import axios from 'axios'
var xheader = [];


function generateAxios(xLink,header,data){
    var apiClient = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        responseType: 'json',
        withCredentials: false,
        headers: header,
    });
    return apiClient.post(xLink,data)
}

export default{
    saveAccountInfo(data){
        xheader = {
            Accept: 'application/json'
        }
        return generateAxios('/api/saveAccount',xheader,data);
    },
    sendconfirmationemail(data){
        xheader = {
            Accept: 'application/json'
        }
        return generateAxios('/api/sendconfirmationemail',xheader,data);
    },
    activateAccountInfo(data){
        xheader = {
            Accept: 'application/json'
        }
        return generateAxios('/api/activateAccountInfo',xheader,data);
    },
    LoginAccount(data){
        xheader = {
            
        }
        return generateAxios('/api/LoginAccount',xheader,data);
    },
    fetchUserByID(data){
        xheader = {
            Accept: 'application/json'
        }
        return generateAxios('/api/fetchUserByID',xheader,data);
    },
    checkToken(data){
        xheader = {
            Authorization: "Bearer "+data['_token'],
            // Accept: 'application/json'
        }
        return generateAxios('/api/checkToken',xheader,data);
    },
    
}