<template>
  <div>
    <!--================Banner Area =================-->
      <!-- <section id="sec-home" class="banner_area">
        <div class="booking_table d_flex align-items-center">
          <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background="">
            <video id="background-video" autoplay loop muted>
              <source src="../../assets/img/home/coverVideo.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </section> -->
      <!--================Banner Area =================-->

      <!--================ About History Area  =================-->
      <!-- <section class="about_history_area section_gap">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">About Us <br>Our History<br>Mission & Vision</h2>
                <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                <a href="#" class="button_hover theme_btn_two">Request Custom Price</a>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../../../public/template/home/image/about_bg.jpg" alt="img">
            </div>
          </div>
        </div>
      </section> -->
      <!--================ About History Area  =================-->
      
      <!--================ Testimonial Area  =================-->
      <section v-if="roomloading == 1 && (POPULARROOMS).length != 0"  id="sec-testimony" class="testimonial_area section_gap bg-abstract">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">Our Popular Rooms</h2>
            <!-- <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p> -->
          </div>
          <!-- <div v-else> -->
            <div v-if="(POPULARROOMS).length != 0 && (POPULARROOMSOTHERINFO).length != 0" class="testimonial_slider locale_room_slider owl-carousel">
              <div v-for="rooms in POPULARROOMS" :key="rooms.relID" class="media testimonial_item row">
                <img class="roomIMG col-12 col-sm-12 col-md-6 col-lg-6" style="width: 100%" :src="POPULARROOMSOTHERINFO[rooms.relID]['room_img']" alt="">
                <div class="media-body col-12 col-sm-12 col-md-6 col-lg-6">
                  <center>
                    <h2>{{this.POPULARROOMSOTHERINFO[rooms.relID]['room_name']}}</h2>
                  </center>
                  <div class="popular_roomDesc" v-html="rooms.description"></div>
                  <!-- <p class="popular_roomDesc">As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p> -->
                  <div class="popular_inclusion">
                    <h4>Inclusions:</h4>
                    <div class="inc_imgs">
                      <div class="row">
                        <div class="col-4 col-md-2" v-for="AME in AMENITIES" :key="AME.id">
                          <center>
                            <img :id="'amen_'+POPULARROOMSOTHERINFO[rooms.relID]['room_no']+'_'+AME.id" style="width: 25px;opacity: 0.4;display: block;margin: auto" :src="xHost+'/'+AME.img" alt="My Happy SVG"/>
                            <label :id="'dspl_amen_'+POPULARROOMSOTHERINFO[rooms.relID]['room_no']+'_'+AME.id" style="font-size: 8px; margin-bottom: 10px;opacity: 0.1" >{{AME.category}}</label>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  
                <!-- <div class="popular_booknow">
                  <center>
                    <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">BOOK NOW</a>
                  </center>
                </div> -->
              </div>
            </div>
          <!-- </div> -->
        </div>
        <br>
        <center>
          <a href="#" @click.prevent="checkRooms()" style="background: white !important" class="button_hover theme_btn_two btn-visit btn-home-book-now">Check More Rooms</a>
        </center>
      </section>
      <div v-else-if="roomloading == 0" class="testimonial_area section_gap bg-abstract">
        <div class="container">
          <ContentLoader></ContentLoader>
        </div>
      </div>
      <div v-else></div>
      <!--================ Testimonial Area  =================-->

      <!-- <section class="latest_blog_area section_gap">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">Other Information</h2>
          </div>
          <div v-if="NEWS.description != undefined" v-html="NEWS.description"></div>
          <img v-else src="../../assets/img/home/pic.jpg" alt="">
        </div>
      </section> -->
      
      <!--================ MAP Area  =================-->
      <section class="testimonial_area section_gap bg-abstract">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">Location</h2>
            <!-- <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p> -->
          </div>
          <iframe id="mapDSPL"
            style="border:0;width: 100%;height: 70vh"
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAm0VhV-wvC3nxBeDR7tIp_38rmgl1FlyM&q=Victoria+court+north+edsa">
          </iframe>
        </div>
      </section>
      <!--================ Testimonial Area  =================-->
      
      <!--================ Latest Blog Area  =================-->
      <section class="latest_blog_area section_gap" style="display: none">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">latest posts from blog</h2>
            <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p>
          </div>
          <div class="row mb_30">
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-1.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">Low Cost Advertising</h4></a>
                  <p>Acres of Diamonds… you’ve read the famous story, or at least had it related to you. A farmer.</p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-2.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">Creative Outdoor Ads</h4></a>
                  <p>Self-doubt and fear interfere with our ability to achieve or set goals. Self-doubt and fear are</p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-3.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">It S Classified How To Utilize Free</h4></a>
                  <p>Why do you want to motivate yourself? Actually, just answering that question fully can </p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--================ Recent Area  =================-->
	
  </div>
</template>

<script>
import services from '../../service/webservice'
export default {
  name: 'balintawak_n',
  // props: {
  //   msg: String
  // },
  data(){
    return {
        webcontent: [], 
        POPULARROOMS: [],
        POPULARROOMSOTHERINFO: [],
        AMENITIES: [],
        NEWS: [],
        xHost: process.env.VUE_APP_HOST,
        company: process.env.VUE_APP_COMPANY_CODE,
    }
  },
  created(){
    this.$store.state.header = "home";
    this.$store.state.locale = "NE";
    this.$store.state.covervideo = "host|locale|ne|cover";
    
    this.$store.state.loadOtherScript = 0;
    this.getAmenities()

    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    
    this.windowResizing()
    var resizedFinished;
    window.addEventListener("resize", ()=>{
      clearTimeout(resizedFinished);
      resizedFinished = setTimeout(()=>{
       this.windowResizing()
      },1000);
    });
    
  },
  mounted(){

  },
  updated: function () {
      this.$nextTick(function () {
        setTimeout(()=>{
          this.modifyHeightPopularRooms()
        },2000);
      })
  },

  // mounted(){
    
  // }
  methods:{
    
   
    
  checkRooms(){
      localStorage.setItem('chkAvailability',JSON.stringify({branch: this.$store.getters.getBranchName}));
      window.open("/booking","_blank")
    },
    fetchAmenitiesByRoomID(roomid){
      var xdata = {
        company: this.company,
        branch: "ne",
        roomid: roomid,
      }
      return services.fetchAmenitiesByRoomID(xdata)
    },
    enableAmenitiesDspl(){
      setTimeout(()=>{
          console.log(this.POPULARROOMS)
          this.POPULARROOMS.forEach((xxv)=>{
            this.fetchAmenitiesByRoomID(xxv['relID']).then(result=>{
              var amens = result.data
              console.log(amens)
              amens['amenities'].forEach((v1)=>{
                if( v1['amenities_category'] ){
                  var el = document.getElementById('amen_'+amens['room_number']+'_'+v1['amenities_category']['id'])
                  var xel = document.getElementById('dspl_amen_'+amens['room_number']+'_'+v1['amenities_category']['id'])
                  
                  if( el ){
                    el.style.opacity = 1
                  } else {
                    console.log('amen_'+amens['room_number']+'_'+v1['amenities_category']['id']+" is not found")
                  }

                  if( xel ){
                    xel.style.opacity = 1
                    if( v1['a_desc'] == 'Yes' || v1['a_desc'] == 'YES' ){
                      xel.innerText = v1['amenities_category']['category']
                    } else {
                      xel.innerText = v1['a_desc']
                    }
                  } else {
                    console.log('dspl_amen_'+amens['room_number']+'_'+v1['amenities_category']['id']+" is not found")
                  }
                }
              })
            });
            
            
          });
          // for (let [key, v] of Object.entries(this.listAmenities)) {
          //   console.log(key)
            
          // }
      },1000);
    },
    modifyHeightPopularRooms(){
      // alert()
      var getItems = document.querySelectorAll('.testimonial_item');
      // -- RESET HEIGHT
      getItems.forEach(i => {
        i.style.height = "Initial";
      });
      // -- FETCH HIGHEST HEIGHT
      var maxHieght = 0;
      getItems.forEach(i => {
        if( i.offsetHeight > maxHieght ){
          maxHieght = i.offsetHeight;
        }
      });
      // -- APPLY NEW HEIGHT
      if( maxHieght != 0 ){
        getItems.forEach(i => {
          i.style.height = maxHieght+"px";
        });
      }
    },

    checkAmenity(Amenities,id){
      var Ame = JSON.parse("["+Amenities+"]")
      // alert(Amenities+" = "+id+" == "+Ame.indexOf(id))
      return Ame.indexOf(id);
    },

    async getPopularRoomInfos(){
      await this.roomServicePopularRoom().then(response => {
        console.log("POPULAROTHERS")
        this.POPULARROOMSOTHERINFO = response.data
        setTimeout(()=>{
          this.roomloading = 1;
        },2000);
        this.$store.state.loadOtherScript = 1;
        this.enableAmenitiesDspl()
        console.log(this.POPULARROOMSOTHERINFO)
      }).catch(error=>{
        console.log(error)
      })
    },

    roomServicePopularRoom(){
        var payloads = {
          data: this.POPULARROOMS,
          company: process.env.VUE_APP_COMPANY_CODE,
          branch: this.$store.getters.getBranchName
        }
        return services.getPopularRoomInfo(payloads)
    },

    getAmenities(){
      this.xgetAmenities().then(response => {
        // console.log(response)
        this.AMENITIES = response.data
        console.log(this.AMENITIES)
      })
    },

    xgetAmenities(){
        var payloads = {
          company: (process.env.VUE_APP_COMPANY_CODE).toLowerCase(),
        }
        return services.fetchAmenityCategoryPerAmenities(payloads)
    },

    windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
        if (screenWidthCheck > 480) {
        this.currentView = 'web'
        } else {
        this.currentView = 'mobile'
        }
        this.displayContent()

        setTimeout(()=>{
          this.modifyHeightPopularRooms()
        },2000);
    },
    contentvariableReset(){
        this.POPULARROOMS = []
        this.NEWS = []
    },
    displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > 480 ){
            // -- WEB
          // -- POPULAR ROOMS
          if( this.webcontent['web|host|locale|ne|popularrooms'] ){
            this.POPULARROOMS = this.webcontent['web|host|locale|ne|popularrooms']
            this.getPopularRoomInfos()
          }
          // -- NEWS
          if( this.webcontent['web|host|locale|ne|news'] ){
            this.NEWS = this.webcontent['web|host|locale|ne|news']
          }
        } else {
            // -- MOBILE
          // -- POPULAR ROOMS
          if( this.webcontent['mobile|host|locale|ne|popularrooms'] ){
            this.POPULARROOMS = this.webcontent['mobile|host|locale|ne|popularrooms']
            this.getPopularRoomInfos()
          }
          // -- NEWS
          if( this.webcontent['mobile|host|locale|ne|news'] ){
            this.NEWS = this.webcontent['mobile|host|locale|ne|news']
          }
        }
        console.log("POPULAR")
        // if( this.POPULARROOMS.length == 0 ){
        //   this.$store.state.loadOtherScript = 1;
        // }
    },
    getVCContent($url){
      this.xgetVCContent($url).then(response => {
        // console.log(response)
        this.webcontent = response.data
        // console.log(this.webcontent['web|home|vcFeatures|types|host'])
        this.displayContent()
      })
    },
    xgetVCContent($url){
        return services.fetchVCcontent($url)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #background-video{
    width: 100%;
  }
  .bg-abstract{
    background: url("../../../public/template/home/image/page-bg.jpg");
    background-repeat: repeat;
    background-size: 2px;
  }
  .gray-bg{
    /* background: #e2e2e2; */
  }
  .vc_option{
    width: 100%;
    height: 500px;
  }
  .card-title{
    color: black !important;
  }
  .card-body{
    text-indent: 25px;
    text-align: justify;
  }
  .card-content{
    padding: 10px;
    background: white;
  }
  /* .custom-card{
  }
  .custom-card:hover{
    transition: box-shadow 250ms ease, transform 250ms ease;
  }
  @keyframes embouse-border {
    from {
      margin: auto;
      box-shadow: 0px;
    }
    to {
      margin: -10px;
      box-shadow: 1px 1px 20px black;
    }
  } */
  .btn-visit{
    font-size: 18px;
    padding: 5px 50px;
    border: 1px solid #FFD700;
    background: transparent;
    color: black;
  }
  .facilities_item p{
    color: white !important;
  }
  .roomIMG{
    width: 400px !important;
  }
  .popular_roomDesc{
    text-indent: 25px;
  }
  .popular_inclusion{
    margin-top: 50px;
  }
  .inc_desc{
    /* padding-left: 20px; */
  }
  .inc_imgs img{
    width: 20px;
  }
  .xicon{
    display: flex;
  }
  .testimonial_item{
    position: relative;
  }
  .popular_booknow{
    position: absolute;
    width: -webkit-fill-available;
    bottom: 20px;
    margin-left: -65px !important;
  }
  .testimonial_item{
    /* display: flex !important;
    align-items: stretch; */
    padding-bottom: 100px;
  }
</style>
