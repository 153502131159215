<template>
  <div>

    <!-- Breadcrumb Section Begin -->
      <section v-if="COVER.image != undefined" class="breadcrumb_area">
          <div class="overlay bg-parallax" v-bind:style="{ 'background-image': 'url(' + COVER.image + ') !important' }" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
      <section v-else class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
    <!-- Breadcrumb Section End -->
    <!-- Shop Section Begin -->
    <section class="shop spad" id="product_list">
        <div class="container">
            <div class="row">
                <div class="offset-lg-1 col-lg-10">
                <h1 class="div-title">Our Products</h1>
                    <div class="row">
                        <div class="offset-md-6 col-md-6">
                            <input type="text" v-model="filter" @keyup="getProducts()" placeholder="search" class="form-control">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <!-- :class="prodisplay[prods.fetch_company_product_byid.is_online]" -->
                        <div v-for="prods in foodArr.data" :key="prods.id" class="col-lg-4 col-md-6 col-sm-6">
                            <div class="product__item sale">
                                <div @click="viewMoreInformation(prods)" class="product__item__pic" :style="{backgroundImage:`url(${foodImgSrc}/${prods.image_file})`}">
                                    <!-- <span class="label">Sale</span> -->
                                    <!-- <ul class="product__hover">
                                        <li><a href="#"><img src="../../assets/malefashion/img/icon/heart.png" alt=""></a></li>
                                        <li><a href="#"><img src="../../assets/malefashion/img/icon/compare.png" alt=""> <span>Compare</span></a>
                                        </li>
                                        <li><a href="#"><img src="../../assets/malefashion/img/icon/search.png" alt=""></a></li>
                                    </ul> -->
                                </div>
                                <div v-if="currentView=='web'" class="product__item__text">
                                    <h6>{{prods.product}}</h6>
                                    <a href="#" @click.stop.prevent="addToCart(prods)" class="add-cart">+ Add To Cart</a>
                                    <!-- <div class="rating">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star-o"></i>
                                    </div> -->
                                    <h5>&#8369; {{this.formatter(prods.fetch_company_product_price_byid.amount)}} <span style="color: #ffc107 !important;" v-if="this.search(prods.id, this.$store.state.cart) != null"> | <i class="fa fa-shopping-cart"></i>&nbsp;{{this.search(prods.id, this.$store.state.cart)}}</span></h5>
                                    
                                    <!-- <div class="product__color__select">
                                        
                                        <label for="pc-7">
                                            <input type="radio" id="pc-7">
                                        </label>
                                        <label class="active black" for="pc-8">
                                            <input type="radio" id="pc-8">
                                        </label>
                                        <label class="grey" for="pc-9">
                                            <input type="radio" id="pc-9">
                                        </label>
                                    </div> -->
                                </div>
                                <div v-else class="product__item__text" style="border: 2px solid rgba(0,0,0,0.5); padding: 20px">
                                    <!-- <div class="row"> -->
                                        <h5>{{prods.product}}</h5>
                                        <h5>&#8369; {{this.formatter(prods.fetch_company_product_price_byid.amount)}} <span style="color: #ffc107 !important;" v-if="this.search(prods.id, this.$store.state.cart) != null"> | <i class="fa fa-shopping-cart"></i>&nbsp;{{this.search(prods.id, this.$store.state.cart)}}</span></h5>

                                        <center>
                                            <button @click="addToCart(prods)" class="button_hover btn-default theme_btn_two btn-visit btn-shop-now" data-v-12835cef="">Add To Cart</button>
                                        </center>

                                    <!-- </div> -->
                                    <!-- <div class="rating">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star-o"></i>
                                    </div> -->
                                    <!-- <div class="product__color__select">
                                        <label for="pc-7">
                                            <input type="radio" id="pc-7">
                                        </label>
                                        <label class="active black" for="pc-8">
                                            <input type="radio" id="pc-8">
                                        </label>
                                        <label class="grey" for="pc-9">
                                            <input type="radio" id="pc-9">
                                        </label>
                                    </div> -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-lg-12">
                            <div class="product__pagination">
                                <a class="active" href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <span>...</span>
                                <a href="#">21</a>
                            </div>
                        </div> -->
                        <div style="margin: 0 auto">
                            <paginate
                                :page-count="pagecnt"
                                :page-range="3"
                                :margin-pages="2"
                                :click-handler="clickCallback"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'pagination'"
                                :page-class="'page-item'"
                            >
                            </paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Shop Section End -->

  </div>
</template>

<script>
// import vueStickySidebar from "vue-sticky-sidebar";
import services from '../../service/webservice'
import Paginate from 'vuejs-paginate-next';
export default {
  name: 'styles_n',
  // props: {
  //   msg: String
  // },,
  components: {
  //   "vue-sticky-sidebar": vueStickySidebar
      paginate: Paginate,
  },
  data(){
    return {
        company: process.env.VUE_APP_COMPANY_CODE,
        foodloading: 0,
        foodArr: [],
        foodImgSrc: "",
        prodisplay: {0: "hide", 1: "show"},

        currentpage: 1,
        pagecnt: 0,
        currentView: 'web',
        filter: '',
        
        // -- WEB CONTENT
        COVER: [],
        webcontent: [], 

    }
  },
  created(){
    this.$store.state.header = "styles";
    // this.$store.getters.fetchCart

    this.getProducts()
    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    this.windowResizing()
    window.addEventListener("resize", this.windowResizing);
    // alert();
  },
  mounted(){
  },
  methods:{
    windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
        if (screenWidthCheck > 480) {
        this.currentView = 'web'
        } else {
        this.currentView = 'mobile'
        }
        this.displayContent()
    },
    contentvariableReset(){
        // -- FEATURES
        this.COVER = []
    },
    displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > 480 ){
            // -- WEB
            // -- COVER
            if( this.webcontent['web|styles|store|cover'] ){
                this.COVER.image = this.webcontent['web|styles|store|cover']['image']
            }
        } else {
            // -- MOBILE
            // -- COVER
            if( this.webcontent['mobile|styles|store|cover'] ){
                this.COVER.image = this.webcontent['mobile|styles|store|cover']['image']
            }
        }
    },
    getVCContent($url){
        this.xgetVCContent($url).then(response => {
        // console.log(response)
        this.webcontent = response.data
        // console.log(this.webcontent['web|home|vcFeatures|types|host'])
        this.displayContent()
        })
    },
    xgetVCContent($url){
        return services.fetchVCcontent($url)
    },
    search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            // alert(myArray[i].product_id + "==" + nameKey)
            if (myArray[i].product_id == nameKey) {
                // alert(JSON.stringify(myArray[i]))
                return myArray[i]['quantity'];
            }
        }
    },
    formatter(price){
        return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    addToCart(objt){
        var cart = this.$store.getters.fetchCart;
        // (this.$store.getters.fetchCart)
        var isAdd = 1;
        cart.forEach(function(value,key){
            console.log(key)
            if( objt['id'] == value['product_id'] ){
                cart[key]['quantity'] = parseInt(cart[key]['quantity'])+1
                isAdd = 0;
            }
        });

        var xxarr = []
        if( isAdd == 1 ){
            xxarr= {
                "product_id": objt['id'],
                "product": objt,
                "quantity": 1
            }
            cart.push(xxarr)
        }

        // var xarr = xxarr
        this.$store.state.cart = cart
        localStorage.setItem('myCart', JSON.stringify(cart))
        this.$store.getters.fetchCart
        // alert(JSON.stringify(cart))
        // alert(JSON.stringify(objt['id']))
    },
    getCart(){
        
    },
      clickCallback (pageNum){
        // alert(pageNum)
        // this.currentpage = pageNum
        this.getProducts(pageNum)
        const myEl = document.getElementById('product_list')

        this.$smoothScroll({
            scrollTo: myEl, // scrollTo is also allowed to be number
        // hash: '#sampleHash' // required if updateHistory is true
        })
      },
      getProducts(num){
        this.foodloading = 0
        if( num != '' ){
            this.fetchProductsPaginate(num).then(response => {
                this.foodloading = 1
                this.foodArr = response.data.list
                this.foodImgSrc = response.data.motelIMG    
                this.$store.state.stylesImageURL = response.data.motelIMG   
                // alert(response.data.list.last_page)
                this.pagecnt = response.data.list.last_page
            })
        } else {
            this.fetchProducts().then(response => {
                this.foodloading = 1
                this.foodArr = response.data.list
                this.foodImgSrc = response.data.motelIMG    
                this.$store.state.stylesImageURL = response.data.motelIMG   
                // alert(response.data.list.last_page)
                this.pagecnt = response.data.list.last_page
            })
        }
      },
      fetchProducts(){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: this.filter,
          pagination: 12
        }
        return services.fetchProducts(xdata)
      },
      fetchProductsPaginate(num){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: this.filter,
          pagination: 12
        }
        return services.fetchProductsPaginate(xdata,num)
      },
      viewMoreInformation(prod){
          localStorage.setItem('moreinfo',JSON.stringify(prod))
          window.location.href="/style-moreinfo";
        //   console.log()
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .page-cover{
        position: relative !important;
        max-width: 1900px;
        /* align-content: center; */
        margin: auto;
    }
    .page-cover img{
        width: 100%;
        position: relative;
    }
    .page-cover .cover-message{
        position: absolute;
        top: 45%;
        color: white;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .cover-message h1{
        font-size: 45px;
    }
    /* .btn-shop-now{
        border: 1px solid #f8b100;
        width: 200px;
    } */
    .div-title{
        text-align: center !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    .product__item__pic{
        background-repeat: no-repeat;
        background-size: cover;
    }
    .breadcrumb_area .bg-parallax {
        background-image: url('../../assets/img/host/home/vc-host-header.jpg')!important;
        background-repeat: no-repeat !important;
        background-attachment: scroll !important;
        background-position: center !important;
        background-size: cover !important;
    }
</style>
