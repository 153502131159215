import { createStore } from 'vuex';

export default new createStore({
    state () {
      return {
        locale: "",
        header: "",
        covervideo: "",
        cart: [],
        totalCart: 0,
        stylesImageURL: "",
        cartSubTotal: "",
        loadOtherScript: 1,
        links: {
          styles: {
            link: '/style',
            _blank: false
          },
          bites: {
            link: 'https://www.victoriabites.com',
            _blank: true
          },
          host: {
            link: '/location',
            _blank: false
          },
          home: {
            link: '/',
            _blank: false
          },
          speaks: {
            link: '/speaks',
            _blank: false
          },
          book: {
            link: '/booking',
            _blank: true
          }
        },
        branchinfo: {
          HI:{
            name: 'Victoria Court Hillcrest',
            address: '207 Hillcrest Drive, Pasig City',
            number: '8671-9782 to 85'
          },
          PA:{
            name: 'Victoria Court Panorama',
            address: 'Escarpment Road, Pasig City',
            number: '8671-9782 to 85'
          },
          LP:{
            name: 'Victoria Court Las Piñas',
            address: 'Admiral Road, Talon III, Las Piñas City',
            number: '8800-6190'
          },
          MA:{
            name: 'Victoria Court Malabon',
            address: '#197 Mac Arthur Highway, Potrero Malabon City',
            number: '361-3361 to 63'
          },
          BA:{
            name: 'Victoria Court Balintawak',
            address: '17 Don Vicente Ang St., Caloocan City',
            number: '8363-32429 & 83640235'
          },
          NE:{
            name: 'Victoria Court North EDSA',
            address: '3 Don Vicente Ang St., Caloocan City',
            number: '8363-7051 to 53'
          },
          SF:{
            name: 'Victoria Court San Fernando',
            address: 'Post 73.7 Km, Sindalan, San Fernando, Pampanga',
            number: '(045) 445-4178'
          },
          SM:{
            name: 'Victoria Court Panorama',
            address: 'Escarpment Road, Pasig City',
            number: '8671-9782 to 85'
          }
        },
        mybranch: {
          name: 'Victoria Court',
          address: '#1 Canley Road Brgy. Oranbo Pasig City',
          number: '81-75-XXXXXXX'
        }
      }
    },
    getters: {
        getStatusOtherScript(state){
          return state.loadOtherScript
        },
        getBranchName(state){
          return state.locale
        },
        getMyBranchInfo(state){
          return state.mybranch
        },
        getBranchInformations(state){
          return state.branchinfo
        },
        getlinks(state){
          return state.links
        },
        fetchHeader(state){
            return state.header;
        },
        fetchCoverVideo(state){
            return state.covervideo;
        },
        fetchCart(state){
          state.totalCart = 0
          state.cartSubTotal = 0
          if( localStorage.getItem('myCart') ){
            state.cart = JSON.parse(localStorage.getItem('myCart'))
            state.cart.forEach(function (value,key){
                console.log(key)
                console.log(value)
                state.totalCart += parseInt(value['quantity'])
                state.cartSubTotal += parseInt(value['quantity']) * parseInt(value['product']['fetch_company_product_price_byid']['amount'])
            });
            return state.cart
          } else {
            return state.cart = []
          }
        },
        fetchCartQuantity(state){
          // var tQuantity = 0;
          state.cart.forEach(function (value,key){
              console.log(key)
              console.log(value)
              state.totalCart += value['quantity']
          });
          return state.totalCart;
        }
    }
  });