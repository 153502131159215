<template>
  <div>
    <section class="shop spad"  style="padding-top: 150px !important">
        <center>
            <h1>ACCOUNT SUCCESSFULLY CREATED</h1>
            <h4>We will send an email verification to activate your account</h4>
            <h4>Thank You.</h4>
        </center>
    </section>
  </div>
</template>

<script>
    // import services from '../../service/account_webservice'
    export default {
        name: 'created_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                
            }
        },
        created(){
            this.$store.state.header = "account";
        },
        // mounted(){
            
        // }
        methods:{
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
