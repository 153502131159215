<template>

  <div v-if="this.$store.getters.fetchHeader=='booking'">
    <!--================Header Area =================-->
    <!-- <header class="header_area">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light">
          
          <a class="navbar-brand logo_h" href="index.html"><img id="" src="../../../public/template/home/image/logo_black.png" alt=""></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          
          <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
            
            <ul class="nav navbar-nav menu_nav ml-auto mr-auto">
              <li class="nav-item active"><a class="nav-link" href="index.html">Home</a></li> 
              <li class="nav-item"><a class="nav-link" href="about.html">About us</a></li>
              <li class="nav-item"><a class="nav-link" href="accomodation.html">Accomodation</a></li>
              <li class="nav-item"><a class="nav-link" href="gallery.html">Gallery</a></li>
              <li class="nav-item submenu dropdown">
                <a class="menuLink corbel" href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Blog</a>
                <ul class="dropdown-menu">
                  <li class="nav-item"><a class="nav-link" href="blog.html">Blog</a></li>
                  <li class="nav-item"><a class="nav-link" href="blog-single.html">Blog Details</a></li>
                </ul>
              </li> 
              <li class="nav-item"><a class="nav-link" href="elements.html">Elemests</a></li>
              <li class="nav-item"><a class="nav-link" href="contact.html">Contact</a></li>
            </ul>
          </div> 
          <button class="btn-home-book-now">book now</button>
        </nav>
      </div>
    </header> -->
    
    <!-- Page Preloder -->
    <!-- <div id="preloder">
        <div class="loader"></div>
    </div> -->

    <header>
      <div class="navbar bookingNavBG">
        <div class="col-lg-2 col-md-2  col-sm-12">
            <div class="header__logo" style="padding: 0px !important;">
                <!-- <a href="#" @click.prevent="menulinks(links.home)"><img src="../../../public/template/home/image/logo_white.png" alt=""></a> -->
                <a class="logo-white" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img style="width: 100% !important" src="../../assets/img/logo/vc-logo-new-white.png" alt="Victoria Court Long Stay Hotel"></a>
                <!-- <a class="logo-black" style="display: none" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-black.png" alt="Victoria Court Long Stay Hotel"></a> -->
            </div>
        </div>
        <div class="col-lg-10 col-md-10">
            <nav class="header__menu mobile-menu" style="text-align: right; padding: 15px 0px 14px !important;">
              <ul style="margin-bottom: 0px !important">
                <!-- <li><a class="menuLink corbel nav-header-title" style="color: white !important">Manage Reservation</a></li> -->
                <li class="nav-header-title" @click="bookingRulesShow()">Reservation Policy</li>
                <li class="nav-header-title"><a style="color: white" href="tel:{{this.$store.getters.getMyBranchInfo.number}}"><i class="fa fa-phone"></i>&nbsp;&nbsp;{{this.$store.getters.getMyBranchInfo.number}}</a></li>
              </ul>
            </nav>
        </div>
      </div>
      <section class="xxheader booking-bread breadcrumb_area" style="padding: 80px 10px !important">
        <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
        <div class="company-info" style="width: 50%;">
          <div class="row">
            <div class="col-md-12" style="font-size: 34px;padding-bottom: 5px">{{this.$store.getters.getMyBranchInfo.name}}</div>
            <div class="col-md-12" style="font-size: 20px;padding-bottom: 5px">{{this.$store.getters.getMyBranchInfo.address}}</div>
            <div class="col-md-12" style="font-size: 20px;padding-bottom: 5px">{{this.$store.getters.getMyBranchInfo.number}}</div>
          </div>
        </div>
      </section>
    </header>

  </div>
  <div v-else-if="this.$store.getters.fetchHeader=='home'">
    <!-- Offcanvas Menu Begin -->
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper" style="z-index: -1">
        <div class="navclose">
          <div>
            <i class="fa fa-times navClose"></i>
          </div>
          <a class="xmobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)">
            <img src="../../assets/img/logo/VC-logo-with-white-BG.png" alt="Victoria Court Long Stay Hotel">
          </a>
          <a class="mobileMenuLogo" href="#" @click.prevent="">
            <img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel">
          </a>
        </div>
        <div id="mobile-menu-wrap">
          <center>
            <img src="../../assets/img/logo/vc-logo.png" alt="Victoria Court Long Stay Hotel">
          </center>
          <div id="mobile-menu-container"></div>
        </div>
        <div class="offcanvas__nav__option">
        </div>
    </div>
    <!-- Offcanvas Menu End -->

    <!-- Header Section Begin -->
    <header class="header header_area">
        <div class="navbar">
            <div :class="container">
              <!-- <div class="row"> -->
                <div class="col-lg-2 col-md-2  col-sm-3 col-3">
                    <div class="header__logo">
                          <a class="final-logo" style="display: block" href="#" @click.prevent="menulinks(links.home)">
                            <img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel">
                          </a>
                    </div>
                </div>
                
                <!-- <div class="col-md-10"> -->
                  <!-- <div class="row" v-if="currentView=='web'">
                    <div class="col-md-12">
                      <div class="cart" style=" padding: 10px;" data-toggle="modal" data-target="#cart-modal" @click="showCart()">
                          <a href="/login" class="corbel" style="text-decoration: none;color: black !important">Login</a>
                          &nbsp;&nbsp;&nbsp;
                          <a href="/signup" class="corbel" style="text-decoration: none;color: black !important">Sign up</a>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="row"> -->
                    <div class="col-lg-8 col-md-8">
                        <nav class="header__menu mobile-menu">
                            <ul>
                                <!-- <li><a class="menuLink corbel" href="/home"><i v-if="currentView == 'mobile'" class="fa fa-home"></i> Home</a></li> -->
                                <li><a class="menuLink corbel" :href="links.host.link"> victoria host</a></li>
                                <li><a class="menuLink corbel" :href="links.bites.link" target="_blank"> victoria bites</a></li>
                                <!-- <li><a class="menuLink corbel" :href="links.styles.link" > victoria style</a></li> -->
                                <li><a class="menuLink corbel" href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal" > victoria style</a></li>
                                <!-- <li><a class="menuLink corbel" :href="links.speaks.link"><i v-if="currentView == 'mobile'" class="fa fa-bullhorn"></i> VICTORIA SPEAKS</a></li> -->
                            </ul>
                        </nav>
                    </div>
                    <div class="col-lg-2 col-md-2" style="margin: auto">
                        <div class="header__nav__option mobile-menu">
                            <!-- <a href="#" class="search-switch menuLink"><img src="../../assets/malefashion/img/icon/search.png" alt=""></a>
                            <a class="menuLink corbel" href="#"><img src="../../assets/malefashion/img/icon/heart.png" alt=""></a> -->
                            <!-- <a class="menuLink corbel" href="#"><i class="fa fa-opencart"></i> <span></span></a>
                            <div class="price">$0.00</div> -->
                            <!-- <button class="btn-home-book-now">book now</button> -->
                            <!-- <center>
                              <a :href="links.book.link" target="_blank" style="color: white !important;padding: 1px !important" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now ">book now</a>
                            </center> -->
                            <!-- <center> -->
                              <a :href="links.book.link" target="_blank" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now">book now</a>
                            <!-- </center> -->
                            <!-- <div class="row" v-if="currentView!='web'" >
                              <div class="col-sm-12">
                                <div class="cart" style="color: white; padding: 10px;">
                                  <a href="#" style="text-decoration: none;color: black !important">Login</a>
                                  &nbsp;&nbsp;&nbsp;
                                  <a href="#" style="text-decoration: none;color: black !important">Signup</a>
                                </div>
                              </div>
                            </div> -->
                        </div>
                        <!-- <div>
                          <center>
                            <a :href="links.book.link" target="_blank" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now ">LOGIN</a>
                          </center>
                        </div> -->
                    </div>
                  <!-- </div> -->

                    <!-- </div> -->
              <!-- </div> -->
            </div>
            <div class="canvas__open mobile-burger" style="color: white; border: 1px solid white"><i class="fa fa-bars"></i></div>
        </div>

    </header>

    <!--================Banner Area =================-->

      <!-- <div id="carouselExample1" class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="../../assets/img/home/splash/splash1.jpg" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../assets/img/home/splash/splash2.jpg" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../assets/img/home/splash/splash3.jpg" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../assets/img/home/splash/splash4.jpg" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../../assets/img/home/splash/splash5.jpg" alt="First slide">
          </div>
        </div>
        <a class="carousel-control-prev" style="background: rgba(0,0,0,0)" href="#carouselExample1" role="button" data-slide="prev">
          <span  style="visibility: hidden" class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" style="background: rgba(0,0,0,0)" href="#carouselExample1" role="button" data-slide="next">
          <span  style="visibility: hidden" class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> -->
      <section v-if="currentView == 'web'" id="sec-home" class="banner_area">
        <div style="position: fixed;z-index: -1;top: 1px !important; color: rgba(0,0,0,0.1); width: 100%">
          <div class="booking_table d_flex align-items-center">
              <div style="width: 100%" v-if="COVER.image != undefined" v-html="COVER.image"></div>
              <video v-else id="background-video" autoplay loop muted>
                <source src="../../assets/img/home/NewCoverVideo.mp4" type="video/mp4">
                <!-- <source src="../../assets/img/home/cover.mp4" type="video/mp4"> -->
                <!-- <source src="../../assets/img/home/test.mp4" type="video/mp4"> -->
              </video>
              <div v-if="this.$store.getters.getBranchName != ''" class="overlay-info">
                <h1>{{ this.getMyBranchNameInGetters(this.$store.getters.getBranchName) }}</h1>
              </div>
          </div>
        </div>
      </section>
      <section v-else class="xmobile-view xxheader  breadcrumb_area">
        <div v-if="COVER.imgpath != undefined" :style="{ 'background': 'url(' + COVER.imgpath + ') no-repeat scroll center !important','background-size': 'cover !important' }" class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
        <div v-else class="">
          <img src="../../assets/img/host/home/facade.jpg" style="width: 100%" alt="">
        </div>
      </section>
      <!--================Banner Area =================-->
    <!-- Header Section End -->
    <!--================Header Area =================-->
  </div>
  <div v-else-if="this.$store.getters.fetchHeader=='bites'"></div>
  <div v-else-if="this.$store.getters.fetchHeader=='policy'"></div>
  <div v-else-if="this.$store.getters.fetchHeader=='styles'">
    <!-- Offcanvas Menu Begin -->
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper">
        <div class="navclose">
          <div>
            <i class="fa fa-times navClose"></i>
          </div>
          <a class="xmobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VC-logo-with-white-BG.png" alt="Victoria Court Long Stay Hotel"></a>
          <a class="mobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
        </div>
        <div id="mobile-menu-wrap">
          <center>
            <img src="../../assets/img/logo/vc-logo.png" alt="Victoria Court Long Stay Hotel">
          </center>
          <div class="offcanvas__nav__option" style="font-size: 30px">
              <!-- <a href="#" class="search-switch"><img src="img/icon/search.png" alt=""></a>
              <a href="#"><img src="img/icon/heart.png" alt=""></a> -->
              <!-- <a href="#"><i class="fa fa-shopping-cart"></i> <span>0</span></a>
              <span class="price">&#8369; 0.00</span> -->
          </div>
          <div id="mobile-menu-container"></div>
        </div>
        <!-- <div class="offcanvas__text">
            <p>Free shipping, 30-day return or refund guarantee.</p>
        </div> -->
    </div>
    <!-- Offcanvas Menu End -->

    <!-- Header Section Begin -->
    <header class="header header_area">
        <div :class="container">
            <div class="styles-header navbar">
                <div class="col-lg-2 col-md-2  col-sm-3 col-3">
                    <div class="header__logo">
                        <!-- <a href="#" @click.prevent="menulinks(links.home)"><img src="../../../public/template/home/image/logo_white.png" alt=""></a> -->
                        <!-- <a class="logo-white" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-white.png" alt="Victoria Court Long Stay Hotel"></a>
                        <a class="logo-black" style="display: none" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-black.png" alt="Victoria Court Long Stay Hotel"></a> -->
                          <a class="final-logo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
                    </div>
                </div>
                <div v-if="currentView!='web'" class="col-sm-7 col-7">
                  <div class="cart" style="color: white; padding: 10px;" data-toggle="modal" data-target="#cart-modal" @click="showCart()">
                    <!-- <div class="btn-cart"> -->
                      <a href="#" style="text-decoration: none;"><i class="fa fa-shopping-cart"></i> <span>{{this.$store.state.totalCart}}</span></a>
                      &nbsp;&nbsp;&nbsp;
                      <span class="price">&#8369; {{this.formatter(this.$store.state.cartSubTotal)}}</span>
                    <!-- </div> -->
                  </div>
                </div>
                <div v-if="currentView!='web'" class="col-sm-2 col-2"></div>
                
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-12" v-if="currentView=='web'" >
                      <div class="cart" style="color: white; padding: 10px;" data-toggle="modal" data-target="#cart-modal" @click="showCart()">
                        <!-- <div class="btn-cart"> -->
                          <a href="#" style="text-decoration: none;"><i class="fa fa-shopping-cart"></i> <span>{{this.$store.state.totalCart}}</span></a>
                          &nbsp;&nbsp;&nbsp;
                          <span class="price">&#8369; {{this.formatter(this.$store.state.cartSubTotal)}}</span>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-10 col-md-10">
                        <nav class="header__menu mobile-menu">
                            <ul>
                                <!-- <li><a class="menuLink corbel" href="/home"><i v-if="currentView == 'mobile'" class="fa fa-home"></i> Home</a></li> -->
                                <li><a class="menuLink corbel" :href="links.host.link"> victoria host</a></li>
                                <li><a class="menuLink corbel" :href="links.bites.link" target="_blank"> victoria bites</a></li>
                                <!-- <li><a class="menuLink corbel" :href="links.styles.link" > victoria style</a></li> -->
                                <li><a class="menuLink corbel" href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal" > victoria style</a></li>
                                <!-- <li><a class="menuLink corbel" :href="links.speaks.link"><i v-if="currentView == 'mobile'" class="fa fa-bullhorn"></i> VICTORIA SPEAKS</a></li> -->
                            </ul>
                        </nav>
                    </div>
                    <div class="col-lg-2 col-md-2" style="margin: auto">
                        <div class="header__nav__option mobile-menu">
                            <!-- <a href="#" class="search-switch menuLink"><img src="../../assets/malefashion/img/icon/search.png" alt=""></a>
                            <a class="menuLink corbel" href="#"><img src="../../assets/malefashion/img/icon/heart.png" alt=""></a> -->
                            <!-- <a class="menuLink corbel" href="#"><i class="fa fa-opencart"></i> <span></span></a>
                            <div class="price">$0.00</div> -->
                            <!-- <button class="btn-home-book-now">book now</button> -->
                            <center>
                              <a :href="links.book.link" target="_blank" style="color: white !important;padding: 1px !important" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now ">book now</a>
                            </center>
                        </div>
                        <!-- <div>
                          <center>
                            <a :href="links.book.link" target="_blank" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now ">LOGIN</a>
                          </center>
                        </div> -->
                    </div>
                  </div>
                </div>
            </div>
            <div class="canvas__open mobile-burger" style="color: white; border: 1px solid white; margin-top: 0px"><i class="fa fa-bars"></i></div>
        </div>

    </header>

    <div class="modal fade" id="cart-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">My VC Cart</h3>
            <!-- <button style="font-size: 20px;" class="close" data-dismiss="modal" aria-label="Close">Continue shopping</button> -->
            <a href="/vc-store" data-dismiss="" aria-label="Close" style="color: white !important;" class="corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now">Continue shopping</a>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">

            <div class="cart-items" v-for="OI in this.$store.state.cart" :key="OI.product_id">
              <div class="row">
                
                <div class="col-md-2 mobile-reset-padding" style="padding-right: 0">
                  <!-- this.$store.state.stylesImageURL -->
                  <img :src="`${this.$store.state.stylesImageURL}`+`/`+`${OI.product.image_file}`"  style="width: 100%" alt="">
                </div>

                <div class="col-md-4 cart-border-left" style=";display: inline-grid;text-align: center;">
                  <div class="col-md-12 cart-align-middle" style="height: 100%; margin: 0 !important">
                    <h5 style="margin: 0 auto !important;">{{OI.product.product}}</h5>
                  </div>
                  <div class="col-md-12 cart-align-middle" style="margin: auto 0; border-top: 1px solid  rgba(197, 194, 194, 0.925);text-align: center">
                    <span style="margin: 0 auto !important;">&#8369; {{this.formatter(OI.product.fetch_company_product_price_byid.amount)}}</span>
                  </div>
                </div>
                
                <div class=" cart-border-left col-md-2" style=";">
                  <div class="cart-align-middle">
                    <div class="input-group">
                      <!-- <span class="input-group-addon" style="font-size: 10px; padding: 7px;border-radius: initial;"><i class="fa fa-plus"></i></span> -->
                      <input type="number" min="0" class="form-control form-control-sm" @change="updateLocalStorageOrder()" v-model="OI.quantity" style="text-align: center;">
                      <!-- <span class="input-group-addon" style="font-size: 10px; padding: 7px;border-radius: initial;"><i class="fa fa-minus"></i></span> -->
                    </div>
                  </div>
                </div>

                <div class=" cart-border-left col-md-2 ">
                  <div class="cart-align-middle">
                    <h5 style="margin: 0 auto !important;">&#8369; {{this.formatter(OI.product.fetch_company_product_price_byid.amount * OI.quantity)}}</h5>
                  </div>
                </div>

                <div class=" cart-border-left mobile-reset-padding col-md-2" style=";padding-left: 0">
                  <div class="cart-align-middle">
                    <span @click="removeItem(OI.product_id)" style="margin: 0 auto !important;"><u>Remove</u></span>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: right">
                <h3>Sub-Total</h3>
                <h2>{{this.formatter(this.$store.state.cartSubTotal)}}</h2>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <a href="/style-checkout" style="color: white !important;" class="corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now">Proceed To Checkout</a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else-if="this.$store.getters.fetchHeader=='speaks' || this.$store.getters.fetchHeader=='account'">
    <!-- Offcanvas Menu Begin -->
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper">
        <!-- <div class="offcanvas__option">
            <div class="offcanvas__links">
                <a href="#">Sign in</a>
                <a href="#">FAQs</a>
            </div>
            <div class="offcanvas__top__hover">
                <span>Usd <i class="arrow_carrot-down"></i></span>
                <ul>
                    <li>USD</li>
                    <li>EUR</li>
                    <li>USD</li>
                </ul>
            </div>
        </div> -->
        <div class="navclose">
          <div>
            <i class="fa fa-times navClose"></i>
          </div>
          <a class="xmobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VC-logo-with-white-BG.png" alt="Victoria Court Long Stay Hotel"></a>
          <a class="mobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
        </div>
        <div id="mobile-menu-wrap">
          <center>
            <img src="../../assets/img/logo/vc-logo.png" alt="Victoria Court Long Stay Hotel">
          </center>
          <div id="mobile-menu-container"></div>
        </div>
        <div class="offcanvas__nav__option">
            <!-- <a href="#" class="search-switch"><img src="img/icon/search.png" alt=""></a>
            <a href="#"><img src="img/icon/heart.png" alt=""></a>
            <a href="#"><img src="img/icon/cart.png" alt=""> <span>0</span></a> -->
            <!-- <div class="price">&#8369; 0.00</div> -->
        </div>
        <!-- <div class="offcanvas__text">
            <p>Free shipping, 30-day return or refund guarantee.</p>
        </div> -->
    </div>
    <!-- Offcanvas Menu End -->

    <!-- Header Section Begin -->
    <header class="header navbar_fixed">
        <!-- <div class="header__top">
            <div :class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7">
                        <div class="header__top__left">
                            <p>Free shipping, 30-day return or refund guarantee.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-5">
                        <div class="header__top__right">
                            <div class="header__top__links">
                                <a href="#">Sign in</a>
                                <a href="#">FAQs</a>
                            </div>
                            <div class="header__top__hover">
                                <span>Usd <i class="arrow_carrot-down"></i></span>
                                <ul>
                                    <li>USD</li>
                                    <li>EUR</li>
                                    <li>USD</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="navbar">
            <div :class="container">
                <div class="col-lg-2 col-md-2  col-sm-3 col-3">
                    <div class="header__logo">
                        <!-- <a href="#" @click.prevent="menulinks(links.home)"><img src="../../../public/template/home/image/logo_white.png" alt=""></a> -->
                        <!-- <a class="logo-white" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-white.png" alt=""></Victoria Court Long Stay Hotela> -->
                        <!-- <a class="logo-black" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-black.png" alt="Victoria Court Long Stay Hotel">-->
                          <a class="final-logo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <nav class="header__menu mobile-menu">
                        <ul>
                            <li><a class="menuLink corbel" :href="links.host.link"> victoria host</a></li>
                            <li><a class="menuLink corbel" :href="links.bites.link" target="_blank"> victoria bites</a></li>
                            <!-- <li><a class="menuLink corbel" :href="links.styles.link" > victoria style</a></li> -->
                            <li><a class="menuLink corbel" href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal" > victoria style</a></li>
                            <!-- <li class="active"><a class="menuLink corbel" :href="links.speaks.link"><i v-if="currentView == 'mobile'" class="fa fa-bullhorn"></i> VICTORIA SPEAKS</a></li> -->
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="header__nav__option mobile-menu">
                        <!-- <a href="#" class="search-switch menuLink"><img src="../../assets/malefashion/img/icon/search.png" alt=""></a>
                        <a class="menuLink corbel" href="#"><img src="../../assets/malefashion/img/icon/heart.png" alt=""></a> -->
                        <!-- <a class="menuLink corbel" href="#"><i class="fa fa-opencart"></i> <span></span></a>
                        <div class="price">$0.00</div> -->
                        <!-- <button class="btn-home-book-now">book now</button> -->
                        <center>
                          <a :href="links.book.link" target="_blank" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now">book now</a>
                        </center>
                    </div>
                </div>
            </div>
            <div class="canvas__open mobile-burger" style="color: white; border: 1px solid white"><i class="fa fa-bars"></i></div>
        </div>

    </header>
    <!-- Header Section End -->
    <!--================Header Area =================-->
  </div>
  <div v-else>
    <!-- Offcanvas Menu Begin -->
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper">
        <!-- <div class="offcanvas__option">
            <div class="offcanvas__links">
                <a href="#">Sign in</a>
                <a href="#">FAQs</a>
            </div>
            <div class="offcanvas__top__hover">
                <span>Usd <i class="arrow_carrot-down"></i></span>
                <ul>
                    <li>USD</li>
                    <li>EUR</li>
                    <li>USD</li>
                </ul>
            </div>
        </div> -->
        <div class="navclose">
          <div>
            <i class="fa fa-times navClose"></i>
          </div>
          <a class="xmobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VC-logo-with-white-BG.png" alt="Victoria Court Long Stay Hotel"></a>
          <a class="mobileMenuLogo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
        </div>
        <div id="mobile-menu-wrap">
          <center>
            <img src="../../assets/img/logo/vc-logo.png" alt="Victoria Court Long Stay Hotel">
          </center>
          <div id="mobile-menu-container"></div>
        </div>
        <div class="offcanvas__nav__option">
            <!-- <a href="#" class="search-switch"><img src="img/icon/search.png" alt=""></a>
            <a href="#"><img src="img/icon/heart.png" alt=""></a>
            <a href="#"><img src="img/icon/cart.png" alt=""> <span>0</span></a> -->
            <!-- <div class="price">&#8369; 0.00</div> -->
        </div>
        <!-- <div class="offcanvas__text">
            <p>Free shipping, 30-day return or refund guarantee.</p>
        </div> -->
    </div>
    <!-- Offcanvas Menu End -->

    <!-- Header Section Begin -->
    <header class="header header_area">
        <!-- <div class="header__top">
            <div :class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7">
                        <div class="header__top__left">
                            <p>Free shipping, 30-day return or refund guarantee.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-5">
                        <div class="header__top__right">
                            <div class="header__top__links">
                                <a href="#">Sign in</a>
                                <a href="#">FAQs</a>
                            </div>
                            <div class="header__top__hover">
                                <span>Usd <i class="arrow_carrot-down"></i></span>
                                <ul>
                                    <li>USD</li>
                                    <li>EUR</li>
                                    <li>USD</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="navbar">
            <div :class="container">
                <div class="col-lg-2 col-md-2  col-sm-3 col-3">
                    <div class="header__logo">
                        <!-- <a href="#" @click.prevent="menulinks(links.home)"><img src="../../../public/template/home/image/logo_white.png" alt=""></a> -->
                        <!-- <a class="logo-white" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-white.png" alt="Victoria Court Long Stay Hotel"></a>
                        <a class="logo-black" style="display: none" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/vc-logo-new-black.png" alt="Victoria Court Long Stay Hotel"></a> -->
                          <a class="final-logo" style="display: block" href="#" @click.prevent="menulinks(links.home)"><img src="../../assets/img/logo/VCLOGOonly.png" alt="Victoria Court Long Stay Hotel"></a>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <nav class="header__menu mobile-menu">
                        <ul>
                            <!-- <li><a class="menuLink corbel" href="/home"><i v-if="currentView == 'mobile'" class="fa fa-home"></i> Home</a></li> -->
                            <li :class="($store.getters.fetchHeader=='locale')?'active':''"><a class="menuLink corbel" :href="links.host.link"> victoria host</a></li>
                            <li :class="($store.getters.fetchHeader=='bites')?'active':''"><a class="menuLink corbel" :href="links.bites.link" target="_blank"> victoria bites</a></li>
                            <li :class="($store.getters.fetchHeader=='styles')?'active':''">
                              <!-- <a class="menuLink corbel" :href="links.styles.link" > victoria style</a> -->
                              <a class="menuLink corbel" href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal" > victoria style</a>
                            </li>
                            <!-- <li :class="($store.getters.fetchHeader=='speaks')?'active':''"><a class="menuLink corbel" :href="links.speaks.link"><i v-if="currentView == 'mobile'" class="fa fa-bullhorn"></i> VICTORIA SPEAKS</a></li> -->
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="header__nav__option mobile-menu">
                        <!-- <a href="#" class="search-switch menuLink"><img src="../../assets/malefashion/img/icon/search.png" alt=""></a>
                        <a class="menuLink corbel" href="#"><img src="../../assets/malefashion/img/icon/heart.png" alt=""></a> -->
                        <!-- <a class="menuLink corbel" href="#"><i class="fa fa-opencart"></i> <span></span></a>
                        <div class="price">$0.00</div> -->
                        <!-- <button class="btn-home-book-now">book now</button> -->
                        <center>
                          <a :href="links.book.link" target="_blank" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now">book now</a>
                        </center>
                    </div>
                </div>
            </div>
            <div class="canvas__open mobile-burger" style="color: white; border: 1px solid white"><i class="fa fa-bars"></i></div>
        </div>

    </header>
    <!-- Header Section End -->
    <!--================Header Area =================-->
  </div>
  <styleModal></styleModal>
    <!-- <div class="modal-backdrop"> -->
       <!-- :class="bookingRules" -->
       <button style="display: none" ref="myBtnModal" data-toggle="modal" data-target="#reservationRule"></button>
      <div class="modal fade" tabindex="-1" data-backrop="static" role="dialog" id="reservationRule">
        <div class="modal-dialog modal-lg">
      
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
              <h4 class="modal-title">General Rules</h4>
            </div>
            <div class="modal-body">
              <div class="container" style="padding: 1px 30px;text-align: justify">
                <ol>
                  <li> All prices are listed in Philippine Pesos. </li>
                  <li> A 50% deposit via bank transfer is required at least 24 hours before check-in. </li>
                  <li> Guests must follow the prescribed reservation process to complete the reservation. </li>
                  <li> Once the reservation is placed, a secured deposit of at least 50% of the total amount shall be collected via bank transfer/deposit at least 24 hours prior to the check-in date. </li>
                  <li> The reservation fee is non-refundable within 24 hours of check-in. </li>
                  <li> 
                      In the case of a refund, the process may be completed through cash or bank transfers.
                      <ul>
                        <li>For bank transfers, guests will need to provide the complete bank details where the refund is to be deposited.</li>
                      </ul>
                  </li>
                  
                  <li> No minors (persons below 18) will be allowed entry unless part of the family or a legal guardian can provide the necessary proof. The hotel reserves the right to decline the entry of the said guests. Thus, it will not result in refunds. </li>
                  <li> Losses or damages to items belonging to the hotel shall be charged to the guests. </li>
                  <li> All transactions will be coursed through the principal guest only. </li>
                  <li> No violence, drugs, hazing, or group sex will be allowed on our premises. </li>
                  <li> Booking revisions must be advised at least 24 hours prior to arrival. </li>
                  <li> No Show: Guests who do not arrive without prior advice will not be entitled to a refund.</li>
                </ol>
              </div>
            </div>
            <div class="modal-footer" style="display: block !important">
              <button type="button" @click="acceptBookingRules()" class="btn btn-default" data-dismiss="modal">Ok</button>
            </div>
          </div>
          
        </div>
      </div>
    <!-- </div> -->
</template>

<script>
  import services from '../../service/webservice'
  import styleModal from '../modal/modal.vue'
  export default {
    name: 'xHeader',
    components:{
      styleModal
    },
    mounted(){
      // alert(this.$store.getters.fetchHeader)
      // alert(this.$store.getters.fetchCoverVideo)
    },
    data(){
      // this.$store.getters.fetchCart,
      return {
        
          bookingRules: 'show',

          fetchbranch: this.$store.getters.getBranchInformations,
          fetchCartGetters: this.$store.getters.fetchCart,
          mobile_drawer: false,
          currentView: 'web',
          // totalCartQuantity: this.$store.getters.fetchCartQuantity
          totalCartQuantity: 0,
          // orderItems: JSON.parse(localStorage.getItem('myCart'))
          // orderItems: this.$store.state.cart
          // orderItems: this.$store.getters.fetchCart

          links: this.$store.getters.getlinks,
          // bookingbranchinfo: this.$store.getters.getMyBranchInfo,

          // -- WEB CONTENT
          webcontent: [],
          COVER: [],
          container: "container",
      }
    },
    props: {
      msg: String
    },
    created(){
      this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
      
      this.windowResizing()
      var resizedFinished;
      window.addEventListener("resize", ()=>{
        clearTimeout(resizedFinished);
        resizedFinished = setTimeout(()=>{
        this.windowResizing()
        },1000);
      });
      // console.log(this.totalCartQuantity);
    },
     updated: function () {
        this.$nextTick(function () {
          setTimeout(()=>{
            if( this.$store.getters.fetchHeader =='booking' ){
              this.checkBookingCookie()
            }
          },1000);
        })
    },
    methods: {
      bookingRulesShow(){
        // let data = browser.cookies.remove('BookingCookie')
        
        // this.setCookie('BookingCookie', 0, 0)
        // this.checkBookingCookie()
        const elem = this.$refs.myBtnModal
        elem.click()
      },
      checkBookingCookie(){
        if( this.getCookie('BookingCookie') == undefined ){
          setTimeout(()=>{
            const elem = this.$refs.myBtnModal
            elem.click()
          },1000);
        }
      },
      acceptBookingRules(){
        // localStorage.setItem('isCookie',1)
        this.setCookie('BookingCookie', 1, 1)
        this.checkBookingCookie()
      },

      setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        
      },
      getMyBranchNameInGetters(branch){
        if( branch != "" ){
          // console.log(this.fetchbranch)
          var myBranchName = this.fetchbranch[branch]['name']
          return myBranchName.replace('Victoria Court', '')
        }
      },
      menulinks(xlinks){
        if( xlinks._blank == false ){
          window.location.href = xlinks.link
        } else {
          window.open(xlinks.link , '_blank')
          // window.open(xlinks.link , '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        }
      },
      windowResizing(){
          // alert()
          var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          // alert(screenWidthCheck)
         if (screenWidthCheck > process.env.VUE_APP_TABLET_SIZE) {
          this.currentView = 'web'
          this.container = "container"
        } else {
          this.currentView = 'mobile'
          this.container = "container-fluid"
        }
          this.displayContent()
      },
      contentvariableReset(){
          this.COVER = []
      },
      imageVideoSet(path){
        var ext = path.split('.')
        var img = ""
        var arr = [
							"jpg","jpeg","jpe","jif","jfif","jfi",
							"png",
							"gif",
							"webp",
							"tiff","tif",
							"psd",
							"raw","arw","cr2","nrw","k25",
							"bmp","dib",
							"heif","heic",
							"ind","indd","indt",
							"jp2","j2k","jpf","jpx","jpm","mj2",
							"svg","svgz",
							"ai",
							"eps",
							"pdf"
						];
        if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
          img = `
            <img style="width: 100%" src="`+path+`"/>
          `;
        } else {
          img = `
            <video style="width:100%" id="background-video" autoplay loop muted>
                <source src="`+path+`" type="video/mp4">
              </video>
          `;
        }
        return img;
      },
      displayContent(){
          this.contentvariableReset()
          // RESET VARIABLE
          let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          // alert(width)

      // alert(this.$store.getters.fetchHeader)
      // alert(this.$store.getters.fetchCoverVideo)
          if( this.$store.getters.fetchHeader == 'home' ){
            if( width > process.env.VUE_APP_MOBILE_SIZE ){
                // -- WEB
                // -- COVER
                if( this.webcontent['web|'+this.$store.getters.fetchCoverVideo] ){
                    this.COVER.imgpath = this.webcontent['web|'+this.$store.getters.fetchCoverVideo]['image']
                    this.COVER.image = this.imageVideoSet(this.webcontent['web|'+this.$store.getters.fetchCoverVideo]['image'])
                    
                }
            } else {
                // -- MOBILE
                // -- COVER
                if( this.webcontent['mobile|'+this.$store.getters.fetchCoverVideo] ){
                    this.COVER.imgpath = this.webcontent['mobile|'+this.$store.getters.fetchCoverVideo]['image']
                    this.COVER.image = this.imageVideoSet(this.webcontent['mobile|'+this.$store.getters.fetchCoverVideo]['image'])
                }
            }
          }
          // alert(this.COVER.image)
      },
      getVCContent($url){
      this.xgetVCContent($url).then(response => {
          // console.log(response)
          this.webcontent = response.data
          // console.log(this.webcontent['web|home|vcFeatures|types|host'])
          this.displayContent()
      })
      },
      xgetVCContent($url){
          return services.fetchVCcontent($url)
      },
      formatter(price){
          return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      },
      updateLocalStorageOrder(){
        this.$store.state.cart.forEach(function(value){
          if( value['quantity'] == '' ){
            value['quantity'] = 1
          }
        });
        localStorage.setItem('myCart',JSON.stringify(this.$store.state.cart))
        this.$store.getters.fetchCart
      },
      showCart(){
        // alert('sokbu')
      },
      removeItem(prodID){
        this.$swal(
          '&nbsp;',
          'Do you want to cancel this order?'
        ).then((result)=>{
          if (result.isConfirmed) {

            var cart = []
            this.$store.state.cart.forEach(function (value){
              // console.log(key)
              if( value['product_id'] != prodID ){
                cart.push(value)
              }
            });
            localStorage.setItem('myCart',JSON.stringify(cart))
            this.$store.getters.fetchCart
            // this.$swal('', '', 'success')
            // console.log(result)
          } 
          // else if (result.isDenied) {
          //   // this.$swal('Changes are not saved', '', 'info')
          // }
        });

      },
      clickDrawer(){
        if( this.mobile_drawer == false ){
          this.mobile_drawer = true
        } else {
          this.mobile_drawer = false
        }
      },
      // OpenMobileDrawer(){

      //   if(this.mobile_drawer){
      //     this.mobile_drawer = false;
      //     // event.target.classList.toggle('bi-x');
      //   }
      //   else{
      //     this.mobile_drawer = true;
      //     // event.target.classList.toggle('bi-x');
      //   }

      // },
      // CloseMobileDrawer(){
      //     this.mobile_drawer = false;
      // }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .cart{
    font-size: 20px;
    text-align: right;
    /* background: url('../../../public/template/home/image/br-bg-skin.jpeg');
    background-size: 1px; */
  }
  .cart-items{
    border: 1px solid rgba(197, 194, 194, 0.925);
    margin-bottom: 20px;
  }
  .cart-border-left{
    border-left: 1px solid rgba(197, 194, 194, 0.925);
  }
  .cart-align-middle{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 30% 0 !important */
  }

  @media screen and (max-width: 768px) {
    .header__logo{
      padding: 0;
      text-align: left !important;
    }
    .header__logo img{
      width: 80px !important;
      /* height: 100px; */
    }
  }
  @media screen and (max-width: 480px) {
    .mobile-reset-padding{
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .cart{
      text-align: center;
    }
    .booking-bread{
      display: none;
    }
    /* .company-info{
      left: 0px !important;
      width: 100%;
    } */
    .header__logo{
      /* width: 60%; */
      /* width: 250px; */
      padding: 0;
      text-align: left !important;
    }
    .header__logo img{
      width: 100px !important;
      /* height: 100px; */
    }
    .nav_transparent{
      background: transparent !important;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .styles-header{
    background: transparent !important;
    border-bottom: 0px !important;
  }
  .styles-header.nav_transparent a,
  .cart a{
    color: white !important;
  }
  .styles-header.navbar_fixed a,
  .cart a{
    color: black !important;
  }
  .nav_transparent{
    background: rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid white;
  }
  .nav_transparent .header__menu ul li a,
  .header__nav__option,
  .cart a{
    color: white !important;
    /* color: black; */
  }
  .header__nav__option{
    padding: 0px !important;
  }

  .header_static{
    position: fixed;
  }


  .xxheader.breadcrumb_area .bg-parallax {
    background: url(../../assets/vcpasig.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 250px; */
    height: 100vh;
    top: -420px;
  }
  .xmobile-view.breadcrumb_area .bg-parallax {
    background: url(../../../public/template/home/image/facade.jpg);
    background-repeat: no-repeat;
    /* background-size: cover; */
    /* object-fit: contain; */
    /* height: 250px; */
    height: 100vh;
    width: 100%;
    /* top: -100px;
    left: -200px; */
    /* top: -420px; */
    /* top: -51vh;
    left: -90vw; */
    /* width: 100%; */
    /* top: -420px; */
    /* left: -50%;
    margin-left: 25%; */
    /* top: -35vh !important ;
    left: -25vh !important ; */
  }
  
  .xxheader.breadcrumb_area{
    padding: 200px 0px ;
    position: relative;
  }
  .xmobile-view{
    /* padding: 250px 0px !important; */
    padding: 0px !important;
  }
  .xmobile-view .bg-parallax{
    height: 100vh !important;
  }


  .company-info{
    position: absolute;
    bottom: 1px;
    left: 20px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
  }

  .nav_transparent .header__menu ul li .dropdown{
    background: black !important;
  }
  /* .btn-home-book-now{
    width: 200px;
  } */

  .header{
    background: transparent !important;
  }
  /* .navbar{
    background: transparent !important;
  } */
  .navbar_fixed .mobile-burger,
  .navbar_fixed .cart,
  .navbar_fixed .cart a{
    color: black !important;
    /* border: 1px solid black; */
  }
  .navbar_fixed{
    background: white !important;
    z-index: 3;
  }
  /* .header__menu ul li a,
  .header__nav__option{
    color: black !important;
  } */
  .header__menu ul li .dropdown{
    background: white !important;
  }
  .btn-home-book-now{
    width: 100%;
    font-weight: bold;
    border-style: none;
    padding: 10px;
    font-size: 15px;
  }
  .nav-header-title{
    font-weight: bold !important;
    font-size: 1.3em !important;
    cursor: pointer;
    color: white !important;
  }
  .bookingNavBG{
    background: black !important;
    /* color: white; */
  }


  #background-video{
    width: 100%;
  }
  
  #mobile-menu-wrap .menuLink{
    font-size: 20px !important;
    /* border-bottom: 1px solid black; */
    margin: 10px 0px;
  }
  #mobile-menu-wrap .menuLink:hover{
    background: rgba(0, 0, 0, 0.5);
    color: white;
  }
  .offcanvas-menu-wrapper{
    padding: 0px !important;
    overflow-x: hidden;
  }
  /* #mobile-menu-container .menuLink,
  #mobile-menu-container .btn-home-book-now
  {
    text-transform: lowercase;
  }  */
  #mobile-menu-container .menuLink{
    /* padding: 0px 20px; */
    /* text-indent: 10px; */
    /* padding: auto !important; */
    font-size: 20px !important;
    font-weight: bold;
    /* text-transform: capitalize; */
    /* text-decoration: underline; */
    color: white;
    text-shadow: 2px 2px 2px black;
  }

/* #mobile-menu-container .menuLink {
 text-transform: capitalize;
}  */

/* #mobile-menu-container .menuLink::first-line,
#mobile-menu-container .btn-home-book-now::first-line
{
 text-transform: capitalize;
} */
  /* .cart:hover{
    background: rgba(0, 0, 0, 0.5);
  } */
  .header__logo img{
    width: 70px;
  }
  .header__logo{
    text-align: center;
  }
  .banner_area{
    position: relative;
    min-height: 800px !important;
    z-index: -1;
  }
  .header__menu ul{
    margin: auto 0
  }
  /* .breadcrumb_area .bg-parallax {
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
      background-position: center !important;
      background-size: cover !important;
  } */
  .canvas__open{
    top: initial;
  }
  .overlay-info{
    position: absolute;
    top: 650px;
    padding-left: 20px;
    z-index: 1;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white
  }
  .overlay-info h1{
    font-size: 70px;
  }
  .booking_table{
    min-height: 780px !important;
  }

  .offcanvas-menu-wrapper {
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 600px;

    /* background: url(http://localhost:8080/img/bg-skin.57f69c5b.png); */
    /* background-repeat: repeat; */

    /* background: ghostwhite; */

    /* width: 100%; */
    /* padding-top: 20px; */
    /* background: transparent; */
    /* position: absolute; */
  }
  /* body.offcanvas-menu-wrapper.active{
    overflow-y: hidden !important;
  } */
  .navclose div{
    width: 50px;
    height: 50px;
    background: transparent;
    font-size: 20px;
    /* text-align: center; */
    /* margin: auto; */
    /* border: 1px solid black; */
  }
  .navclose div i{
    padding: 31%;
  }

  #mobile-menu-wrap img{
    display: none;
  }
  .xmobileMenuLogo{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 100px;
    margin-left: -25px;
  }
  .mobileMenuLogo{
    
    position: absolute;
    width: 200%;
    top: -20%;
    opacity: 0.5;
    z-index: -1;
  }
  #mobile-menu-container{
    position: absolute;
    width: 200px;
    height: 60%;
    top: 35%;
    /* top: 50%; */
    left: 100px;
    margin-left: -50px;
    /* margin-top: -50%; */
    /* text-align: center; */
  }
  #mobile-menu-container .theme_btn_two{
    border-radius: 20px;
    text-transform: capitalize;
    font-size: 20px !important;
    font-weight: bold;
  }
  .menuLink{
    text-transform: uppercase;
  }
  #mobile-menu-container .btn-home-book-now{
    position: absolute;
    bottom: 70px;
    width: 100%;
    /* left: 50%;
    margin-left: -25%; */
  }
</style>