<template>
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12 p-0">
              <!-- @click="redirectLocation('breakfast')" -->
              <div class="bites_category container-breakfast">
                <router-link to="/bites/breakfast">
                  <p class="div_title">Breakfast</p>
                </router-link>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-9">
              <div class="row">
                  <div class="col-md-6 p-0">
                    <div @click="redirectLocation('sandwich')" class="bites_category container-sandwich">
                        <p class="div_title">Sandwich</p>
                    </div>
                  </div>
                  <div class="col-md-6 p-0">
                    <div @click="redirectLocation('imperialfeast')" class="bites_category container-imperial">
                      <p class="div_title">Imperial Feast</p>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12 p-0">
                    <div @click="redirectLocation('meat')" class="bites_category container-meat">
                        <p class="div_title">Teasers, Meat and Chicken</p>
                    </div>
                  </div>
              </div>
          </div>
          <div class="col-md-3 p-0">
            <div @click="redirectLocation('bar')" class="bites_category container-bar">
              <p class="div_title">Bar</p>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'bites_n',
  // props: {
  //   msg: String
  // },,
  // components: {
  //   "vue-sticky-sidebar": vueStickySidebar
  // },
  data(){
    return {

    }
  },
  created(){
    this.$store.state.header = "bites";
  },
  mounted(){
    
  },
  methods:{
    redirectLocation(loc){
      alert(loc)
      // this.$router.params = '/bites/'+loc
      // window.location.href="/bites/"+loc
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .bites-container{
        /* text-align: center; */
    }
    .bites_category{
        margin: auto;
        padding: 0px;
        text-align: center;
        transition: transform 1s;
        position: relative;
        z-index: 1;
        border: 1px solid rgb(196, 197, 196);
    }
    .bites_category:hover{
      transform: scale(1.1);
        z-index: 2;
        border-radius: 2px;
        cursor: pointer;
      /* background-size: 150% !important; */
    }
    .container-breakfast{
        background: #4D8FAC;
        height: 200px;
        
        background-image: url("../../assets/img/bites/cover/breakfast.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .container-sandwich{
        background: #317589;
        height: 250px;
        background-image: url("../../assets/img/bites/cover/sandwich.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .container-imperial{
        background: #264348;
        height: 250px;
        
        background-image: url("../../assets/img/bites/cover/imperial.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .container-meat{
        background: #E08A1E;
        height: 200px;

        background-image: url("../../assets/img/bites/cover/meats.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .container-bar{
        background: #ABB7B7;
        height: 450px;

        background-image: url("../../assets/img/bites/cover/bar.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .div_title{
        color: white;
        font-size: 55px;
        font-weight: bold;
        text-shadow: 2px 2px black;
        /* margin-top: 50px; */
        border-radius: 2px;
        background: rgba(19, 19, 19, 0.5);
    }
    .container-breakfast p{
        line-height: 200px;
    }
    .container-sandwich p{
        line-height: 250px;
    }
    .container-imperial p{
        line-height: 250px;
    }
    .container-meat p{
        line-height: 200px;
    }
    .container-bar p{
        line-height: 450px;
    }
</style>
