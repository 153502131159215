import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import vuex from './data/data';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import VueSmoothScroll from 'vue3-smooth-scroll'

// import Popper from "vue3-popper"
// import * as mdb from 'mdb-ui-kit'; // lib
// import { Input } from 'mdb-ui-kit'; // module
// import Datepicker from 'vue-material-datepicker';


// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

app.use(router);
app.use(vuex);

const options = {
    showCloseButton: true,
    // showConfirmButton: false,
    confirmButtonColor: '#000000',
    // cancelButtonColor: '#d33',
    allowOutsideClick: false,

}
// console.log(options);
// app.component("Popper_", Popper);Vue.use(VueTelInput);
app.use(VueSweetalert2,options);
// app.use(VueSweetalert2);
app.use(BootstrapIconsPlugin);
app.use(VueSmoothScroll);
// app.component('xDatepicker', Datepicker);
// app.use(Datepicker);

app.mount('#App');
