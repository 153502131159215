<template>
  <div>
    <section class="shop spad"  style="padding-top: 150px !important">
        <center id="desc"></center>
    </section>
  </div>
</template>

<script>
    import services from '../../service/account_webservice'
    export default {
        name: 'verified_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                userid: '',
                company: process.env.VUE_APP_COMPANY_CODE,
                propt: ''
            }
        },
        created(){
            this.$store.state.header = "account";
            this.userid = this.$route.query.i
            this.activateAccount()
        },
        // mounted(){
            
        // }
        methods:{
            activateAccount(){
                this.activateAccountInfo().then(response => {
                    console.log(response.data)
                    if( response.data == 1 ){
                        this.propt = ` <h1>ACCOUNT SUCCESSFULLY ACTIVATED</h1>
                                    <h4>You may now login to <a href="/login">Victoria Court Portal.</a></h4>
                                    <h4>Thank You.</h4>`  
                    } else if( response.data == 2 ){
                        this.propt = ` <h1>THIS ACCOUNT IS ALREADY ACTIVATED</h1>
                                    <h4>You may now login to Victoria Court Portal</h4>
                                    <h4>Thank You.</h4>` 
                    } else {
                        this.propt = ` <h1>INCORRECT ACCOUNT</h1>
                                    <h4>We can't find your account. <a href="/signup">Click here to create a account.</a></h4>
                                    <h4>Thank You.</h4>` 
                    }
                    let xapp = document.getElementById('desc');
                    xapp.innerHTML = this.propt;  
                })
            },
            activateAccountInfo(){
                var xdata = {
                    company: this.company,
                    userid: this.userid,
                }
                return services.activateAccountInfo(xdata)
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
