<template>
    <xHeader/>
    <router-view/>
    <!-- <datepicker/> -->
    <xFooter/>
</template>

<script>

import xHeader from './components/header/header.vue';
import xFooter from './components/footer/footer.vue';
// import datepicker from '../node_modules/vue-datepicker/vue-datepicker.vue';


export default {
  name: 'App',
  components: {
    xHeader,
    xFooter,
    // datepicker
  },
  mounted(){
    
  },
  updated: function () {
      this.$nextTick(function () {
        alert()
        // if ( $('.testimonial_slider').length ){
            
        // }
      })
  },
}
</script>

<style>
</style>
