<template>
  <div>
    <!--================Banner Area =================-->
      <!-- <section id="sec-home" class="banner_area">
        <div class="booking_table d_flex align-items-center">
          <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background="">
            <video id="background-video" autoplay loop muted>
              <source src="../../assets/img/home/coverVideo.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </section> -->
      <!--================Banner Area =================-->

      <!--================ About History Area  =================-->
      <!-- <section class="about_history_area section_gap">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">About Us <br>Our History<br>Mission & Vision</h2>
                <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                <a href="#" class="button_hover theme_btn_two">Request Custom Price</a>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../../../public/template/home/image/about_bg.jpg" alt="img">
            </div>
          </div>
        </div>
      </section> -->
      <!--================ About History Area  =================-->
      
      <!--================ Testimonial Area  =================-->
      <section id="sec-testimony" class="testimonial_area section_gap bg-abstract">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">Our Popular Rooms</h2>
            <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p>
          </div>
          <div class="testimonial_slider locale_room_slider owl-carousel">
            <div class="media testimonial_item">
              <img class="roomIMG" src="../../assets/img/room/VCBA.jpg" alt="">
              <div class="media-body">
                <center>
                  <h2>Hamptons</h2>
                </center>
                <p class="popular_roomDesc">As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                <!-- <a href="#"><h4 class="sec_h4">1</h4></a> -->
                <!-- <div class="star">
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star-half-o"></i></a>
                </div> -->
                <div class="popular_inclusion">
                  <h4>Inclusions:</h4>
                  <div class="inc_imgs">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/wifi.svg" alt=""/>
                          <span class="inc_desc">Internet Connection</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/bathtub.svg" alt=""/>
                          <span class="inc_desc">Bathtub</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/dumbell.svg" alt=""/>
                          <span class="inc_desc">Gym</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popular_booknow">
                <center>
                  <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">BOOK NOW</a>
                </center>
              </div>
            </div>
            <div class="media testimonial_item">
              <img class="roomIMG" src="../../assets/img/room/VCBA.jpg" alt="">
              <div class="media-body">
                <center>
                  <h2>Hamptons</h2>
                </center>
                <p class="popular_roomDesc">As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                <!-- <a href="#"><h4 class="sec_h4">1</h4></a> -->
                <!-- <div class="star">
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star-half-o"></i></a>
                </div> -->
                <div class="popular_inclusion">
                  <h4>Inclusions:</h4>
                  <div class="inc_imgs">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/wifi.svg" alt=""/>
                          <span class="inc_desc">Internet Connection</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/bathtub.svg" alt=""/>
                          <span class="inc_desc">Bathtub</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/dumbell.svg" alt=""/>
                          <span class="inc_desc">Gym</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popular_booknow">
                <center>
                  <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">BOOK NOW</a>
                </center>
              </div>
            </div>
            <div class="media testimonial_item">
              <img class="roomIMG" src="../../assets/img/room/VCBA.jpg" alt="">
              <div class="media-body">
                <center>
                  <h2>Hamptons</h2>
                </center>
                <p class="popular_roomDesc">As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                <!-- <a href="#"><h4 class="sec_h4">1</h4></a> -->
                <!-- <div class="star">
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star-half-o"></i></a>
                </div> -->
                <div class="popular_inclusion">
                  <h4>Inclusions:</h4>
                  <div class="inc_imgs">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/wifi.svg" alt=""/>
                          <span class="inc_desc">Internet Connection</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/bathtub.svg" alt=""/>
                          <span class="inc_desc">Bathtub</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/dumbell.svg" alt=""/>
                          <span class="inc_desc">Gym</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popular_booknow">
                <center>
                  <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">BOOK NOW</a>
                </center>
              </div>
            </div>
            <div class="media testimonial_item">
              <img class="roomIMG" src="../../assets/img/room/VCBA.jpg" alt="">
              <div class="media-body">
                <center>
                  <h2>Hamptons</h2>
                </center>
                <p class="popular_roomDesc">As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                <!-- <a href="#"><h4 class="sec_h4">1</h4></a> -->
                <!-- <div class="star">
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star"></i></a>
                  <a href="#"><i class="fa fa-star-half-o"></i></a>
                </div> -->
                <div class="popular_inclusion">
                  <h4>Inclusions:</h4>
                  <div class="inc_imgs">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/wifi.svg" alt=""/>
                          <span class="inc_desc">Internet Connection</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/bathtub.svg" alt=""/>
                          <span class="inc_desc">Bathtub</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="xicon">
                          <img src="../../assets/img/room/amenities/dumbell.svg" alt=""/>
                          <span class="inc_desc">Gym</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popular_booknow">
                <center>
                  <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">BOOK NOW</a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--================ Testimonial Area  =================-->

      <section class="latest_blog_area section_gap">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">latest posts from blog</h2>
            <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p>
          </div>
          <img src="../../assets/img/home/pic.jpg" alt="">
        </div>
      </section>
      
      <!--================ MAP Area  =================-->
      <section class="testimonial_area section_gap bg-abstract">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">Location</h2>
            <!-- <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p> -->
          </div>
          <iframe id="mapDSPL"
            style="border:0;width: 100%;height: 70vh"
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAm0VhV-wvC3nxBeDR7tIp_38rmgl1FlyM&q=Victoria+court+gil+puyat">
          </iframe>
        </div>
      </section>
      <!--================ Testimonial Area  =================-->
      
      <!--================ Latest Blog Area  =================-->
      <section class="latest_blog_area section_gap">
        <div class="container">
          <div class="section_title text-center">
            <h2 class="title_color">latest posts from blog</h2>
            <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p>
          </div>
          <div class="row mb_30">
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-1.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">Low Cost Advertising</h4></a>
                  <p>Acres of Diamonds… you’ve read the famous story, or at least had it related to you. A farmer.</p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-2.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">Creative Outdoor Ads</h4></a>
                  <p>Self-doubt and fear interfere with our ability to achieve or set goals. Self-doubt and fear are</p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-recent-blog-post">
                <div class="thumb">
                  <img class="img-fluid" src="../../../public/template/home/image/blog/blog-3.jpg" alt="post">
                </div>
                <div class="details">
                  <div class="tags">
                    <a href="#" class="button_hover tag_btn">Travel</a>
                    <a href="#" class="button_hover tag_btn">Life Style</a>
                  </div>
                  <a href="#"><h4 class="sec_h4">It S Classified How To Utilize Free</h4></a>
                  <p>Why do you want to motivate yourself? Actually, just answering that question fully can </p>
                  <h6 class="date title_color">31st January,2018</h6>
                </div>	
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--================ Recent Area  =================-->
	
  </div>
</template>

<script>
export default {
  name: 'balintawak_n',
  // props: {
  //   msg: String
  // },
  data(){
    return {

    }
  },
  created(){
    this.$store.state.header = "home";
  },
  // mounted(){
    
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #background-video{
    width: 100%;
  }
  .bg-abstract{
    background: url("../../../public/template/home/image/page-bg.jpg");
    background-repeat: repeat;
    background-size: 2px;
  }
  .gray-bg{
    /* background: #e2e2e2; */
  }
  .vc_option{
    width: 100%;
    height: 500px;
  }
  .card-title{
    color: black !important;
  }
  .card-body{
    text-indent: 25px;
    text-align: justify;
  }
  .card-content{
    padding: 10px;
    background: white;
  }
  /* .custom-card{
  }
  .custom-card:hover{
    transition: box-shadow 250ms ease, transform 250ms ease;
  }
  @keyframes embouse-border {
    from {
      margin: auto;
      box-shadow: 0px;
    }
    to {
      margin: -10px;
      box-shadow: 1px 1px 20px black;
    }
  } */
  .btn-visit{
    font-size: 18px;
    padding: 5px 50px;
    border: 1px solid #FFD700;
    background: transparent;
    color: black;
  }
  .facilities_item p{
    color: white !important;
  }
  .roomIMG{
    width: 400px !important;
  }
  .popular_roomDesc{
    text-indent: 25px;
  }
  .popular_inclusion{
    margin-top: 50px;
  }
  .inc_desc{
    /* padding-left: 20px; */
  }
  .inc_imgs img{
    width: 20px;
  }
  .xicon{
    display: flex;
  }
  .testimonial_item{
    position: relative;
  }
  .popular_booknow{
    position: absolute;
    width: -webkit-fill-available;
    bottom: 20px;
    margin-left: -65px !important;
  }
  .testimonial_item{
    /* display: flex !important;
    align-items: stretch; */
    padding-bottom: 100px;
  }
</style>
