<template>
    <section v-if="currentView == 'web'">
        <div class="page-cover">
            <img v-if="COVER.path != undefined" :src="COVER.path" alt="">
            <img v-else src="../../assets/img/styles/home/vc-style-header.jpg" alt="">
            <!-- <button>Shop Now</button> -->
            <div class="cover-message">
                <h1>Your style introduces you before you speak</h1>
                <br>
                <!-- <a href="/vc-store" class="button_hover btn-default theme_btn_two btn-visit btn-shop-now" data-v-12835cef="">Shop Now</a> -->
            </div>
        </div>
    </section>
    <section v-else>
        <div class="page-cover mobile-banner">
            <img v-if="COVER.path != undefined" :src="COVER.path" alt="">
            <img v-else src="../../assets/styles/styles-mobile-img2.jpg" alt="">
            <!-- <button>Shop Now</button> -->
            <div class="cover-message">
                <h1>Get the power of great orgasms in your hands</h1>
                <br>
                <!-- <a href="/vc-store" class="button_hover btn-default theme_btn_two btn-visit btn-shop-now" data-v-12835cef="">Shop Now</a> -->
            </div>
        </div>
    </section>

    <section class="shop spad">
        <div class="container">
            <h1 class="div-title">Our Featured Products</h1>
            <div class="row">
                <div class="product-card col-lg-4 col-md-4 col-sm-12">
                    <div class="feature-item">
                        <!-- <img v-if="IMG1.image != undefined" :src="IMG1.image" alt=""> -->
                        <div v-if="IMG1.image != undefined" v-html="IMG1.image"></div>
                        <img v-else src="../../assets/img/styles/home/featured-prod.jpg" alt="">
                    </div>
                </div>
                <div class="product-card col-lg-4 col-md-4 col-sm-12">
                    <div class="feature-item">
                        <!-- <img v-if="IMG2.image != undefined" :src="IMG2.image" alt=""> -->
                        <div v-if="IMG2.image != undefined" v-html="IMG2.image"></div>
                        <img v-else src="../../assets/styles/souvenir.jpg" alt="">
                    </div>
                </div>
                <div class="product-card col-lg-4 col-md-4 col-sm-12">
                    <div class="feature-item">
                        <!-- <img v-if="IMG3.image != undefined" :src="IMG3.image" alt=""> -->
                        <div v-if="IMG3.image != undefined" v-html="IMG3.image"></div>
                        <img v-else src="../../assets/styles/adult_toys.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="shop spad">
        <div class="container">
            <div class="section_title text-center">
                <h2 class="title_color">Visit Our Online Stores</h2>
            </div>
            <div class="row">
                <div class="col-md-4 option_shop" style=";display:none">
                    <center>
                        <img src="../../assets/img/logo/VCLOGOonly.png" style="width: 200px;height: 200px;" alt="">
                    </center>
                    <div class="styles-desc">
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                    </div>
                    <a href="#" target="_blank" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                </div>
                <!-- LAZADA -->
                <div class="col-md-6 option_shop" style="">
                    <center>
                        <img src="../../assets/img/header/lazada.png" style="width: 300px;height: 200px;" alt="">
                    </center>
                    <div class="styles-desc">
                        <!-- There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. -->
                    </div>
                    <a href="https://www.lazada.com.ph/shop/victoria-court/" target="_blank" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                </div>
                <!-- SHOPEE -->
                <div class="col-md-6 option_shop" style="">
                    <center>
                        <img src="../../assets/img/header/shopee.png" style="width: 200px;height: 200px;" alt="">
                    </center>
                    <div class="styles-desc">
                        <!-- There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. -->
                    </div>
                    <a href="#" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                </div>
            </div>   
        </div>
    </section>
</template>

<script>
 // import vueStickySidebar from "vue-sticky-sidebar";
    import services from '../../service/webservice'
    export default {
    name: 'styles_n',
    // props: {
    //   msg: String
    // },,
    // components: {
    //   "vue-sticky-sidebar": vueStickySidebar
    // },
    data(){
        return {
            currentView: 'web',
            // -- WEB CONTENT
            COVER: [],
            webcontent: [], 
            IMG1: [], 
            IMG2: [], 
            IMG3: [], 
            MOREINFO: [], 
        }
    },
    created(){
        this.$store.state.header = "aboutus";
        this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
        this.windowResizing()
        window.addEventListener("resize", this.windowResizing);
    },
    mounted(){
        
    },
    methods:{

        windowResizing(){
            // alert()
            var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // alert(screenWidthCheck)
            if (screenWidthCheck > 480) {
            this.currentView = 'web'
            } else {
            this.currentView = 'mobile'
            }
            this.displayContent()
        },
        contentvariableReset(){
            this.COVER = []
            this.IMG1 = []
            this.IMG2 = []
            this.IMG3 = []
            this.MOREINFO = []
        },
        imageVideoSet(path){
        var ext = path.split('.')
        var img = ""
        var arr = [
                "jpg","jpeg","jpe","jif","jfif","jfi",
                "png",
                "gif",
                "webp",
                "tiff","tif",
                "psd",
                "raw","arw","cr2","nrw","k25",
                "bmp","dib",
                "heif","heic",
                "ind","indd","indt",
                "jp2","j2k","jpf","jpx","jpm","mj2",
                "svg","svgz",
                "ai",
                "eps",
                "pdf"
            ];
        if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
            img = `
            <img style="width: 100%" src="`+path+`"/>
            `;
        } else {
            img = `
            <video style="width:100%" id="background-video" autoplay loop muted>
                <source src="`+path+`" type="video/mp4">
                </video>
            `;
        }
        return img;
        },
        displayContent(){
            this.contentvariableReset()
            // RESET VARIABLE
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // alert(width)
            if( width > 480 ){
                // -- WEB
                // -- COVER
                if( this.webcontent['web|styles|store|cover'] ){
                    this.COVER.image = this.imageVideoSet(this.webcontent['web|styles|store|cover']['image'])
                    this.COVER.path = this.webcontent['web|styles|store|cover']['image']
                }
                // -- FEATURE PROD
                // -- IMG 1
                if( this.webcontent['web|styles|feature|img1'] ){
                    this.IMG1.image = this.imageVideoSet(this.webcontent['web|styles|feature|img1']['image'])
                    this.IMG1.path = this.webcontent['web|styles|feature|img1']['image']
                }
                // -- IMG 2
                if( this.webcontent['web|styles|feature|img2'] ){
                    this.IMG2.image = this.imageVideoSet(this.webcontent['web|styles|feature|img2']['image'])
                    this.IMG2.path = this.webcontent['web|styles|feature|img2']['image']
                }
                // -- IMG 3
                if( this.webcontent['web|styles|feature|img3'] ){
                    this.IMG3.image = this.imageVideoSet(this.webcontent['web|styles|feature|img3']['image'])
                    this.IMG3.path = this.webcontent['web|styles|feature|img3']['image']
                }
                // -- MORE INFO
                if( this.webcontent['web|styles|moreinfo'] ){
                    this.MOREINFO.image = this.imageVideoSet(this.webcontent['web|styles|moreinfo']['image'])
                    this.MOREINFO.path = this.webcontent['web|styles|moreinfo']['image']
                    this.MOREINFO.description = this.webcontent['web|styles|moreinfo']['description']
                }
            } else {
                // -- MOBILE
                // -- COVER
                if( this.webcontent['mobile|styles|store|cover'] ){
                    this.COVER.image = this.webcontent['mobile|styles|store|cover']['image']
                }
                // -- FEATURE PROD
                // -- IMG 1
                if( this.webcontent['mobile|styles|feature|img1'] ){
                    this.IMG1.image = this.webcontent['mobile|styles|feature|img1']['image']
                }
                // -- IMG 2
                if( this.webcontent['mobile|styles|feature|img2'] ){
                    this.IMG2.image = this.webcontent['mobile|styles|feature|img2']['image']
                }
                // -- IMG 3
                if( this.webcontent['mobile|styles|feature|img3'] ){
                    this.IMG3.image = this.webcontent['mobile|styles|feature|img3']['image']
                }
                // -- MORE INFO
                if( this.webcontent['mobile|styles|moreinfo'] ){
                    this.MOREINFO.image = this.webcontent['mobile|styles|moreinfo']['image']
                    this.MOREINFO.description = this.webcontent['mobile|styles|moreinfo']['description']
                }
            }
        },
        getVCContent($url){
        this.xgetVCContent($url).then(response => {
            // console.log(response)
            this.webcontent = response.data
            // console.log(this.webcontent['web|home|vcFeatures|types|host'])
            this.displayContent()
        })
        },
        xgetVCContent($url){
            return services.fetchVCcontent($url)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #styles-modal{
    top: 15% !important;
  }

  /* #styles-modal .option_shop{
    background: white;
  } */

  #styles-modal .modal-content {
    background: rgba(255, 255, 255, 0.9) !important;
    /* background: transparent !important; */
    border: 1px solid transparent;
  }

  #styles-modal .modal-dialog {
    max-width: 95%;
  }

  #styles-modal .modal-header {
    border: 1px solid transparent !important;
  }

  /* #styles-modal .modal-body {
    background: white !important;
  } */

  .xbtn-features{
    color: black;
    background: white;
    /* position: absolute !important; */
    /* bottom: 10px; */
    width: 80%;
    left: 10% !important;
    border: 1px solid #FFD700;
    /* padding: auto 30px !important; */
  }

  .styles-desc{
    text-align: justify;
    margin-bottom: 30px;
  }



    .page-cover{
        position: relative !important;
        max-width: 1900px;
        /* height: auto; */
        /* align-content: center; */
        margin: auto;
    }
    .page-cover img{
        width: 100%;
        /* height: 100%; */
        position: relative;
    }
    .page-cover .cover-message{
        position: absolute;
        top: 45%;
        color: white;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .cover-message h1{
        font-size: 45px;
    }
    .btn-shop-now{
        border: 1px solid #f8b100;
        width: 200px;
    }
    .div-title{
        text-align: center;
    }
    .feature-item img{
        height: 500px;
        width: 100%;
    }
    .fashion{
        width: 650px !important;
        right: 150px;
        top: 20% !important;
        text-align: justify !important;
    }
    .mobile-banner{
        height: 500px;
    }
    .mobile-banner img{
        height: 100%;
    }
    .mobile-banner .cover-message{
        top: 300px;
    }
    @media screen and (max-width: 480px) {
        .cover-message h1{
            font-size: 20px;
        }
        .fashion{
            width: 300px !important;
            /* right: 150px; */
            right: 10px;
            top: 60% !important;
        }
        .xfashion{
            /* height: 300px; */
        }
        .xfashion img{
            height: 300px;
        }
    }
    .product-card{
        margin-bottom: 10px;
    }

</style>
