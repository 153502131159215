<template>
 <!-- v-if="this.$store.getters.fetchHeader!='bites'" -->
  <div>
    <!--================ start footer Area  =================-->	
    <section id="sec-contacts">
      <footer class="footer-area">
        <div class="container">
          <div class="row">
            <div class="offset-md-1 col-md-10">
              <div class="row">
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="single-footer-widget">
                    <h6 class="footer_title">About Us</h6>
                    <p>Get whisked away to fantastic places unlike anything you've experienced only at Victoria Court. You'd be surprised at the exciting worlds you will discover, the sizzling moments you will share, and the romantic memories you will make.</p>
                  </div>
                </div> -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="single-footer-widget">
                    <h6 class="footer_title">Navigation Links</h6>
                    <div class="row">
                      <div class="col-6">
                        <ul class="list_style">
                          <!-- <li><a href="/home">Home</a></li> -->
                          <li><a :href="links.host.link" @click.prevent="menulinks(links.host)">VICTORIA HOST</a></li>
                          <li><a :href="links.bites.link" @click.prevent="menulinks(links.bites)">VICTORIA BITES</a></li>
                          <!-- <li><a :href="links.styles.link" @click.prevent="menulinks(links.styles)">VICTORIA STYLE</a></li> -->
                          <li><a href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal">VICTORIA STYLE</a></li>
                          
                          <!-- <li><a :href="links.speaks.link" @click.prevent="menulinks(links.speaks)">VICTORIA SPEAKS</a></li> -->
                        </ul>
                      </div>									
                    </div>							
                  </div>
                </div>	
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="single-footer-widget">
                    <!-- <h6 style="visibility: hidden" class="footer_title">Careers</h6>
                    <div class="row">
                      <div class="col-6">
                      </div>									
                    </div> -->
                    <!-- <h6 class="footer_title">Corporate Social Responsibility</h6> -->
                    <h6 class="footer_title">Legal Terms & Conditions</h6>			
                    <div class="row">
                      <div class="col-6">
                        <ul class="list_style">
                          <!-- <li><a href="/home">Home</a></li> -->
                          <li><a href="/legalterms" target="_blank">LEGAL TERMS</a></li>
                          <li><a href="/privacypolicy" target="_blank">PRIVACY POLICY</a></li>
                          <!-- <li><a href="#">Cookie Policy</a></li> -->
                          <!-- <li><a href="#">Sitemap</a></li> -->
                        </ul>
                      </div>									
                    </div>					
                  </div>
                </div>		
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="single-footer-widget">
                    <h6 class="footer_title">Social Media</h6>
                    <div class="row footer-bottom d-flex justify-content-between align-items-center" style="padding-top: 1px !important">
                       <div class="col-12 col-sm-12 footer-social" style="text-align: left !important">
                        <a target="_blank" href="https://www.facebook.com/victoriacourtvc" style="margin-left: 0 !important"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://www.twitter.com/victoriacourtvc"><i class="fa fa-twitter"></i></a>
                        <a target="_blank" href="https://www.instagram.com/victoriacourtvc"><i class="fa fa-instagram"></i></a>
                        <a target="_blank" href="https://www.youtube.com/victoriacourtvc"><i class="fa fa-youtube"></i></a>
                      </div>	
                    </div>
                    <h6 class="footer_title">Contact</h6>		
                    <div class="row">
                      <div class="col-12">
                        <ul class="list_style">
                          <li><a href="tel:0917-VCSERVE"><i class="fa fa-phone"></i> 0917-VCSERVE</a></li>
                          <li><a href="mailto:serve@victoriacourt.com" target="_blank"><i class="fa fa-envelope"></i> serve@victoriacourt.com</a></li>
                        </ul>
                      </div>									
                    </div>		
                  </div>
                </div>							
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                  <h6 class="footer_title">Newsletter</h6>
                  <p>For business professionals caught between high OEM price and mediocre print and graphic output, </p>		
                  <div id="mc_embed_signup">
                    <form target="_blank" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01" method="get" class="subscribe_form relative">
                      <div class="input-group d-flex flex-row">
                        <input name="EMAIL" placeholder="Email Address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address '" required="" type="email">
                        <button class="btn sub-btn"><span class="lnr lnr-location"></span></button>		
                      </div>									
                      <div class="mt-10 info"></div>
                    </form>
                  </div>
                </div>
                </div> -->
              </div>
            </div>
            
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-footer-widget instafeed">
                <h6 class="footer_title">InstaFeed</h6>
                <ul class="list_style instafeed d-flex flex-wrap">
                  <li><img src="../../../public/template/home/image/instagram/Image-01.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-02.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-03.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-04.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-05.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-06.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-07.jpg" alt=""></li>
                  <li><img src="../../../public/template/home/image/instagram/Image-08.jpg" alt=""></li>
                </ul>
              </div>
            </div>						 -->
          </div>
          <!-- <div class="border_line"></div> -->
          <!-- <div class="row footer-bottom d-flex justify-content-between align-items-center">
            <p class="col-lg-8 col-sm-12 footer-text m-0">
              Copyright &copy;2022 All rights reserved
            </p>
            <div class="col-lg-4 col-sm-12 footer-social">
              <a target="_blank" href="https://www.facebook.com/victoriacourtvc"><i class="fa fa-facebook"></i></a>
              <a target="_blank" href="https://www.twitter.com/victoriacourtvc"><i class="fa fa-twitter"></i></a>
              <a target="_blank" href="https://www.instagram.com/victoriacourtvc"><i class="fa fa-instagram"></i></a>
              <a target="_blank" href="https://www.youtube.com/victoriacourtvc"><i class="fa fa-youtube"></i></a>
            </div>
          </div> -->
        </div>
      </footer>
    </section>
    <!--================ End footer Area  =================-->
    <div v-if="acceptCooke == 0" class="cookie">
      <div class="container-cookie">
        <div class="cookie-content">
          In order to know which cookies are used in the Website, how to disable them and/or withhold consent, or for any further information, see the <u><a href="/privacypolicy#cookie">cookie policy</a></u>.
        </div>
        <div class="cookie-button">
          <a href="#" @click.prevent="acceptCookie()" style="color: white !important;" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now ">Accept Cookies</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'xFooter',
  props: {
    msg: String
  },
  data(){
    return {
      links: this.$store.getters.getlinks,
      acceptCooke: 0
    }
  },
  mounted(){
    this.checkCookie()
  },
  updated: function () {
      this.$nextTick(function () {
        setTimeout(()=>{
          this.loadOtherScripts()
        },1000);
      })
  },
  methods:{
      loadOtherScripts(){
        console.log(this.$store.getters.getStatusOtherScript)
        if( this.$store.getters.getStatusOtherScript == 1 ){
          this.loadExternalScript('/template/malefashion/js/jquery-3.3.1.min.js')
          setTimeout(()=>{
            this.loadExternalScript('/template/malefashion/js/bootstrap.min.js')
            this.loadExternalScript('/template/malefashion/js/jquery.nicescroll.min.js')
            this.loadExternalScript('/template/malefashion/js/jquery.magnific-popup.min.js')
            this.loadExternalScript('/template/malefashion/js/jquery.countdown.min.js')
            this.loadExternalScript('/template/malefashion/js/jquery.slicknav.js')
            this.loadExternalScript('/template/malefashion/js/mixitup.min.js')
            this.loadExternalScript('/template/malefashion/js/owl.carousel.min.js')
            this.loadExternalScript('/template/malefashion/js/main.js')
          },1000);
          this.loadExternalScript('/template/home/js/jquery-3.2.1.min.js')
          setTimeout(()=>{
            this.loadExternalScript('/template/home/js/popper.js')
            this.loadExternalScript('/template/home/js/bootstrap.min.js')
            this.loadExternalScript('/template/home/vendors/owl-carousel/owl.carousel.min.js')
            this.loadExternalScript('/template/home/js/jquery.ajaxchimp.min.js')
            this.loadExternalScript('/template/home/js/mail-script.js')
            this.loadExternalScript('/template/home/vendors/bootstrap-datepicker/bootstrap-datetimepicker.min.js')
            this.loadExternalScript('/template/home/js/stellar.js')
            this.loadExternalScript('/template/home/vendors/lightbox/simpleLightbox.min.js')
            this.loadExternalScript('/template/home/js/custom.js')

            console.log("LOADED")

          },1500);
        } else {
          console.log('Again')
          setTimeout(()=>{
            this.loadOtherScripts()
          },1000);
        }
      },
      loadExternalScript(link){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', link)
        document.head.appendChild(recaptchaScript)
      },
      checkCookie(){
        if( this.getCookie('acceptCookie') != '' ){
          this.acceptCooke = 1
        }
      },
      acceptCookie(){
        // localStorage.setItem('isCookie',1)
        this.setCookie('acceptCookie', 1, 1)
        this.checkCookie()
      },

      setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },

      menulinks(xlinks){
        if( xlinks._blank == false ){
          window.location.href = xlinks.link
        } else {
          window.open(xlinks.link , '_blank')
        }
      },
  }
}
</script>

<style>
  @font-face {
    font-family: 'GT';
    src: url('../../assets/font/GTAF/GT America Regular/GT America Regular.otf') format('otf'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
         url('../../assets/font/GTAF/GT America Regular/GT America Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }
  *:not(i){
    font-family: GT !important;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #sec-contacts{
    position: relative;
    /* top: 100vh; */
  }
  .footer-area{
    padding: 90px 0px !important;
  }
  .footer_title{
    /* text-align: center; */
    /* padding-top: 10px; */
    margin-bottom: 10px;
  }
  .footer-social{
    margin-top: 1px !important;
    margin-bottom: 30px !important;
  }
  .cookie{
    position: fixed;
    bottom: 1px;
    z-index: 9999999;
    width: 100%;
    background: white;
  }
  .cookie .container-cookie{
    /* position: relative; */
    /* margin: 0 auto; */
    padding: 50px 25% 20px;
  }
  .cookie .cookie-button{
    padding-top: 25px;
  }
  @media screen and (max-width: 480px) {
    .cookie .container-cookie{
      padding: 50px 10% 20px;
    }
  }
</style>
