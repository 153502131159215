<template>
  <div>
    <div class="home-content">
        <!--================ Accomodation Area  =================-->
        <div class="booking-form">
          <div class="row">
            <div class="col-md-6">
              <label>Location</label>
              <select @change="changeBranch()" v-model="branch" name="" id="" class="form-control input-booking">
                <option v-for="xbranch in branches.data" :key="xbranch.id" :value="xbranch.branch_code">{{xbranch.branch}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Stay</label>
              <select v-model="roomrate" class="form-control input-booking">
                <option v-for="xroomrate in roomRateList.data" :key="xroomrate.id" :value="xroomrate.roomrate.id">{{xroomrate.roomrate.room_rate}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Date</label>
              <!-- <datepicker
                weekStartsOn="0"
                :lowerLimit="xbookingDate"
                class="form-control"
                v-model="bookingDate"
              ></datepicker> -->
              <input @change="backToOriginalDate()" type="date" required v-model="bookingDate" :min="xbookingDate" onkeydown="return false"  class="form-control input-booking">
            </div>
            <div class="col-md-6">
              <label>Time</label>
              <input @change="backToOriginalTime()" type="time" required v-model="bookingTime" onkeydown="return false"  class="form-control input-booking">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 offset-lg-3 col-lg-6 col-md-12">
              <br><br><br>
              <!-- form-control corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now -->
              <button style="color: white !important;vertical-align: middle;border-radius: 0" class=" form-control  corbel btn-default btn-visit btn-home-book-now" @click="this.checkAvailability()" >Check Availability</button>
            </div>
          </div>
        </div>
        <section id="sec-about" class=" section_gap bg-abstract">
          <div :class="container">
            <div class="section_title text-center">
              <h2 class="title_color">VICTORIA COURT</h2>
              <!-- <p>We all live in an age that belongs to the young at heart. Life that is becoming extremely fast, </p> -->
            </div>
            <div class="row mb_30">
              <div class="col-lg-4 col-sm-4">
                <div class="custom-card">
                  <div class="image-container">
                    <div v-if="HOST.image != undefined" class="vc_option" v-html="HOST.image"></div>
                    <img v-else class="vc_option" src="../../assets/img/home/img_host.jpg" alt="">
                    
                  </div>
                  <div class="card-content">
                    <h3><u class="card-title">Victoria Host</u></h3>
                    <div class="card-body">
                      <span v-if="HOST.description != undefined" v-html="HOST.description"></span>
                      <span v-else>The Victoria Court brand has been synonymous with great service and privacy guaranteed. More than the one-of-a-kind rooms with meticulously designed unique thematic rooms, every stay at Victoria Court is unique on its own!</span>
                    </div>
                    <center>
                      <a :href="links.host.link" class="button_hover theme_btn_two btn-features">Visit Site</a>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="custom-card">
                  <div class="image-container">
                    <div v-if="BITES.image != undefined" class="vc_option" v-html="BITES.image"></div>
                    <img v-else class="vc_option" src="../../assets/img/home/img_bites.jpg" alt="">
                  </div>
                  <div class="card-content">
                    <h3><u class="card-title">Victoria Bites</u></h3>
                    <div class="card-body">
                      <span v-if="BITES.description != undefined" v-html="BITES.description"></span>
                      <span v-else >Victoria Bites offers the best lutong-bahay that has been loved by everyone since the 80s! Serving sumptuous food to Victoria Court guests inside their rooms is now available for pick-up and delivery at your doorsteps, 24/7!</span>
                    </div>
                    <center>
                      <a :href="links.bites.link" target="_blank" class="button_hover theme_btn_two btn-features">Visit Site</a>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="custom-card">
                  <div class="image-container">
                    <div v-if="STYLES.image != undefined" class="vc_option" v-html="STYLES.image"></div>
                    <img v-else class="vc_option" src="../../../public/template/home/image/vc_style.jpg" alt="">
                  </div>
                  <div class="card-content">
                    <h3><u class="card-title">Victoria Style</u></h3>
                    <div class="card-body">
                      <span v-if="STYLES.description != undefined" v-html="STYLES.description"></span>
                      <span>Feel the Victoria Court vibes with Victoria Style! We offer high-quality merchandise that is uniquely hip, cool, and relevant!</span>
                    </div>
                    <center>
                      <!-- <a :href="links.styles.link" target="_blank" class="button_hover theme_btn_two btn-features">Visit Site</a> -->
                      <a href="#" @click.prevent="" data-toggle="modal" data-target="#styles-modal" class="button_hover theme_btn_two btn-features">Visit Site</a>
                      <!-- <button class="btn btn-visit btn-features">Visit Site</button> -->
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6" style="display: none">
                <div class="custom-card">
                  <div class="image-container">
                    <div v-if="SPEAKS.image != undefined" class="vc_option" v-html="SPEAKS.image"></div>
                    <img v-else class="vc_option" src="../../../public/template/home/image/vc_speaks.jpg" alt="">
                  </div>
                  <div class="card-content">
                    <h3><u class="card-title">Victoria Speaks</u></h3>
                    <div class="card-body">
                      <span v-if="SPEAKS.description != undefined" v-html="SPEAKS.description"></span>
                      <span v-else>Speak your mind with Victoria Speaks! This new platform will surely bring out the inner sexy in you. Talks and discussions about love, romance, sex, pop-culture and other exciting topics will be featured in Victoria Speaks.</span>
                    </div>
                    <center>
                      <a :href="links.speaks.link" class="button_hover theme_btn_two btn-features">Visit Site</a>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--================ Accomodation Area  =================-->
        
        <!--================ Facilities Area  =================-->
        <section id="sec-room" class="facilities_area section_gap">
          <div :class="container">
            <div class="overlay bg-parallax" :style="{background: 'url('+ require(`@/assets/img/home/Background_type_of_rooms.jpg`) +') no-repeat scroll center 0/cover'}" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background="">  
            </div>
            <div class="container-fluid">
              <div class="section_title text-center">
                <h2 class="title_w">Type of Rooms</h2>
                <!-- <p>Who are in extremely love with eco friendly system.</p> -->
              </div>
              <div class="row mb_30">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="facilities_item">
                    <h4 class="sec_h4">
                      <!-- <i class="lnr lnr-dinner"></i> -->
                      <u style="color: white !important">Standard</u>
                    </h4>
                    <p v-if="STANDARD.description != undefined" v-html="STANDARD.description"></p>
                    <p v-else >There’s nothing standard about our standard rooms! Each Victoria Court Standard Room is uniquely designed to ignite your imagination for a wonderful and pleasurable experience!</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="facilities_item">
                    <h4 class="sec_h4">
                      <!-- <i class="lnr lnr-dinner"></i> -->
                      <u style="color: white !important">Deluxe</u>
                    </h4>
                    <p v-if="DELUXE.description != undefined" v-html="DELUXE.description"></p>
                    <p v-else>More room for your imagination with the Victoria Court Deluxe Room! A bigger room with more interesting interiors, plus a mini refrigerator, all for your enjoyment! </p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="facilities_item">
                    <h4 class="sec_h4">
                      <!-- <i class="lnr lnr-dinner"></i> -->
                      <u style="color: white !important">Suite</u>
                    </h4>
                    <p v-if="SUITE.description != undefined" v-html="SUITE.description"></p>
                    <p v-else>Playful yet sophisticated. Our suite rooms are designed to fulfill your fantasies with their well-thought-out interiors and themes that we can relate to. All of our suite rooms come with either a bathtub, a sauna, or a jacuzzi</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div class="facilities_item">
                    <h4 class="sec_h4">
                      <!-- <i class="lnr lnr-dinner"></i> -->
                      <u style="color: white !important">Thematic</u>
                    </h4>
                    <p v-if="THEMATIC.description != undefined" v-html="THEMATIC.description"></p>
                    <p v-else>Enter a different world with our thematic rooms. Once you step inside, you’ll be transformed into a world of surprises brought to you by the thematic interiors of the room. These rooms also come with either a bathtub, a sauna, or a jacuzzi, plus a video karaoke machine.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--================ Facilities Area  =================-->
        
        <!--================ About History Area  =================-->
        <section class="about_history_area section_gap bg-abstract">
          <div :class="container">
            <div class="row">
              <div class="col-md-6 d_flex align-items-center">
                <div class="about_content ">
                  <h2 class="title title_color">About Us</h2>
                  <p v-if="ABOUTUS.description != undefined" v-html="ABOUTUS.description"></p>
                  <p v-else>Get whisked away to fantastic places, unlike anything you've experienced only at Victoria Court. You'd be surprised at the exciting worlds you will discover, the sizzling moments you will share, and the romantic memories you will make.</p>
                  <a href="/aboutus" class="button_hover theme_btn_two">Read More</a>
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="ABOUTUS.image != undefined" class="img-fluid" v-html="ABOUTUS.image"></div>
                <img v-else class="img-fluid" src="../../assets/img/home/about_us_cover.jpg" alt="img">
              </div>
            </div>
          </div>
        </section>
        <!--================ About History Area  =================-->
        
        <!--================ Testimonial Area  =================-->
        <section id="sec-testimony" class="testimonial_area section_gap">
          <div :class="container">
            <div class="section_title text-center">
              <h2 class="title_color">Testimonial from our Clients</h2>
              <!-- <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p> -->
            </div>
            <div name="displayTestimonyInfo" v-if="(TESTIMONIAL).length != 0" class="testimonial_slider owl-carousel">
              <div v-for="testiList in TESTIMONIAL" :key="testiList.name" class="media testimonial_item">
                <img style="width: 80px;height: 80px;" class="rounded-circle" :src="testiList.image" alt="">
                <div class="media-body">
                  <p v-html="testiList.description"></p>
                  <!-- <a href="#" @click.prevent=""><h4 class="sec_h4" style="white-space: pre-line">{{testiList.name}}</h4></a> -->
                  <div class="sec_h4" style="position: absolute;bottom: 20px">{{testiList.name}}</div>
                  <!-- <div class="star">
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star-half-o"></i></a>
                  </div> -->
                </div>
              </div> 
            </div>
            <div name="displayTestimonyInfo" v-else class="testimonial_slider owl-carousel">   
              <div class="media testimonial_item">
                <img class="rounded-circle" src="../../../public/template/home/image/testtimonial-1.jpg" alt="">
                <div class="media-body">
                  <p>As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                  <a href="#" @click.prevent=""><h4 class="sec_h4" style="white-space: pre-line">Fanny Spencer</h4></a>
                  <div class="star">
                    <!-- <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star-half-o"></i></a> -->
                  </div>
                </div>
              </div>
              <div class="media testimonial_item">
                <img class="rounded-circle" src="../../../public/template/home/image/testtimonial-1.jpg" alt="">
                <div class="media-body">
                  <p>As conscious traveling Paupers we must always be concerned about our dear Mother Earth. If you think about it, you travel across her face, and She is the </p>
                  <a href="#" @click.prevent=""><h4 class="sec_h4" style="white-space: pre-line">Fanny Spencer</h4></a>
                  <div class="star">
                    <!-- <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star"></i></a>
                    <a href="#"><i class="fa fa-star-half-o"></i></a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--================ Testimonial Area  =================-->
        
        <!--================ Latest Blog Area  =================-->
        <section class="latest_blog_area section_gap bg-abstract" style="display: none">
          <div :class="container">
            <div class="section_title text-center">
              <h2 class="title_color">latest posts from blog</h2>
              <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from </p>
            </div>
            <div class="row mb_30">
              <div class="col-lg-4 col-md-6">
                <div class="single-recent-blog-post">
                  <div class="thumb">
                    <img class="img-fluid" src="../../assets/img/home/blog_cover.jpg" alt="post">
                  </div>
                  <div class="details">
                    <div class="tags">
                      <a href="#" class="button_hover tag_btn">Travel</a>
                      <a href="#" class="button_hover tag_btn">Life Style</a>
                    </div>
                    <a href="#"><h4 class="sec_h4">Blog</h4></a>
                    <p>Acres of Diamonds… you’ve read the famous story, or at least had it related to you. A farmer.</p>
                    <h6 class="date title_color">31st January,2018</h6>
                  </div>	
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-recent-blog-post">
                  <div class="thumb">
                    <img class="img-fluid" src="../../../public/template/home/image/blog/blog-2.jpg" alt="post">
                  </div>
                  <div class="details">
                    <div class="tags">
                      <a href="#" class="button_hover tag_btn">Travel</a>
                      <a href="#" class="button_hover tag_btn">Life Style</a>
                    </div>
                    <a href="#"><h4 class="sec_h4">Erotica</h4></a>
                    <p>Self-doubt and fear interfere with our ability to achieve or set goals. Self-doubt and fear are</p>
                    <h6 class="date title_color">31st January,2018</h6>
                  </div>	
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-recent-blog-post">
                  <div class="thumb">
                    <img class="img-fluid" src="../../../public/template/home/image/blog/blog-3.jpg" alt="post">
                  </div>
                  <div class="details">
                    <div class="tags">
                      <a href="#" class="button_hover tag_btn">Travel</a>
                      <a href="#" class="button_hover tag_btn">Life Style</a>
                    </div>
                    <a href="#"><h4 class="sec_h4">Article</h4></a>
                    <p>Why do you want to motivate yourself? Actually, just answering that question fully can </p>
                    <h6 class="date title_color">31st January,2018</h6>
                  </div>	
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--================ Recent Area  =================-->
    
    </div>
  </div>
  <!-- <styleModal></styleModal> -->
</template>

<script>
// import { ref } from 'vue';
import services from '../../service/webservice'
  // import styleModal from '../modal/modal.vue'
// import $ from "jquery";
// import { xfunction,xowl } from "../../../public/template/malefashion/js/xmain"
// import datepicker from 'vue3-datepicker'
export default {
  name: 'home_n',
  // props: {
  //   msg: String
  // },
  components: {
                // datepicker,
                // styleModal
              },
  data(){
    return {
      company: process.env.VUE_APP_COMPANY_CODE,
      rooms: [],
      branches: [],
      branch: "",
      roomRateList: [],
      roomrate: "",
      bookingDate: '',
      xbookingDate: '',
      bookingTime: "",
      xbookingTime: "",

      // -- WEB CONTENTS
      webcontent: [],
      // -- FEATURES
      HOST: [],
      BITES: [],
      STYLES: [],
      SPEAKS: [],
      // -- TYPES ROOM
      STANDARD: [],
      DELUXE: [],
      SUITE: [],
      THEMATIC: [],
      // -- ABOUT US
      ABOUTUS: [],
      // -- TESTIMONIAL
      TESTIMONIAL: [],
      dumTestimonial: [],
      
      links: this.$store.getters.getlinks,

      checkingTestimony: "",
      container: "container"

    }
  },
  created(){
    this.$store.state.header = "home";
    this.$store.state.covervideo = "home|cover";
    // this.$store.state.header = "aboutus";

    // var today = new Date(Date.now()+2*24*60*60*1000);
    var today = new Date(Date.now()+12*60*60*1000);
    var date = today.getDate()
    var month = today.getMonth()
    var year = today.getFullYear()
    // this.bookingDate = ref(new Date(year,month,date));
    // this.xbookingDate = ref(new Date(year,month,date));
    this.bookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
    this.xbookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
      var bHrs = today.getHours() < 10 ? '0'+today.getHours() : today.getHours();
      var bMins = today.getMinutes() < 10 ? '0'+today.getMinutes() : today.getMinutes();
      this.xbookingTime = bHrs+":"+bMins;
      this.bookingTime = bHrs+":"+bMins;

    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    
    this.windowResizing()
    var resizedFinished;
    window.addEventListener("resize", ()=>{
      clearTimeout(resizedFinished);
      resizedFinished = setTimeout(()=>{
       this.windowResizing()
      },1000);
    });

    // console.log(this.TESTIMONIAL)
  // console.log(this.getVCContent())
    
  },
  mounted(){
    this.getbranch().then(response => {
      // console.log(response)
      this.branches = response
      this.branch = response.data[0]['branch_code']

      this.getRates()

      // this.checkingTestimony = setInterval(this.myTimer,1000);

      
    })
  },
  updated: function () {
      this.$nextTick(function () {
        setTimeout(()=>{
          this.modifyHeightFeatures()
          this.modifyHeightTypeRooms()
          this.modifyHeightTestimonial()
        },2000);
      })
  },
  methods:{
      backToOriginalDate(){
        if( this.bookingDate == "" ){
          var today = new Date(Date.now()+12*60*60*1000);
          var date = today.getDate()
          var month = today.getMonth()
          var year = today.getFullYear()
          this.bookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
          this.xbookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
        }
      },
      backToOriginalTime(){
        if( this.bookingTime == "" ){
          var selDate = new Date(this.bookingDate)
          var today = new Date(selDate+12*60*60*1000);
          var bHrs = today.getHours() < 10 ? '0'+today.getHours() : today.getHours();
          var bMins = today.getMinutes() < 10 ? '0'+today.getMinutes() : today.getMinutes();
          this.xbookingTime = bHrs+":"+bMins;
          this.bookingTime = bHrs+":"+bMins;
        }
      },
      modifyHeightTypeRooms(){
        // alert()
        var getItems = document.querySelectorAll('#sec-room .facilities_item');
        // -- RESET HEIGHT
        getItems.forEach(i => {
          i.style.height = "Initial";
        });
        // -- FETCH HIGHEST HEIGHT
        var maxHieght = 0;
        getItems.forEach(i => {
          if( i.offsetHeight > maxHieght ){
            maxHieght = i.offsetHeight;
          }
        });
        // -- APPLY NEW HEIGHT
        getItems.forEach(i => {
          i.style.height = maxHieght+"px";
        });
      },
      
      modifyHeightTestimonial(){
        // alert()
        // var getItems = document.querySelectorAll('.testimonial_item');

        // // -- RESET HEIGHT
        // var maxHieght = 0;
        // getItems.forEach(i => {
        //   i.style.height = "Initial";
        // });
        //   getItems.forEach(i => {
        //     var hh = i.offsetHeight;
        //     if( hh > maxHieght ){
        //       maxHieght = hh;
        //     }
        //   });
        //   getItems.forEach(i => {
        //     i.setAttribute('style', 'height: '+maxHieght+"px !important");
        //   });

      // alert()
        var getItems = document.querySelectorAll('.testimonial_item');
        // -- RESET HEIGHT
        getItems.forEach(i => {
          i.style.height = "Initial";
        });
        // -- FETCH HIGHEST HEIGHT
        var maxHieght = 0;
        getItems.forEach(i => {
          if( i.offsetHeight > maxHieght ){
            maxHieght = i.offsetHeight;
          }
        });
        // -- APPLY NEW HEIGHT
        if( maxHieght != 0 ){
          getItems.forEach(i => {
            i.style.height = maxHieght+"px";
          });
        }
      },
      modifyHeightFeatures(){
        // alert()
        var getItems = document.querySelectorAll('.custom-card .card-content');
        // -- RESET HEIGHT
        getItems.forEach(i => {
          i.style.height = "Initial";
        });
        // -- FETCH HIGHEST HEIGHT
        var maxHieght = 0;
        getItems.forEach(i => {
          if( i.offsetHeight > maxHieght ){
            maxHieght = i.offsetHeight;
          }
        });
        // -- APPLY NEW HEIGHT
        maxHieght = parseFloat(maxHieght)+parseFloat(40)
        getItems.forEach(i => {
          i.style.height = maxHieght+"px";
        });
      },
      windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
         if (screenWidthCheck > process.env.VUE_APP_TABLET_SIZE) {
          this.container= "container"
          this.currentView = 'web'
        } else {
          this.container= "container-fluid"
          this.currentView = 'mobile'
        }


        setTimeout(()=>{
          this.modifyHeightFeatures()
          this.modifyHeightTypeRooms()
          this.modifyHeightTestimonial()
        },1000);
        
      },
      contentvariableReset(){
        // -- FEATURES
          this.HOST = []
          this.BITES = []
          this.STYLES = []
          this.SPEAKS = []
        // -- TYPES ROOM
          this.STANDARD = []
          this.DELUXE = []
          this.SUITE = []
          this.THEMATIC = []
        // -- ABOUTUS
          this.ABOUTUS = []
        // -- TESTIMONIAL
          this.TESTIMONIAL = []
      },
      imageVideoSet(path){
        var ext = path.split('.')
        var img = ""
        var arr = [
							"jpg","jpeg","jpe","jif","jfif","jfi",
							"png",
							"gif",
							"webp",
							"tiff","tif",
							"psd",
							"raw","arw","cr2","nrw","k25",
							"bmp","dib",
							"heif","heic",
							"ind","indd","indt",
							"jp2","j2k","jpf","jpx","jpm","mj2",
							"svg","svgz",
							"ai",
							"eps",
							"pdf"
						];
        if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
          img = `
            <img style="width: 100%" src="`+path+`"/>
          `;
        } else {
          img = `
            <video style="width:100%" id="background-video" autoplay loop muted>
                <source src="`+path+`" type="video/mp4">
              </video>
          `;
        }
        return img;
      },
      displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > process.env.VUE_APP_MOBILE_SIZE ){
          // -- WEB
          // -- FEATURE
          if( this.webcontent['web|home|vcFeatures|types|host'] ){
            this.HOST.image = this.imageVideoSet(this.webcontent['web|home|vcFeatures|types|host']['image'])
            this.HOST.path = this.webcontent['web|home|vcFeatures|types|host']['image']
            this.HOST.description = this.webcontent['web|home|vcFeatures|types|host']['description']
          }
          if( this.webcontent['web|home|vcFeatures|types|bites'] ){
            this.BITES.image = this.imageVideoSet(this.webcontent['web|home|vcFeatures|types|bites']['image'])
            this.BITES.path = this.webcontent['web|home|vcFeatures|types|bites']['image']
            this.BITES.description = this.webcontent['web|home|vcFeatures|types|bites']['description']
          }
          if( this.webcontent['web|home|vcFeatures|types|styles'] ){
            this.STYLES.image = this.imageVideoSet(this.webcontent['web|home|vcFeatures|types|styles']['image'])
            this.STYLES.path = this.webcontent['web|home|vcFeatures|types|styles']['image']
            this.STYLES.description = this.webcontent['web|home|vcFeatures|types|styles']['description']
          }
          if( this.webcontent['web|home|vcFeatures|types|speaks'] ){
            this.SPEAKS.image = this.imageVideoSet(this.webcontent['web|home|vcFeatures|types|speaks']['image'])
            this.SPEAKS.path = this.webcontent['web|home|vcFeatures|types|speaks']['image']
            this.SPEAKS.description = this.webcontent['web|home|vcFeatures|types|speaks']['description']
          }
          // -- TYPE OF ROOMS
          if( this.webcontent['web|home|typeRooms|types|standard'] ){
            this.STANDARD.description = this.webcontent['web|home|typeRooms|types|standard']['description']
          }
          if( this.webcontent['web|home|typeRooms|types|deluxe'] ){
            this.DELUXE.description = this.webcontent['web|home|typeRooms|types|deluxe']['description']
          }
          if( this.webcontent['web|home|typeRooms|types|suite'] ){
            this.SUITE.description = this.webcontent['web|home|typeRooms|types|suite']['description']
          }
          if( this.webcontent['web|home|typeRooms|types|thematic'] ){
            this.THEMATIC.description = this.webcontent['web|home|typeRooms|types|thematic']['description']
          }
          // -- ABOUTUS
          if( this.webcontent['web|home|about|intro'] ){
            this.ABOUTUS.image = this.imageVideoSet(this.webcontent['web|home|about|intro']['image'])
            this.ABOUTUS.path = this.webcontent['web|home|about|intro']['image']
            this.ABOUTUS.description = this.webcontent['web|home|about|intro']['description']
          }
          // -- TESTIMONIAL
          if( this.webcontent['web|home|testimonial'] ){
            this.TESTIMONIAL = this.webcontent['web|home|testimonial']
            // setTimeout(()=>{
            //   this.modifyHeightTestimonial()
            // },1000);
          }
        } else {
          // -- MOBILE
          // -- FEATURE
          if( this.webcontent['mobile|home|vcFeatures|types|host'] ){
            this.HOST.image = this.imageVideoSet(this.webcontent['mobile|home|vcFeatures|types|host']['image'])
            this.HOST.path = this.webcontent['mobile|home|vcFeatures|types|host']['image']
            this.HOST.description = this.webcontent['mobile|home|vcFeatures|types|host']['description']
          }
          if( this.webcontent['mobile|home|vcFeatures|types|bites'] ){
            this.BITES.image = this.imageVideoSet(this.webcontent['mobile|home|vcFeatures|types|bites']['image'])
            this.BITES.path = this.webcontent['mobile|home|vcFeatures|types|bites']['image']
            this.BITES.description = this.webcontent['mobile|home|vcFeatures|types|bites']['description']
          }
          if( this.webcontent['mobile|home|vcFeatures|types|styles'] ){
            this.STYLES.image = this.imageVideoSet(this.webcontent['mobile|home|vcFeatures|types|styles']['image'])
            this.STYLES.path = this.webcontent['mobile|home|vcFeatures|types|styles']['image']
            this.STYLES.description = this.webcontent['mobile|home|vcFeatures|types|styles']['description']
          }
          if( this.webcontent['mobile|home|vcFeatures|types|speaks'] ){
            this.SPEAKS.image = this.imageVideoSet(this.webcontent['mobile|home|vcFeatures|types|speaks']['image'])
            this.SPEAKS.path = this.webcontent['mobile|home|vcFeatures|types|speaks']['image']
            this.SPEAKS.description = this.webcontent['mobile|home|vcFeatures|types|speaks']['description']
          }
          // -- TYPE OF ROOMS
          if( this.webcontent['mobile|home|typeRooms|types|standard'] ){
            this.STANDARD.description = this.webcontent['mobile|home|typeRooms|types|standard']['description']
          }
          if( this.webcontent['mobile|home|typeRooms|types|deluxe'] ){
            this.DELUXE.description = this.webcontent['mobile|home|typeRooms|types|deluxe']['description']
          }
          if( this.webcontent['mobile|home|typeRooms|types|suite'] ){
            this.SUITE.description = this.webcontent['mobile|home|typeRooms|types|suite']['description']
          }
          if( this.webcontent['mobile|home|typeRooms|types|thematic'] ){
            this.THEMATIC.description = this.webcontent['mobile|home|typeRooms|types|thematic']['description']
          }
          // -- ABOUTUS
          if( this.webcontent['mobile|home|about|intro'] ){
            this.ABOUTUS.image = this.imageVideoSet(this.webcontent['mobile|home|about|intro']['image'])
            this.ABOUTUS.path = this.webcontent['mobile|home|about|intro']['image']
            this.ABOUTUS.description = this.webcontent['mobile|home|about|intro']['description']
          }
          // -- TESTIMONIAL
          if( this.webcontent['mobile|home|testimonial'] ){
            this.TESTIMONIAL = this.webcontent['mobile|home|testimonial']
          }
        }
      },
      getVCContent($url){
        this.xgetVCContent($url).then(response => {
          // console.log(response)
          this.webcontent = response.data
          // console.log(this.webcontent['web|home|vcFeatures|types|host'])
          this.displayContent()
        })
      },
      xgetVCContent($url){
        return services.fetchVCcontent($url)
      },
      strPad(str){
        return (str).toString().padStart(2,'0')
      },
      getbranch(){
        var xdata = {
          company: this.company,
        }
        return services.getbranch(xdata)
      },
      getRates(){
        this.getRoomRate().then(response => {
          console.log(response)
          this.roomRateList = response
          this.roomrate = response.data[0]['roomrate']['id']
          this.xfilter_roomrate = this.roomrate
        })
      },
      getRoomRate(){
        var xdata = {
          company: this.company,
          branch: this.branch
        }
        return services.getRoomRate(xdata)
      },
      changeBranch(){
        // this.filter()
        this.getRates()
      },
      checkAvailability(){
        // this.branch
        // this.roomrate
        // this.bookingDate

        if( this.checkFilterDateTime() == 1 ){
          var arr = {
            branch: this.branch,
            roomrate: this.roomrate,
            bookingDate: this.bookingDate,
            bookingTime: this.bookingTime
          }

          localStorage.setItem('chkAvailability',JSON.stringify(arr));
          window.open('/booking');
        } else {
          this.$swal(
              '&nbsp;',
              'Reservation Time Should Be Greater Than '+this.datetimeformatter(this.xbookingDate+" "+this.xbookingTime+":00")
            );
        }

      },
      checkFilterDateTime(){
        var stat = 1;
        if( this.bookingDate == this.xbookingDate ){
          if( this.xbookingTime > this.bookingTime ){
            stat = 0;
          }
        }
        return stat;
      },

      datetimeformatter(myDate){
        const months = {
          0: 'Jan',
          1: 'Feb',
          2: 'Mar',
          3: 'Apr',
          4: 'May',
          5: 'Jun',
          6: 'Jul',
          7: 'Aug',
          8: 'Sep',
          9: 'Oct',
          10: 'Nov',
          11: 'Dec'
        }
        var xxDate = myDate.split(' ')[0]
        var xxTime = myDate.split(' ')[1]
        var xDate = new Date(
          xxDate.split('-')[0],
          (xxDate.split('-')[1] - 1),
          xxDate.split('-')[2],
          xxTime.split(':')[0],
          xxTime.split(':')[1],
          xxTime.split(':')[2]
        )

        var hours = xDate.getHours();
        var minutes = xDate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        var retDate = months[xDate.getMonth()] + " " + xDate.getDate() + ", " + xDate.getFullYear();
        var retTime = hours + ':' + minutes + ' ' + ampm;
        return retDate + " " + retTime
        // return xDate.getFullYear()+"-"+this.strPad(((xDate.getMonth())+1))+"-"+this.strPad((xDate.getDate()));
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .bg-abstract{
    background: url("../../../public/template/home/image/bg-skin.png");
    background-repeat: repeat;
    /* background-size: 10px; */
  }
  /* .gray-bg{ */
    /* background: #e2e2e2; */
  /* } */
  .vc_option{
    /* width: 100%; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
  }
  .image-container{
    background: rgba(0, 0, 0, 0.2);
  }
  .card-title{
    color: black !important;
  }
  .card-body{
    text-indent: 25px;
    text-align: justify;
  }
  .card-content{
    padding: 10px;
    background: white;
    /* height: 400px; */
    position: relative;
  }
  .card-content h3{
    text-align: center !important;
  }
  .custom-card{
    margin-bottom: 20px;
  }
  /* .custom-card{
  }
  .custom-card:hover{
    transition: box-shadow 250ms ease, transform 250ms ease;
  }
  @keyframes embouse-border {
    from {
      margin: auto;
      box-shadow: 0px;
    }
    to {
      margin: -10px;
      box-shadow: 1px 1px 20px black;
    }
  } */
  .home-content .btn-visit{
    font-size: 18px;
    padding: 5px 50px;
    border: 1px solid #FFD700;
    background: transparent;
    /* color: black; */
  }
  .facilities_item p{
    color: white !important;
  }
  /* .facilities_item p p{
    color: white !important;
  } */

  @media screen and (max-width: 768px) {
    .booking_table{
      height: 200px !important;
      min-height: auto !important;
    }
    .home-content .booking-form{
      padding: 40px 50px !important;
    }
    /* .home-content .booking-form .btn-home-book-now{
      width: 400px !important;
    } */
    .btn-features{
      width: 80%;
      left: 10% !important;
      /* font-size: 11px !important; */
      /* padding: auto 30px !important; */
    }
  }
  @media screen and (max-width: 480px) {
    .booking_table{
      height: 200px !important;
      min-height: auto !important;
    }
    .home-content .booking-form{
      padding: 40px 50px !important;
    }
    .btn-features{
      width: 80%;
      left: 10% !important;
      /* padding: auto 30px !important; */
    }
  }
  /* @media only screen and (min-width: 700px) {
    .booking_table{
      height: 200px;
      min-height: auto !important;
    }
  } */

  .home-content .booking-form{
    margin: 0 !important;
    padding: 40px 150px;
    background: black;
    color: white;
  }
  /* .home-content .booking-form button{
    color: white !important;
  } */
  .home-content .booking-form label{
    font-size: 23px;
    padding-top: 40px;
    color: white !important;
  }

  .input-booking option{
    background: black !important;
  }
  .input-booking{
    color: white !important;
    background: transparent !important;
    border: transparent !important;
    border-bottom: 1px solid white !important;
    border-radius: 0 !important;
  }
  .input-booking:focus{
    box-shadow: none !important;
    outline: 0 !important;
  }

  .input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }
  .home-content{
    position: relative;
    /* top: 100vh; */
    background: white;
  }
  .btn-features{
    /* padding: 5px 50px; */
    border: 1px solid #FFD700;
    background: transparent;

    color: black;
    position: absolute !important;
    bottom: 10px;
    width: 80%;
    left: 10% !important;
    font-size: 14px;
    /* padding: auto 30px !important; */
  }
  .facilities_item{
    /* height: 300px; */
    color: white !important;
  }
</style>
