<template>
  <div>
    SANDWICH
  </div>
</template>

<script>
export default {
  name: 'sandwich_n',
  // props: {
  //   msg: String
  // },,
  // components: {
  //   "vue-sticky-sidebar": vueStickySidebar
  // },
  data(){
    return {

    }
  },
  created(){
    this.$store.state.header = "bites";
  },
  mounted(){
    
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
