<template>
  <div>
      <!-- Breadcrumb Section Begin -->
      <section v-if="COVER.path != undefined" class="breadcrumb_area">
          <div class="overlay bg-parallax" v-bind:style="{ 'background-image': 'url(' + COVER.path + ') !important' }" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">About Us</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
      <section v-else class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">About Us</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
    <!-- Breadcrumb Section End -->
    <div v-if="HISTORY.description != undefined" v-html="HISTORY.description"></div>
    <div v-else>
        <section class="about_history_area section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">HISTORY</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">In 1970, Mr. Angelo King, fondly called AK, known as the patriarch of the King’s group, noticed how well motels were doing. Making mathematical computations in his head, AK’s imagination caught fire at the prospect of venturing into the motel business. He was bent on entering the business and decided to work on something most motels failed to give to their clients – GOOD QUALITY SERVICE.
    It was in August when AK opened up his first venture in Pasay City. He named it Anito Lodge, after a Tagalog god or deity. Nothing really set it above the competition except for one thing: its prompt and accommodating service. Word-of-mouth became its sole means of marketing and the business started to grow its own clientele.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">THE COMPANY GROWTH AND EVOLUTION</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">The first venture led to another. AK opened his second motel in 1973. It was located in Pasig where a potential market of motels was evident.
    In the forefront, Anito Lodge experienced increasing demand. In answer to this, the Company opened up Halina Lodge in 1975. Then AK chanced upon the Caloocan area and saw an opportunity to be a pioneer in the area. Thus, 1976 saw the inauguration of Anito Lodge Caloocan. This was the biggest motel at that time, consisting of 102 rooms.</p>
                        <br>
                        <p style="text-indent: 20px;text-align: justify;">The year 1980 not only meant the beginning of a new decade for the Company but also marked the entry into the fold of AK’s sons, Archie and Wyden, who are said to be the prime movers of the Company's second generation of drive-in hotel expansion and growth. The advent started off with the opening of Akasya Lodge in Sta. Mesa, Manila. The following year, the Company opened Kamagong Lodge in Pasay City. Because of the growing demand, the Company started to evolve and set-up district offices in 1984. Divided into North and South districts, they allowed focused attention to the problems and pressing concerns of each motel in a professionally managed manner.
    From that time on, thrust shifted to providing lodging facilities of hotel quality and service at premium prices. Then came the introduction of amenities such as colored TV, bathtubs, jacuzzis, and air-conditioners. This paved the way for the coining of the term ―drive-in hotel for the succeeding Company establishments.
    The almost simultaneous opening of Anahaw and Anito Royale in 1985 brought with it the introduction of mega rooms which were known as VIP rooms. They became popular and increased in number when talks of AK's imminent semi-retirement became rife.
    AK, the battle-weary founder was now ready to pass on the business to his two sons and decided to divide the Company into Management Teams. In October 1985, the wheels of transition were put into motion with the creation of the three Group Management Teams (GMT). GMT I, which was under AK, and he controlled Anahaw and Kamagong Lodges. Anito Caloocan and Victoria Courts were handled by AK’s eldest son, Archie under GMT II. The younger Wyden managed Anito Pasay, Anito Royale, and Akasya under GMT III.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">THE VICTORIA COURT ON ITS OWN</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">Under GMT II, Mr. Archie King named his first hotel Panorama Enterprises, a 38-room property in Pasig which he branded ―Victoria Court, the first time the brand name ―Victoria Court was used. This property was the most innovative and classiest hotel of its time because of its first class amenities, bigger rooms, and thematic atmosphere.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">THE BIRTH OF OTHER LOCALES</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">August 1986 opened its door to Archie King‗s dream of adding a hotel to his chain of drive-in hotel and restaurants. He ventured into the hotel industry, with the high influx then of tourists in the country. The opportunity came for the realization of his dream when a property in Ermita was offered for sale. He purchased the old 5-storey apartelle which was then called the Fe and Caridad Apartments.
    Today, the 5-storey hotel has been renovated into a 6-storey establishment called Best Western Hotel La Corona. It was formally inaugurated in January of 1989. An international tie-up with Best Western International, a large consortium of hotel chains, was established in April 1989. As an affiliate of this renowned group, Hotel la Corona automatically enjoyed certain privileges such as marketing, advertising, and an international reservation system. Because of the success experienced by Hotel La Corona in terms of occupancy and profitability, Archie King decided that this name be expanded. On November 2, 1994, Hotel la Corona de Lipa (HLC-L) was born in Lipa City, Batangas. The second venture of Mr. King outside Metro Manila (after VC-San Fernando), HLC-L showed signs of good business in its first year of operations. Today HLC-L is considered one of the best hotels in Batangas. A few months after the birth of HLC-L, Hotel La Corona de Pagsanjan (HLC-P) was acquired in July 1995. The very first resort-hotel, HLC-P promised to be the most sought after resort-hotel in the Laguna province, with its first class facilities and the biggest Ropes Challenge Discovery courses in the country.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">VICTORIA COURT ONWARDS</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">Just after the purchase of the Fe and Caridad Apartments, another drive-in hotel called FIESTA Lodge was purchased; it is now called Victoria Court Pasay. Although it was operational upon purchase, renovation was done slowly but surely, restructuring and reshaping the establishment into an all-suite drive-in hotel better than Victoria Court Pasig, with its unique motifs, suite rooms, and mezzanine Jacuzzis.
    After a few years of operation, Victoria Court Pasig performed beyond expectation. In fact, it pleased the Management so much it took to liking the name Victoria Court. Management eventually decided to rename GMT II as the Victoria Court Group. With that move, a logo was drummed up, depicting a classy but mysterious-looking lady in a hush-up motion, with a background of black and silver. It is supposed to embody a secret, and it means that apart from good and fast service, Victoria Court also offers PRIVACY, COURTESY, PROMPT SERVICE, WORLD- CLASS FACILITIES, AND STATE-OF-THE-ART HOUSEKEEPING.
    Afterwards, the Anito moniker was dropped from the Caloocan drive-in hotel and changed to Victoria Court Caloocan. 1987 saw AK's full retirement from the drive-in hotel business. He turned over the control of Kamagong and Anahaw Lodges to the Victoria Court Management. 1988 saw again the acquisition of two new properties – Victoria Court Canley I and II in Pasig and Victoria Court Malabon. To keep the Management focused, two empowered and decentralized district operations were set-up. North District included VC Malabon (now called Victoria Court Mc Arthur), VC Caloocan (now VC North Edsa), VCPA (composed of Canley, Suites and Escarpment), and VCHI (formerly called the Victoria Court). South District was composed of VC Cuneta (now called VC Pasay), VCAD (formerly called Anahaw), Halina Lodge, and Kamagong Lodge.
    After four years of managing Halina and Kamagong, Victoria Court turned over the management of these properties to Anito Group of Companies. VCPA and VCHI then became part of the South District.
    At present, Victoria Court has 13 properties to its name, including the three-star Hotel La Corona hotels, broken down into Strategic Business Units (SBUs). The Hotel Division is composed of Hotel La Corona de Manila, Lipa, and Pagsanjan. The Drive-in Division is composed of Hotel Victoria de San Fernando, Hotel Victoria de Malate, Victoria Court Cuneta, Victoria Court North Edsa, Victoria Court Hillcrest, Victoria Court Panorama, Victoria Court Las Piñas, Victoria Court Gil Puyat, Victoria Court McArthur, and Victoria Court Balintawak.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_gap remBottomPadding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center">THE BIRTH OF AMH/AKG & DSI</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p style="text-indent: 20px;text-align: justify;">True to its commitment of pursuing greater challenges that lies ahead, a major organizational change was carried out beginning January 2005. All the Victoria Court and Hotel La Corona properties were officially divided into the management of the two Managing Directors, namely Atticus Robert R. King (ARRK) and Ian Angelo R. King (IARK).
    This major decision of Mr. Archimedes R. King (ARK) on the division of properties gave birth to the creation and establishment of two new business names under the umbrella of Victoria Court as the mother organization.
    The first group was the Augustus Maximus Holdings (AMH) under the leadership of ARRK as the Managing Director and is composed of the following: Victoria Court Panorama (VCPA), Victoria Court Hillcrest (VCHI), Victoria Court Las Pinas (VCLP), Victoria Court North Edsa (VCNE), Victoria Court Balintawak (VCBA), Victoria Court MacArthur (VCMA), and Hotel Victoria de San Fernando (HVSF).
    The second group was named Ditus Sapientia Inc. (DSI) under the leadership of IARK as the Managing Director and is composed of the following properties: Victoria Court Cuneta (VCCU), Victoria Court Gil Puyat (VCGP), Hotel Victoria De Malate (HVMA), Hotel La Corona Manila (HLCM), Hotel La Corona Lipa (HLCL) and Hotel La Corona De Pagsanjan (HLCP).
    Thus, AMH & DSI now both embarks on a more challenging path towards continuous improvement as envisioned by their respective Managing Directors. Recently, DSI rebranded their Victoria Court properties and now, AMH, solely carries the Victoria Court brand and will continue to give unparalleled service to its patrons.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>



    <section class="section_gap bg-abstract">
        <div class="container">
            <div v-if="MISSION.description != undefined" class="row">
                <div class="col-md-6 d_flex align-items-center">
                    <div class="about_content ">
                        <h2 class="title title_color">Mission</h2>
                        <p v-html="MISSION.description"></p>
                        <!-- <a href="/aboutus" class="button_hover theme_btn_two">Read More</a> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <div v-html="MISSION.image"></div>
                    <!-- <img class="img-fluid" :src="MISSION.image" alt="img"> -->
                </div>
            </div>
            <div v-else class="row">
                <div class="col-md-6 d_flex align-items-center">
                    <div class="about_content ">
                        <h2 class="title title_color">Mission</h2>
                        <p>We are committed to the growing business of creating a distinct, private, romantic and playful stage for couples, families and friends to portray their fantasies and express their love through our magical themed rooms, products and services with our happy and innovative talents.</p>
                        <!-- <a href="/aboutus" class="button_hover theme_btn_two">Read More</a> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <img class="img-fluid" src="../../assets/img/about/mission.jpg" alt="img">
                </div>
            </div>
        </div>
    </section>
    <section class="section_gap">
        <div class="container">
            <div v-if="VISION.description != undefined" class="row">
                <div class="col-md-6 d_flex align-items-center xorder-2">
                    <div class="about_content ">
                        <h2 class="title title_color">Vision</h2>
                        <p v-html="VISION.description"></p>
                        <!-- <a href="/aboutus" class="button_hover theme_btn_two">Read More</a> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <div v-html="VISION.image"></div>
                    <!-- <img class="img-fluid" :src="VISION.image" alt="img"> -->
                </div>
            </div>
            <div v-else class="row">
                <div class="col-md-6 d_flex align-items-center xorder-2">
                    <div class="about_content ">
                        <h2 class="title title_color">Vision</h2>
                        <p>ADULT DISNEY Playful Romantic Experience</p>
                        <!-- <a href="/aboutus" class="button_hover theme_btn_two">Read More</a> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <img class="img-fluid" src="../../assets/img/about/vision.jpg" alt="img">
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="section_gap">
         <div class="container">
             <div class="row">
                 <div class="col-md-12">
                    <h2 class="title title_color text-center">Vision</h2>
                    <div class="img-container">
                        <img class="img-fluid" src="../../assets/img/about/vision.jpg" alt="">
                        <div class="tags">
                            <h3>ADULT DISNEY</h3>
                            <p>PLAYFUL ROMANTIC EXPERIENCE</p>
                        </div>
                    </div>
                 </div>
             </div>
         </div>
    </section> -->
    <section class="section_gap">
         <div class="container">
             <div class="row">
                 <div class="col-md-12">
                    <div id="parallax-world-of-ugg">
  
                        <section>
                            <div class="title">
                                <h1>Core Values</h1>
                            </div>
                        </section>

                        <section>
                            <div class="paralax-creative">
                                <h2>MALIKHAIN</h2>
                                <p class="subDesc">INNOVATE OR DIE</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">N</span>aghahanap ng bagong pamamaraan o diskarte upang mapadali ang gawain ng hindi masasakripisyo ang kalidad. Nagbibigay ng mungkahi at nakikita ang “oppurtunity” upang mas mapaganda. Mapasimple at makagawa ng bagong produkto o ma “improve” ang bawat produkto o serbisyo. Naghahanap ng uso o makabagong teknolohiya. </p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>

                        <section>
                            <div class="parallax-malasakit">
                                <h2>MALASAKIT</h2>
                                <p class="subDesc">ACT LIKE THE OWNER</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">U</span>na sa isip ang kapakanan ng kompanya. Gagawin ang tama sa trabaho. Gumastos nang naayon sa patakaran at ikakabuti ng kompanya. Hindin nagtuturo o ipinapasa ang trabaho. Hindi nagsasabi ng “hindi ko trabaho yan”. May kusang loob. Hindi kailangan utusan. </p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>

                        <section>
                            <div class="parallax-matapat">
                                <h2>MATAPAT</h2>
                                <p class="subDesc">I AM TRUSTWORTHY</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">M</span>apagkakatiwalaan. Hindi sinungaling at kailanman ay hindi magsisinungaling upang mapagtakpan ang isang pagkakamali o maling ginagawa mapa sarili man o kamang gagawa. Hindi hinahayaan kailanman ang mandaya sa kasamahan higit sa lahat sa kompanya. Ang pandaraya kahit sa isang maliit na bagay lamang ay hindio palulusutin ng kompanya. Sabihan ang management kung sino ang hindi gumagawa ng mabuti.</p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>

                        <section>
                            <div class="parallax-courage">
                                <h2>COURAGE</h2>
                                <p class="subDesc">SPEAK YOUR MIND</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">N</span>akikipag usap ng deretso at walang alinlangan. Hindi paligoy ligoy. Hindi natatakot magsabi ng totoo hanggang sa may ari ng kompanya. Bawal “snowflake” o manipis ang balat; trabaho lang. Walang personalan. Kayang magbigay at tumanggap ng opinyon o “criticism” na hindi masama ang kalooban.</p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>

                        <section>
                            <div class="parallax-impact">
                                <h2>IMPACT</h2>
                                <p class="subDesc">DOER. NOT AIDS.<br/>(AS IF DOING SOMETHING)</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">T</span>inatapos ang gawain ng mas maaga. Kayang mag deliver ng 20x higit sa average performer. Challenge the “status quo” hindi pwede ang ‘pwede na’ . No to “crab mentality”. Hindi nanghihila pababa. Imbis ay tumutulong itaas ang lahat. Nagbibigay “value” sa product o sa serbisyo.</p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>

                        <section>
                            <div class="parallax-team">
                                <h2>TEAM</h2>
                                <p class="subDesc">ONE DIRECTION. ONE GOAL</p>
                            </div>
                        </section>

                        <section>
                            <div class="block">
                                <p><span class="first-character">I</span>sang team na nagkakaisa at nagtatrabaho ng naayon at bukal sa kalooban. Hindi pabigat. Hindi nanlalamang sa kapwa at kompanya. Hindi pasaway. Dapat sa tama lang.</p>
                                <p class="line-break margin-top-10"></p>
                            </div>
                        </section>
                        
                    </div>
                 </div>
             </div>
         </div>
    </section>
  </div>
</template>

<script>
    // import services from '../../service/account_webservice'
    import services from '../../service/webservice'
    export default {
        name: 'aboutus_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                company: process.env.VUE_APP_COMPANY_CODE,
                // -- WEB CONTENT
                COVER: [],
                webcontent: [], 
                HISTORY: [],
                MISSION: [],
                VISION: [],
            }
        },
        created(){
            this.$store.state.header = "aboutus";
            this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
            this.windowResizing()
            window.addEventListener("resize", this.windowResizing);
        },
        // mounted(){
            
        // }
        methods:{
            windowResizing(){
                // alert()
                var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                // alert(screenWidthCheck)
                if (screenWidthCheck > process.env.VUE_APP_MOBILE_SIZE) {
                this.currentView = 'web'
                } else {
                this.currentView = 'mobile'
                }
                this.displayContent()
            },
            contentvariableReset(){
                // -- FEATURES
                this.COVER = []
                this.HISTORY = []
                this.MISSION = []
                this.VISION = []
            },
            imageVideoSet(path){
                var ext = path.split('.')
                var img = ""
                var arr = [
                                    "jpg","jpeg","jpe","jif","jfif","jfi",
                                    "png",
                                    "gif",
                                    "webp",
                                    "tiff","tif",
                                    "psd",
                                    "raw","arw","cr2","nrw","k25",
                                    "bmp","dib",
                                    "heif","heic",
                                    "ind","indd","indt",
                                    "jp2","j2k","jpf","jpx","jpm","mj2",
                                    "svg","svgz",
                                    "ai",
                                    "eps",
                                    "pdf"
                                ];
                if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
                img = `
                    <img style="width: 100%" src="`+path+`"/>
                `;
                } else {
                img = `
                    <video style="width:100%" id="background-video" autoplay loop muted>
                        <source src="`+path+`" type="video/mp4">
                    </video>
                `;
                }
                return img;
            },
            displayContent(){
                this.contentvariableReset()
                // RESET VARIABLE
                let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                // alert(width)
                if( width > process.env.VUE_APP_MOBILE_SIZE ){
                    // -- WEB
                    // -- COVER
                    if( this.webcontent['web|home|about|cover'] ){
                        this.COVER.path = this.webcontent['web|home|about|cover']['image']
                        this.COVER.image = this.imageVideoSet(this.webcontent['web|home|about|cover']['image'])
                    }
                    // -- HISTORY
                    if( this.webcontent['web|home|about|history'] ){
                        this.HISTORY.description = this.webcontent['web|home|about|history']['description']
                    }
                    // -- MISSION
                    if( this.webcontent['web|home|about|rules|mission'] ){
                        this.MISSION.description = this.webcontent['web|home|about|rules|mission']['description']
                        this.MISSION.image = this.imageVideoSet(this.webcontent['web|home|about|rules|mission']['image'])
                        this.MISSION.path = this.webcontent['web|home|about|rules|mission']['image']
                    }
                    // -- VISION
                    if( this.webcontent['web|home|about|rules|vision'] ){
                        this.VISION.description = this.webcontent['web|home|about|rules|vision']['description']
                        this.VISION.image = this.imageVideoSet(this.webcontent['web|home|about|rules|vision']['image'])
                        this.VISION.path = this.webcontent['web|home|about|rules|vision']['image']
                    }
                } else {
                    // -- MOBILE
                    // -- COVER
                    if( this.webcontent['mobile|home|about|cover'] ){
                        this.COVER.path = this.webcontent['mobile|home|about|cover']['image']
                        this.COVER.image = this.imageVideoSet(this.webcontent['mobile|home|about|cover']['image'])
                    }
                    // -- HISTORY
                    if( this.webcontent['mobile|home|about|history'] ){
                        this.HISTORY.description = this.webcontent['mobile|home|about|history']['description']
                    }
                    // -- MISSION
                    if( this.webcontent['mobile|home|about|rules|mission'] ){
                        this.MISSION.description = this.webcontent['mobile|home|about|rules|mission']['description']
                        this.MISSION.image = this.imageVideoSet(this.webcontent['mobile|home|about|rules|mission']['image'])
                        this.MISSION.path = this.webcontent['mobile|home|about|rules|mission']['image']
                    }
                    // -- VISION
                    if( this.webcontent['mobile|home|about|rules|vision'] ){
                        this.VISION.description = this.webcontent['mobile|home|about|rules|vision']['description']
                        this.VISION.image = this.imageVideoSet(this.webcontent['mobile|home|about|rules|vision']['image'])
                        this.VISION.path = this.webcontent['mobile|home|about|rules|vision']['image']
                    }
                }
                // alert(this.COVER)
            },
            getVCContent($url){
                this.xgetVCContent($url).then(response => {
                // console.log(response)
                this.webcontent = response.data
                // console.log(this.webcontent['web|home|vcFeatures|types|host'])
                this.displayContent()
                })
            },
            xgetVCContent($url){
                return services.fetchVCcontent($url)
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .remBottomPadding{
        padding-bottom: 0px;
    }
    .img-container{
        position: relative;
    }
    .img-container .tags{
        position: absolute;
        top: 30%;
        left: 10%;
    }
    .tags h3{
        font-size: 50px;
        color: white;
    }
    .tags p{
        color: white;
    }

    /* Override UGG site */
    #main {width: 100%; padding:0;}
    .content-asset p {margin:0 auto;}
    .breadcrumb {display:none;}

    /* Helpers */
    /**************************/
    .margin-top-10 {padding-top:10px;}
    .margin-bot-10 {padding-bottom:10px;}

    /* Typography */
    /**************************/
    #parallax-world-of-ugg h1 { font-size:24px; font-weight:400; text-transform: uppercase; color:black; padding:0; margin:0;}
    #parallax-world-of-ugg h2 { font-size:70px; letter-spacing:10px; text-align:center; color:white; font-weight:400; text-transform:uppercase; z-index:10; opacity:.9; text-shadow: 1px 1px 2px #000;}
    .subDesc{
        color: white;
        text-align: center;
        font-size: 15px !important;
        text-shadow: 1px 1px 2px #000;
    }
    #parallax-world-of-ugg h3 { font-size:14px; line-height:0; font-weight:400; letter-spacing:8px; text-transform: uppercase; color:black;}
    #parallax-world-of-ugg p { font-weight:400; font-size:14px; line-height:24px;}
    .first-character {font-weight:400; float: left; font-size: 50px; line-height: 43px; padding-top: 2px; padding-right: 8px; padding-left: 3px; font-family: 'Source Sans Pro', sans-serif;}

    /* .sc {color: #3b8595;}
    .ny {color: #3d3c3a;}
    .atw {color: #c48660;} */

    /* Section - Title */
    /**************************/
    #parallax-world-of-ugg .title {background: white; padding: 60px; margin:0 auto; text-align:center;}
    #parallax-world-of-ugg .title h1 {font-size:35px; letter-spacing:8px;}

    /* Section - Block */
    /**************************/
    #parallax-world-of-ugg .block {background: white; padding: 60px; width:820px; margin:0 auto; text-align:justify;}
    #parallax-world-of-ugg .block-gray {background: #f2f2f2;padding: 60px;}
    #parallax-world-of-ugg .section-overlay-mask {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: black; opacity: 0.70;}

    /* Section - Parallax */
    /**************************/
    #parallax-world-of-ugg .paralax-creative {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/creative.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: top center;}

    #parallax-world-of-ugg .parallax-malasakit {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/malasakit.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

    #parallax-world-of-ugg .parallax-matapat {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/matapat.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

    #parallax-world-of-ugg .parallax-courage {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/courage.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

    #parallax-world-of-ugg .parallax-impact {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/impact.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

    #parallax-world-of-ugg .parallax-team {padding-top: 300px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../assets/img/about/teamwork.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

    /* Extras */
    /**************************/
    #parallax-world-of-ugg .line-break {border-bottom:1px solid black; width: 150px; margin:0 auto;}

    /* Media Queries */
    /**************************/
    @media screen and (max-width: 959px) and (min-width: 768px) {
        #parallax-world-of-ugg .block {padding: 40px; width:620px;}
    }
    @media screen and (max-width: 767px) {
        #parallax-world-of-ugg .block {padding: 30px; width:420px;}
        #parallax-world-of-ugg h2 {font-size:30px;}
        #parallax-world-of-ugg .block {padding: 30px;}

        #parallax-world-of-ugg .paralax-creative,
        #parallax-world-of-ugg .parallax-malasakit,
        #parallax-world-of-ugg .parallax-matapat,
        #parallax-world-of-ugg .parallax-courage,
        #parallax-world-of-ugg .parallax-impact,
        #parallax-world-of-ugg .parallax-team
        
        {padding-top:100px; padding-bottom:100px;}

    }
    @media screen and (max-width: 479px) {
        #parallax-world-of-ugg .block {padding: 30px 15px; width:290px;}
    }

    @media screen and (min-width: 800px) {
        .xorder-2{
        order: 2
        }
    }
    .breadcrumb_area .bg-parallax {
        background-image: url('../../assets/img/about/cover.jpg')!important;
        background-repeat: no-repeat !important;
        background-attachment: scroll !important;
        background-position: center !important;
        background-size: cover !important;
    }
</style>
