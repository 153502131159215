<template>
  <div>

    <!-- Breadcrumb Section Begin -->
      <!-- <section class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Speaks</h2>
              </div>
          </div>
      </section> -->
    <!-- Breadcrumb Section End -->

    <!-- Shop Section Begin -->
    <section class="shop spad" id="speaksList" style="padding-top: 150px !important">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- <div class="shop__product__option">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="shop__product__option__left">
                                    <p>Showing 1–12 of 126 results</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12" v-for="news in speaks" :key="news.id">
                            <div class="speaks_container">
                                <div class="speaks_image">
                                    <img :src="`${news.img}`" alt="">
                                </div>
                                <div class="speaks_title">
                                    <h2>{{news.title}}</h2>
                                </div>
                                <div class="speaks_from">
                                    <!-- <span class="speaks_from_local" style="visibility: hidden">Victoria Court</span>  <span class="speaks_from_date">{{news.created_at}}</span> -->
                                </div>
                                <div class="speaks_intro">
                                    <!-- <span class="speaks_intro_desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span> -->
                                </div>
                                <br>
                                <button class="button_hover theme_btn_two btn-visit btn-home-book-now form-control" @click="viewArticle(news)">View Article</button>
                            </div>
                            <!-- <div class="row">
                                <div class="col-sm-12">
                                    <div class="speaks_container">
                                        <div class="speaks_image">
                                            <img src="../../assets/img/speaks/swing.jpeg" alt="">
                                        </div>
                                        <div class="speaks_title">
                                            <h2>Swing Swing Lang</h2>
                                        </div>
                                        <div class="speaks_from">
                                            <span class="speaks_from_local">Victoria Court</span> | <span class="speaks_from_date">March 22, 2022</span>
                                        </div>
                                        <div class="speaks_intro">
                                        <span class="speaks_intro_desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                        </div>
                                    </div>
                                </div> -->
                            <!-- </div> -->
                        </div>
                        <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="speaks_container">
                                        <div class="speaks_image">
                                            <img src="../../assets/img/speaks/tali.jpeg" alt="">
                                        </div>
                                        <div class="speaks_title">
                                            <h2>50 Shades</h2>
                                        </div>
                                        <div class="speaks_from">
                                            <span class="speaks_from_local">Victoria Court</span> | <span class="speaks_from_date">March 22, 2022</span>
                                        </div>
                                        <div class="speaks_intro">
                                        <span class="speaks_intro_desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="speaks_container">
                                        <div class="speaks_image">
                                            <img src="../../assets/img/speaks/tali.jpeg" alt="">
                                        </div>
                                        <div class="speaks_title">
                                            <h2>50 Shades</h2>
                                        </div>
                                        <div class="speaks_from">
                                            <span class="speaks_from_local">Victoria Court</span> | <span class="speaks_from_date">March 22, 2022</span>
                                        </div>
                                        <div class="speaks_intro">
                                        <span class="speaks_intro_desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- <div class="showmore">
                                <center>
                                    <a href="#" class="button_hover theme_btn_two btn-visit btn-home-book-now">Show More</a>
                                </center>
                            </div> -->
                            <!-- <div class="product__pagination">
                                <a class="active" href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <span>...</span>
                                <a href="#">21</a>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div style="margin: 0 auto">
                            <paginate
                                :page-count="pagecnt"
                                :page-range="3"
                                :margin-pages="2"
                                :click-handler="clickCallback"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'pagination'"
                                :page-class="'page-item'"
                            >
                            </paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Shop Section End -->

  </div>
</template>

<script>
// import vueStickySidebar from "vue-sticky-sidebar";
import services from '../../service/webservice'
import Paginate from 'vuejs-paginate-next';
export default {
  name: 'speak_n',
  // props: {
  //   msg: String
  // },,
  components: {
  //   "vue-sticky-sidebar": vueStickySidebar
      paginate: Paginate,
  },
  data(){
    return {
        company: process.env.VUE_APP_COMPANY_CODE,
        imgPath: process.env.VUE_APP_API_URL,
        pagecnt: 0,
        pagenumbers: 12,
        speaks: []
    }
  },
  created(){
    this.$store.state.header = "speaks";
  },
  mounted(){
    this.getSpeaks('')
  },
  methods:{
      clickCallback (pageNum){
        // alert(pageNum)
        // this.currentpage = pageNum
        this.getSpeaks(pageNum)
        const myEl = document.getElementById('speaksList')

        this.$smoothScroll({
            scrollTo: myEl, // scrollTo is also allowed to be number
        // hash: '#sampleHash' // required if updateHistory is true
        })
      },
      viewArticle(article){
          localStorage.setItem('article',JSON.stringify(article))
          window.location.href="/read"
      },
      getSpeaks(pageNum){
        if( pageNum == '' ){
            this.fetchSpeaks().then(response => {
                // this.foodloading = 1
                // console.log(response.data)
                this.speaks = response.data.data
                this.pagecnt = response.data.last_page
            })
        } else {
            this.fetchSpeaksPagination(pageNum).then(response => {
                // this.foodloading = 1
                this.speaks = response.data.data
                this.pagecnt = response.data.last_page
            })
        }
      },
      fetchSpeaks(){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: "",
          pagination: this.pagenumbers
        }
        return services.fetchSpeaks(xdata)
      },
      fetchSpeaksPagination(num){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: "",
          pagination: this.pagenumbers
        }
        return services.fetchSpeaksPagination(xdata,num)
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .speaks_container{
        margin-bottom: 70px;
    }
    .speaks_image img{
        width: 100% !important;
    }
    .speaks_from_local{
        font-weight: bold;
    }
    .speaks_from{
        font-size: 16px;
        text-align: right;
    }
    .speaks_intro{
        text-indent: 25px;
        text-align: justify;
    }
    .showmore{
        margin-top: 50px;
    }
</style>
