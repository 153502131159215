<template>
  <div>

    <!-- Breadcrumb Section Begin -->
      <section v-if="COVER.image != undefined" class="breadcrumb_area">
          <div class="overlay bg-parallax" v-bind:style="{ 'background-image': 'url(' + COVER.image + ') !important' }" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
      <section v-else class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
    <!-- Breadcrumb Section End -->
    <!-- Shop Section Begin -->
    <section class="shop spad" id="product_list">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <center>
                        <img :src="`${this.$store.state.stylesImageURL}`+`/`+`${prod.image_file}`"  style="max-width: 300px; width: 100%" alt="">
                    </center>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>{{this.prod.product}}</h2>
                        </div>
                    </div>
                    <div class="row" style="vertical-align: bottom">
                        <div class="col-md-12">
                            <div style="text-align: justify" id="description"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <center>
                        <h3>&#8369; {{this.formatter(this.prod.fetch_company_product_price_byid.amount)}}</h3>
                    </center>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="offset-md-8 col-md-4">
                            <input style="text-align: center" type="number" min="0" class="form-control form-control-sm" @change="updateQuantity()" v-model="prodQTY">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="offset-md-8 col-md-4">
                            <a href="#" @click.stop.prevent="addToCart(prod)" class="button_hover theme_btn_two form-control">Add To Cart</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="suggestionForm">
                <h2>Item Suggestions</h2>
                <div class="row">
                    <div v-for="(suggestprod,index) in foodArr.data" :key="suggestprod.id" class="col-md-3" :class="(index >= 4)?'hidden':''">
                        <!-- {{suggestprod}} -->
                        <div class="row">
                            <div class="col-md-12">
                                <img :src="`${this.$store.state.stylesImageURL}`+`/`+`${suggestprod.image_file}`"  style="width: 100%;height: 300px" alt="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <center>
                                    <h4>{{suggestprod.product}}</h4>
                                </center>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <center>
                                    <h5>&#8369; {{this.formatter(suggestprod.fetch_company_product_price_byid.amount)}}</h5>
                                </center>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <a href="#" @click.stop.prevent="viewMoreInformation(suggestprod)" class="button_hover theme_btn_two form-control">Check Item</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Shop Section End -->

    <div :class="loader_stat" class="loader-container">
      <img src="../../assets/loading.gif" alt="">
    </div>
  </div>
  
</template>

<script>
// import vueStickySidebar from "vue-sticky-sidebar";
import services from '../../service/webservice'
// import Paginate from 'vuejs-paginate-next';
// var InputMask = require('inputmask-core')
import { maska } from 'maska'
export default {
  name: 'styles_n',
  // props: {
  //   msg: String
  // },,
  components: {
  //   "vue-sticky-sidebar": vueStickySidebar
    //   paginate: Paginate,
        // maska
  },
  directives: { maska },
  data(){
    return {
        loader_stat: 'hidden',
        company: process.env.VUE_APP_COMPANY_CODE,
        foodloading: 0,
        foodArr: [],
        foodImgSrc: "",
        prodisplay: {0: "hide", 1: "show"},

        currentpage: 1,
        pagecnt: 0,
        currentView: 'web',
        prod: JSON.parse(localStorage.getItem('moreinfo')),

        prodQTY: 1,

        // -- WEB CONTENT
        COVER: [],
        webcontent: [], 

    }
  },
  created(){
    this.$store.state.header = "styles";
    // this.$store.getters.fetchCart

    this.getProducts()
    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    this.windowResizing()
    window.addEventListener("resize", this.windowResizing);
    // alert();
  },
  mounted(){
    let xapp = document.getElementById('description');
    xapp.innerHTML = this.prod.description;

    this.checkCurrentQty()
  },
  methods:{

    windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
        if (screenWidthCheck > 480) {
        this.currentView = 'web'
        } else {
        this.currentView = 'mobile'
        }
        this.displayContent()
    },
    contentvariableReset(){
        // -- FEATURES
        this.COVER = []
    },
    displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > 480 ){
            // -- WEB
            // -- COVER
            if( this.webcontent['web|styles|store|cover'] ){
                this.COVER.image = this.webcontent['web|styles|store|cover']['image']
            }
        } else {
            // -- MOBILE
            // -- COVER
            if( this.webcontent['mobile|styles|store|cover'] ){
                this.COVER.image = this.webcontent['mobile|styles|store|cover']['image']
            }
        }
    },
    getVCContent($url){
        this.xgetVCContent($url).then(response => {
        // console.log(response)
        this.webcontent = response.data
        // console.log(this.webcontent['web|home|vcFeatures|types|host'])
        this.displayContent()
        })
    },
    xgetVCContent($url){
        return services.fetchVCcontent($url)
    },
    checkCurrentQty(){
        var cart = this.$store.getters.fetchCart;
        for( const xcart in cart){
            // alert(cart[xcart]['product_id'])
            // alert(this.prod.id)
            if( cart[xcart]['product_id'] == this.prod.id ){
                this.prodQTY = cart[xcart]['quantity'];
            }
        }
    },
    updateQuantity(){
        if( this.prodQTY == '' ){
            this.prodQTY = 1
        }
    },
    addToCart(objt){
        var cart = this.$store.getters.fetchCart;
        // (this.$store.getters.fetchCart)
        var isAdd = 1;
        // var cart = this.$store.getters.fetchCart;
        for( const xcart in cart){
            if( objt['id'] == cart[xcart]['product_id'] ){
                cart[xcart]['quantity'] = this.prodQTY
                isAdd = 0;
            }
        }

        var xxarr = []
        if( isAdd == 1 ){
            xxarr= {
                "product_id": objt['id'],
                "product": objt,
                "quantity": this.prodQTY
            }
            cart.push(xxarr)
        }

        // var xarr = xxarr
        this.$store.state.cart = cart
        localStorage.setItem('myCart', JSON.stringify(cart))
        this.$store.getters.fetchCart

        window.location.href="/vc-store"
        // alert(JSON.stringify(cart))
        // alert(JSON.stringify(objt['id']))
    },
    formatter(price){
        return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    getProducts(){
        this.fetchProducts().then(response => {
            this.foodloading = 1
            this.foodArr = response.data.list
            // console.log(this.foodArr)
            this.foodImgSrc = response.data.motelIMG    
            this.$store.state.stylesImageURL = response.data.motelIMG   
            // alert(response.data.list.last_page)
            this.pagecnt = response.data.list.last_page
        })
    },
    fetchProducts(){
        var xdata = {
            company: this.company,
        //   branch: this.branch,
            filter: ""
        }
        return services.fetchProducts(xdata)
    },
    validateFields(arrinput){
        var stat = 1;
        arrinput.forEach((value)=>{
            if( value == "" ){
            stat = 0
            }
        })
        return stat
    },
      viewMoreInformation(xprod){
          localStorage.setItem('moreinfo',JSON.stringify(xprod))
          window.location.href="/style-moreinfo";
        //   console.log()
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hidden{
        display: none;
    }
    .cart{
        font-size: 20px;
        /* text-align: right; */
    }
    .cart-items{
        border: 1px solid rgba(197, 194, 194, 0.925);
        /* margin-bottom: 20px; */
    }
    .cart-border-left{
        border-left: 1px solid rgba(197, 194, 194, 0.925);
    }
    .cart-align-middle{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* margin: 30% 0 !important */
    }
    @media screen and (max-width: 480px) {
        .mobile-reset-padding{
        padding-left: 15px !important;
        padding-right: 15px !important;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .page-cover{
        position: relative !important;
        max-width: 1900px;
        /* align-content: center; */
        margin: auto;
    }
    .page-cover img{
        width: 100%;
        position: relative;
    }
    .page-cover .cover-message{
        position: absolute;
        top: 45%;
        color: white;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .cover-message h1{
        font-size: 45px;
    }
    /* .btn-shop-now{
        border: 1px solid #f8b100;
        width: 200px;
    } */
    .div-title{
        text-align: center !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    .product__item__pic{
        background-repeat: no-repeat;
        background-size: cover;
    }
    .loader-container{
    position: fixed;
    background: rgba(0,0,0,0.9);
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;

    /* margin: auto; */
  }
  .loader-container img{
    /* background: rgba(255, 255, 255, 0.5); */
    width: 100px;
    margin: auto;
    top: 45vh;
    position: relative;
    display: block;

  }
   .hidden{
     display: none;
   }
   .showed{
     display: block;
   }
   .input-required{
       color: red;
       font-size: 12px;
       margin: 0;
   }
   .padBot{
       padding-top: 10px;
       /* padding-bottom: 10px; */
   }
   .suggestionForm{
       margin-top: 200px;
   }
    .breadcrumb_area .bg-parallax {
        background-image: url('../../assets/img/host/home/vc-host-header.jpg')!important;
        background-repeat: no-repeat !important;
        background-attachment: scroll !important;
        background-position: center !important;
        background-size: cover !important;
    }
</style>
