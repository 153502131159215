<template>
  <div>
    <!-- Breadcrumb Section Begin -->
      <!-- <section class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
              </div>
          </div>
      </section> -->
    <!-- Breadcrumb Section End -->
    <section class="bg-abstract booking">
      <div class="container">
        <div class="row" style="padding: 55px 0px !important">
          <div class="col-md-8">
            <!-- <vue-sticky-sidebar topSpacing="150" containerSelector=".container" innerWrapperSelector='.sidebar__inner' class="sidebar"> -->
            <!-- <div class="col-md-5"> -->
              <div class="settlement-container">
                <div class="settlement-header">
                  <h2>Room Information</h2>
                </div>
                <div class="settlement_form">
                  <div class="row">
                    <div class="col-md-12">
                      <center>
                        <p class="dspl-roomName">{{roomName}} <span>({{branchName}})</span></p>
                      </center>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="label-header">Amenities: </span>
                      <div class="row">
                        <div v-for="ameCat in ameCat.data" :key="ameCat.id" class="col-3" >
                          <center>
                            <img style="width: 25px;opacity: 0.4" :id="'amen_'+roomNumber+'_'+ameCat.id" :src="xHost+'/'+ameCat.img" alt="My Happy SVG"/>
                            <br/><label style="font-size: 8px; margin-bottom: 10px;opacity: 0.1;" :id="'dspl_amen_'+roomNumber+'_'+ameCat.id">{{ameCat.category}}</label>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <center>
                        <img v-if="roomImg == '' || roomImg == null" src="../../assets/img/room/no-image.png" style="width: 60%" alt="">
                        <!-- <img v-else :src="getImgUrl(img_path,company,(branch).toLowerCase(),room.room_img)" alt=""> -->
                        <img v-else :src="`${img_path}${company}/${(branch).toLowerCase()}/${roomImg}`"  style="width: 60%" alt="">
                      </center>
                    </div>
                  </div>
                  <div v-if="roomDesc!=''" class="row">
                    <div class="col-md-12">
                      <span class="label-header">Description: </span>
                      <br>
                      {{roomDesc}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="offset-md-1 col-md-10">
                      <table class="table table-bordered">
                        <tr>
                          <th>Reservation:</th>
                          <td>
                            <div class="row">
                              <div class="col-md-8">
                                <b>DATE:</b> {{reservationDate}}
                              </div>
                              <div class="col-md-4">
                                <b>TIME:</b> {{reservationTime}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Room Rate:</th>
                          <td>
                            <div class="row">
                              <div class="col-md-8">
                                {{roomRateName}}
                              </div>
                              <div class="col-md-4">
                                {{(price).toLocaleString('en-PH', {currency: 'PHP', style: 'currency'})}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Voucher Code:</th>
                          <td>
                            <div class="row">
                              <div class="col-lg-8">
                                <div v-if="hasDiscount == 1" class="input-group">
                                  <input type="text" v-model="voucherCode" style="border-right: 1px solid transparent" class="form-control">
                                  <span class="input-group-addon" style="background: white !important;color: green;border-left: 1px solid transparent"><i class="fa fa-check-circle"></i></span>
                                </div>
                                <input v-else type="text" v-model="voucherCode" class="form-control">
                              </div>
                              <div class="col-lg-4">
                                <a href="#" @click.stop.prevent="applyVoucher()" class="button_hover btn-validate form-control">Apply</a>
                              </div>
                            </div>
                            
                          </td>
                        </tr>
                        <tr>
                          <th>Discount:</th>
                          <td v-if="discount==0">{{(discount).toLocaleString('en-PH', {currency: 'PHP', style: 'currency'})}}</td>
                          <td v-else style="color: red">{{(discount*-1).toLocaleString('en-PH', {currency: 'PHP', style: 'currency'})}}</td>
                        </tr>
                        <tr>
                          <th>Total:</th>
                          <td>{{(price-discount).toLocaleString('en-PH', {currency: 'PHP', style: 'currency'})}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <!-- <div class="col-12"> -->
                    <div class="NoteContainer">
                      <p><i>Note: For party rates, please contact us through our email(<a href="mailto:serve@victoriacourt.com" target="_blank">serve@victoriacourt.com</a>) or phone number(<a href="tel:0917-VCSERVE">0917-VCSERVE</a>)</i></p>
                    </div>
                  <!-- </div> -->
                </div>
              </div>
              
            <!-- </div> -->
            <!-- </vue-sticky-sidebar> -->
          </div>
          <div class="col-md-4">
            <div class="settlement-container">
              <div class="settlement-header">
                <h2>Guest Information</h2>
              </div>
              <div class="settlement_form">
                <div class="row">
                  <div class="col-md-12 padBot">
                    <label>First Name</label>
                    <input type="text" v-model="firstname" class="form-control">
                    <p class="input-required" v-if="error.firstname != null">{{this.changeDescription('firstname',this.error.firstname[0])}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 padBot">
                    <label>Last Name</label>
                    <input type="text" v-model="lastname" class="form-control">
                    <p class="input-required" v-if="error.lastname != null">{{this.changeDescription('lastname',this.error.lastname[0])}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 padBot">
                    <label>Email</label>
                    <input type="text" v-model="email" class="form-control">
                    <p class="input-required" v-if="error.email != null">{{this.changeDescription('email',this.error.email[0])}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Remarks</label>
                    <textarea name="" v-model="remarks" class="form-control remarks" id="" cols="30" rows="10"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="settlement-container" style="margin-top: 50px">
              <div class="settlement-header">
                <h2>Payment</h2>
              </div>
              <div class="settlement_form">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <label>Mode of Payment</label> -->
                    <div class="accordion" id="accordionExample">
                      <div class="card">
                        <div class="card-header" id="wallet-head" data-toggle="collapse" data-target="#wallet-payment" aria-expanded="true" aria-controls="wallet-payment">
                          <h2 class="mb-0">
                            <button class="btn btn-link" type="button">
                              Wallet
                            </button>
                          </h2>
                        </div>
                        <div id="wallet-payment" class="collapse show" aria-labelledby="wallet-head" data-parent="#accordionExample">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-gc" @change="selPMethod('pm-gc')" value="wallet|gc"> GCash
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-wechat" @change="selPMethod('pm-wechat')" value="wallet|wechat"> WeChat
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-header collapsed" id="debit-head" data-toggle="collapse" data-target="#debit-payment" aria-expanded="false" aria-controls="debit-payment">
                          <h2 class="mb-0">
                            <button class="btn btn-link" type="button">
                              Debit
                            </button>
                          </h2>
                        </div>
                        <div id="debit-payment" class="collapse" aria-labelledby="debit-head" data-parent="#accordionExample">
                          <div class="card-body">
                            <div class="row">
                              <!-- <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-bn" @change="selPMethod('pm-bn')" value="onlinebanktransfer|bn"> Bancnet Philippines
                                </label>
                              </div> -->
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-bpi_ph" @change="selPMethod('pm-bpi_ph')" value="onlinebanktransfer|bpi_ph"> Bank of the Philippine Island
                                </label>
                              </div>
                              <!-- <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-ubponline_ph" @change="selPMethod('pm-ubponline_ph')" value="onlinebanktransfer|ubponline_ph"> Union Bank of the Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-pnbonline_ph" @change="selPMethod('pm-pnbonline_ph')" value="onlinebanktransfer|pnbonline_ph"> Philippine National Bank
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-instapay_qr_ph" @change="selPMethod('pm-instapay_qr_ph')" value="onlinebanktransfer|instapay_qr_ph"> All Philippine banks that is supported by Instapay QR or QR PH
                                </label>
                              </div> -->
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-br_bdo_ph" @change="selPMethod('pm-br_bdo_ph')" value="onlinebanktransfer|br_bdo_ph"> BDO Online Bank Transfer (via Brankas)
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-br_rcbc_ph" @change="selPMethod('pm-br_rcbc_ph')" value="onlinebanktransfer|br_rcbc_ph"> RCBC Online Bank Transfer (via Brankas)
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-br_pnb_ph" @change="selPMethod('pm-br_pnb_ph')" value="onlinebanktransfer|br_pnb_ph"> PNB Online Bank Transfer (via Brankas)
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-header collapsed" id="otc-head" data-toggle="collapse" data-target="#otc-payment" aria-expanded="false" aria-controls="otc-payment">
                          <h2 class="mb-0">
                            <button class="btn btn-link" type="button">
                              OTC Payment
                            </button>
                          </h2>
                        </div>
                        <div id="otc-payment" class="collapse" aria-labelledby="otc-head" data-parent="#accordionExample">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-creditcard" @change="selPMethod('pm-creditcard')" value="creditcard|bdo_cc_ph"> Credit Card
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-bayadcenter_ph" @change="selPMethod('pm-bayadcenter_ph')" value="nonbank_otc|bayadcenter_ph"> Bayad Center Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-711_ph" @change="selPMethod('pm-711_ph')" value="nonbank_otc|711_ph"> 711 Network Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-cliqq_ph" @change="selPMethod('pm-cliqq_ph')" value="nonbank_otc|cliqq_ph"> 711 Cliqq Network Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-mlhuillier_ph" @change="selPMethod('pm-mlhuillier_ph')" value="nonbank_otc|mlhuillier_ph"> Mlhuillier Pawnshop Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-cebuana_ph" @change="selPMethod('pm-cebuana_ph')" value="nonbank_otc|cebuana_ph"> Cebuana Pawnshop Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-ecpay_ph" @change="selPMethod('pm-ecpay_ph')" value="nonbank_otc|ecpay_ph"> Ecpay Network Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-da5_ph" @change="selPMethod('pm-da5_ph')" value="nonbank_otc|da5_ph"> Direct Agents 5 Network Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-expresspay_ph" @change="selPMethod('pm-expresspay_ph')" value="nonbank_otc|expresspay_ph"> Expresspay Network Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-dp_ph" @change="selPMethod('pm-dp_ph')" value="nonbank_otc|dp_ph"> DragonPay Philippines
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-smbills_ph" @change="selPMethod('pm-smbills_ph')" value="nonbank_otc|smbills_ph"> SM Bills Payment Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-truemoney_ph" @change="selPMethod('pm-truemoney_ph')" value="nonbank_otc|truemoney_ph"> True Money Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-posible_ph" @change="selPMethod('pm-posible_ph')" value="nonbank_otc|posible_ph"> Posible.net Network
                                </label>
                              </div>
                              <div class="col-md-12 pmethod">
                                <label>
                                  <input type="checkbox" class="chk-paymentMethod" id="pm-etap_ph" @change="selPMethod('pm-etap_ph')" value="nonbank_otc|etap_ph"> Etap Network
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-12">
                    <label>Mode of Payment</label>
                    <select v-model="modepayment" name="" class="form-control" id="">
                      <option value="onlinebanktransfer|bpi_ph">Bank of the Philippine Island</option>
                      <option value="onlinebanktransfer|br_bdo_ph">BDO Online Bank Transfer (via Brankas)</option>
                      <option value="onlinebanktransfer|br_rcbc_ph">RCBC Online Bank Transfer (via Brankas)</option>
                      <option value="onlinebanktransfer|br_pnb_ph">PNB Online Bank Transfer (via Brankas)</option>
                      <option value="wallet|gc">GCash</option>
                      <option value="wallet|wechat">WeChat</option>
                      <option value="nonbank_otc|bayadcenter_ph">Bayad Center Network</option>
                      <option value="nonbank_otc|711_ph">711 Network Philippines</option>
                      <option value="nonbank_otc|cliqq_ph">711 Cliqq Network Philippines</option>
                      <option value="nonbank_otc|mlhuillier_ph">Mlhuillier Pawnshop Network</option>
                      <option value="nonbank_otc|cebuana_ph">Cebuana Pawnshop Network</option>
                      <option value="nonbank_otc|ecpay_ph">Ecpay Network Philippines</option>
                      <option value="nonbank_otc|da5_ph">Direct Agents 5 Network Philippines</option>
                      <option value="nonbank_otc|expresspay_ph">Expresspay Network Philippines</option>
                      <option value="nonbank_otc|dp_ph">DragonPay Philippines</option>
                      <option value="nonbank_otc|smbills_ph">SM Bills Payment Network</option>
                      <option value="nonbank_otc|truemoney_ph">True Money Network</option>
                      <option value="nonbank_otc|posible_ph">Posible.net Network</option>
                      <option value="nonbank_otc|etap_ph">Etap Network</option>
                    </select>
                  </div>
                </div> -->
                <br>
                <div class="row">
                  <div class="col-md-12">
                      <a href="#" @click.stop.prevent="continueToBook()" class="button_hover theme_btn_two form-control">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div :class="loader_stat" class="loader-container">
      <img src="../../assets/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
  import services from '../../service/webservice'
  // import vueStickySidebar from "../../../node_modules/vue-sticky-sidebar/src/vue-sticky-sidebar.vue";
  // import VueSweetalert2 from 'vue-sweetalert2';
  export default {
    name: 'booking_settlement_n',
    // props: {
    //   msg: String
    // },
    // components: {"vue-sticky-sidebar": vueStickySidebar},
    data(){
      return {

        loader_stat: 'hidden',

        company: process.env.VUE_APP_COMPANY_CODE,
        xHost: process.env.VUE_APP_HOST,
        img_path: process.env.VUE_APP_ROOM_WEBSITE_URL,

        firstname: "",
        lastname: "",
        email: "",
        remarks: "",
        modepayment: "",

        branch: localStorage.getItem('branch'),
        reserveDate: localStorage.getItem('date'),
        reserveTime: localStorage.getItem('time'),
        roomid: localStorage.getItem('roomid'),
        rateid: localStorage.getItem('roomrate'),
        roomtype: localStorage.getItem('roomtypeid'),
        
        
        branchName: "",
        reservationDate: "",
        reservationTime: "",
        roomName: "",
        roomNumber: "",
        roomImg: "",
        amenities: "",
        roomRateName: "",
        price: "",
        roomDesc: "",
        discount: 0,
        hasDiscount: 0,
        discountInfo: [],

        voucherCode: "",

        listAmenities: [],
        isAvailableRoom: 0,
        ameCat: [],
        

        return_url: location.protocol+"//"+location.host,
        // return_url: location.host+'/bookingsettle',

        error: [],

        branchinfo: this.$store.getters.getBranchInformations,

      }
    },
    created(){
      this.$store.state.header = "booking";
      // this.rooms = this.getRooms()
      // console.log(location.host)

      this.roomAvailabilityCheck(this.roomid).then(response => {
        if(response.data != 1){
          this.$swal(
              '&nbsp;',
              'This room is not available right now. Please select new room'
            ).then(()=>{
              window.location.href="/booking";
            });
        }
      })

      
    },
    mounted(){

      // setTimeout(()=>{
        // alert(isNaN(new Date(this.reserveDate)) +" === "+ this.reserveDate)
        if (isNaN(new Date(this.reserveDate)) == true || this.reserveDate == null) {
          this.$swal(
            '&nbsp;',
            'Sorry for inconvenience we encounter some error, please book again. Thankyou'
          ).then(()=>{
            window.location.href="/booking"
          })
        }
      // },2000);

      this.fetchResaInfo()
      this.getAmenityCategory()
      this.getAmenities()

      // -- inactivity checker
      this.inactivityTime()
      // this.getModepayment('pm-gc')
      this.$store.state.mybranch = this.branchinfo[this.branch]
    },
    updated: function () {
        this.$nextTick(function () {
          this.enableAmenitiesDspl()
        })
    },
    methods:{
      applyVoucher(){
        this.loader_stat = "showed"
        this.checkVoucher().then(response => {
          this.loader_stat = "hidden"
          // this.ameCat = response
          var xInfo = response.data;
          if( xInfo.status ){
            this.discountInfo = xInfo;
            this.hasDiscount = 1
            if( xInfo.data.isAmount == 1 ){
              this.discount = xInfo.data.discount
            } else {
              var percent = xInfo.data.discount/100
              this.discount = this.price*percent
            }
            
          } else {
            this.discountInfo = []
            this.hasDiscount = 0
            this.discount = 0;
            this.$swal(
              '&nbsp;',
              xInfo.message
            );
            // alert(xInfo.message)
          }
          console.log(xInfo)
        })
      },
      checkVoucher(){
        var xdata = {
          company: this.company,
          branch: (this.branch).toLowerCase(),
          code: this.voucherCode,
          booking_date: this.reserveDate+" "+this.reserveTime+":00"
        }
        return services.fetchVoucherViaCode(xdata)
      },
      enableAmenitiesDspl(){
        setTimeout(()=>{
            console.log(this.listAmenities['amenities'])
            // for (let [key, v] of Object.entries(this.listAmenities)) {
            //   console.log(key)
              this.listAmenities['amenities'].forEach((v1)=>{
                if( v1['amenities_category'] ){
                  var el = document.getElementById('amen_'+this.listAmenities['room_number']+'_'+v1['amenities_category']['id'])
                  var xel = document.getElementById('dspl_amen_'+this.listAmenities['room_number']+'_'+v1['amenities_category']['id'])
                  
                  if( el ){
                    el.style.opacity = 1
                  }

                  if( xel ){
                    xel.style.opacity = 1
                    if( v1['a_desc'] == 'Yes' || v1['a_desc'] == 'YES' ){
                      xel.innerText = v1['amenities_category']['category']
                    } else {
                      xel.innerText = v1['a_desc']
                    }
                  }
                }
              })
            // }
        },1000);
      },
      getAmenityCategory(){
        this.getAmeCatPerAmenities().then(response => {
          this.ameCat = response
          console.log(response.data)
        })
      },
      getAmeCatPerAmenities(){
        var xdata = {
          company: this.company,
          branch: this.branch
        }
        return services.fetchAmenityCategoryPerAmenities(xdata)
      },
      // continueToBook(){
      //   alert('asdadsas')
      // },
      // -- inactivity checker
      changeDescription(xcode,xstring){
          return xstring.replace('The '+xcode,'This')
      },
      selPMethod(checkID){
        const checkboxes = document.querySelectorAll('.chk-paymentMethod');
          for (const c of checkboxes) {
            c.checked = false
          }
          document.querySelector('#'+checkID).checked = true;
          this.getModepayment(checkID)
      },
      getModepayment(checkID){
          this.modepayment = document.querySelector('#'+checkID).value;
      },
      inactivityTime(){
        var time;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;

        function logout() {
          // alert('15mins Idle, please check room availability again')
          // window.location.href="/booking";
            this.$swal(
              '&nbsp;',
              '15mins Idle, please check room availability again'
            ).then(()=>{
              window.location.href="/booking";
            });
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 300000) // 15mins
        }
      },
      formatter(price){
        return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      },
      getAmenities(){
        this.fetchAmenitiesByRoomID().then(response=>{
          this.listAmenities = response.data
          this.enableAmenitiesDspl()
          this.roomImg = response.data.get_room_info.room_img
          console.log(this.roomImg)
          // console.log(response.data)
        })
      },
      fetchAmenitiesByRoomID(){
        var xdata = {
          company: this.company,
          branch: this.branch,
          roomid: this.roomid,
        }
        return services.fetchAmenitiesByRoomID(xdata)
      },
      fetchResaInfo(){
        this.fetchOtherDetails().then(response=>{
          this.branchName = response.data.branchName
          this.reservationDate = response.data.reservationDate
          this.reservationTime = response.data.reservationTime
          this.roomName = response.data.roomName
          this.roomNumber = response.data.roomnumber
          // this.amenities = response.data.
          this.roomRateName = response.data.roomRate
          // this.price = this.formatter(response.data.price)
          this.price = response.data.price
          this.roomDesc = response.data.roomDesc
        })
      },
      roomAvailabilityCheck(roomid){
        var xdata = {
          company: this.company,
          branch: this.branch,
          date: this.reserveDate,
          roomid: roomid
        }
        return services.roomAvailabilityCheck(xdata)
      },
      async continueToBook(){
        this.loader_stat = "showed"
        await this.roomAvailabilityCheck(this.roomid).then(response => {
          this.isAvailableRoom = response.data
        })
        if( this.isAvailableRoom == 1 ){
          if( this.modepayment != "" ){
            // alert(Object.prototype.toString.call(this.reserveDate))
            // var timestamp = Date.parse(this.reserveDate);

            if (isNaN(new Date(this.reserveDate)) == false && this.reserveDate != null) {

              this.bookingPayment().then(response => {
                // alert(JSON.stringify(response))
                var res = JSON.parse(JSON.stringify(response));
                // console.log(res)
                if( res['data']['status'] == 1 ){
                  var xMethod = this.modepayment.split('|');
                  if( xMethod[0] == 'wallet' || xMethod[0] == 'onlinebanktransfer' || xMethod[0] == 'onlinebillspayment'  || xMethod[0] == 'creditcard' ){
                    window.location.href=res['data']['link'];
                  } else {
                    var instruction = res['data']['direct_otc_info'][0];
                    this.$swal(
                      '&nbsp;',
                      (instruction['pay_instructions']).replaceAll('\n', '<br><br>')+'<br><br>Reference Number: '+instruction['pay_reference']
                    ).then(()=>{
                      window.location.href="/booking";
                    });
                    // alert(instruction['pay_instructions']+'\n\nReference Number: '+instruction['pay_reference']);
                    // window.location.href="/booking";
                  }
                } else {
                  alert(res['data']['json'])
                }
              }).catch(error=>{
                this.$swal(
                  '&nbsp;',
                  'Please input guest information'
                ).then(()=>{
                  this.loader_stat = "hidden"
                  console.log(error.response.data.errors)
                  this.error = error.response.data.errors
                })
              })
            } else {
              this.$swal(
                '&nbsp;',
                'Sorry for inconvenience we encounter some error, please book again. Thankyou'
              ).then(()=>{
                window.location.href="/booking"
              })
            }
          } else {
            this.$swal(
              '&nbsp;',
              'Please select mode of payment'
            ).then(()=>{
              this.loader_stat = "hidden"
            })
          }
            
        } else {
          this.$swal(
            '&nbsp;',
            'This room is not available right now. Please select new room'
          ).then(()=>{

            window.location.href="/booking";

          });
          

        }
      },
      fetchOtherDetails(){
        var xData = {
          company: this.company,
          branch: this.branch,
          reserveDate: this.reserveDate,
          reserveTime: this.reserveTime,
          roomid: this.roomid,
          rateid: this.rateid
        }
        return services.fetchOtherDetails(xData)
      },
      validateFields(arrinput){
        var stat = 1;
        arrinput.forEach((value)=>{
          if( value == "" ){
            stat = 0
          }
        })
        return stat
      },
      bookingPayment(){
        // var requiredVal = [
        //   this.firstname,
        //   this.lastname,
        //   this.email,
        //   this.modepayment
        // ];
        // if( this.validateFields(requiredVal) == 1 ){
          var xData = {

            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            remarks: this.remarks,
            modepayment: this.modepayment,

            company: this.company,
            branch: this.branch,
            date: this.reserveDate,
            time: this.reserveTime,
            roomid: this.roomid,
            rateid: this.rateid,
            roomtype: this.roomtype,

            price: this.price,
            return_url: this.return_url,

            discount: this.discount,
            discountInfo: JSON.stringify(this.discountInfo),

          }
          return services.bookingPayment(xData)
        // } else {
        //   this.$swal(
        //       '&nbsp;',
        //       'Please Complete All Required Fields'
        //     )
        //   this.loader_stat = "hidden"
        // }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bg-abstract{
    background: url("../../../public/template/home/image/bg-skin.png");
    background-repeat: repeat;
    /* background-size: 2px; */
  }
  /* .booking{ */
    /* background: rgb(20, 20, 20); */
  /* } */
  .booking:not(i){
    font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif !important;
  }
  .settlement-container{
   /* box-shadow: 1px 1px 10px #000; */
    border: 1px solid black;
    /* border-top: transparent; */
  }
  .settlement-header{
    /* color: white; */
    /* background: #f8b600; */
    background: white;
    text-align: center;
    padding-top: 10px;
  }
  .settlement-header h2{
    margin: 0;
  }
  .settlement_form{
    /* box-shadow: 1px 1px 5px #000; */
    padding: 20px;
    padding-bottom: 50px;
    /* margin: 10px; */
    /* border-radius: 5px; */
    border-top: 1px solid black;
    background: white;
  }
  .form-title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    padding-bottom: 30px;
  }
  .label-header{
    font-weight: bold;
    font-size: 18px;
  }
  .remarks{
    resize:none;
    height: 150px;
  }

  .swal-button {
    width: 150px !important;
  }

  .loader-container{
    position: fixed;
    background: rgba(0,0,0,0.9);
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;

    /* margin: auto; */
  }
  .loader-container img{
    /* background: rgba(255, 255, 255, 0.5); */
    width: 100px;
    margin: auto;
    top: 45vh;
    position: relative;
    display: block;

  }
   .hidden{
     display: none;
   }
   .showed{
     display: block;
   }
   .dspl-roomName{
     font-size: 34px;
     font-weight: bold;
     background: black;
     color: white;
     width: 100%;
     padding: 20px;
   }
   .dspl-roomName span{
     font-size: 24px;
     font-weight: normal;
   }
   @media screen and (max-width: 480px) {
    .dspl-roomName{
      width: 100%;
    } 
   }
   .input-required{
       color: red;
       font-size: 12px;
       margin: 0;
   }
   .padBot{
       padding-top: 10px;
       /* padding-bottom: 10px; */
   }

  .NoteContainer{
    /* background: rgb(163, 36, 36);
    padding: 10px; */
    text-align: center;
  }
  .NoteContainer *{
    color: red;
    font-size: 13px;
  }
  .NoteContainer a{
    text-decoration: underline;
  }
  .btn-validate{
    display: block;
    background: #222222;
    color: #fff;
    text-transform: uppercase;
  }
</style>
