import { createRouter, createWebHistory } from 'vue-router';

import home_n from '../components/home/home.vue';

import styles_n from '../components/shop/shop.vue';
import store_n from '../components/shop/store.vue';
import style_checkout_n from '../components/shop/checkout.vue';
import style_moreinfo_n from '../components/shop/moreinfo.vue';
import xstyles_n from '../components/styles/styles.vue';

import speaks_n from '../components/speak/speak.vue';
import read_n from '../components/speak/read.vue';

import location_n from '../components/location/location.vue';
import booking_n from '../components/booking/booking.vue';
import booking_settlement_n from '../components/booking/settlement.vue';


import balintawak_n from '../components/locale/balintawak.vue';
import cuneta_n from '../components/locale/cuneta.vue';
import gilpuyat_n from '../components/locale/gilpuyat.vue';
import hillcrest_n from '../components/locale/hillcrest.vue';
import laspinas_n from '../components/locale/laspinas.vue';
import malabon_n from '../components/locale/malabon.vue';
import malate_n from '../components/locale/malate.vue';
import northedsa_n from '../components/locale/northedsa.vue';
import panorama_n from '../components/locale/panorama.vue';
import sanfernando_n from '../components/locale/sanfernando.vue';


import bites_n from '../components/bites/main.vue';
import breakfast_n from '../components/bites/breakfast.vue';
import sandwich_n from '../components/bites/sandwich.vue';

import signup_n from '../components/account/signup.vue'
import created_n from '../components/account/created.vue'
import verified_n from '../components/account/verified_account.vue'
import login_n from '../components/account/login.vue'
import myaccount_n from '../components/account/myaccount.vue'

import aboutus_n from '../components/aboutus/aboutus.vue'

import legalnotice_n from '../components/policy/legalnotice.vue'
import privacypolicy_n from '../components/policy/privacypolicy.vue'



// breakfast
// sandwitch
// imperialfeast
// meat
// bar

export default new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/', name: 'index', component: home_n
        },
        {
            path: '/home', name: 'home', component: home_n
        },

        // -- VICTORIA STYLES
        {
            path: '/styles', name: 'styles', component: styles_n
        },
        {
            path: '/vc-store', name: 'vc-store', component: store_n
        },
        {
            path: '/style-checkout', name: 'style-checkout', component: style_checkout_n
        },
        {
            path: '/style-moreinfo', name: 'style-moreinfo', component: style_moreinfo_n
        },
        {
            path: '/style', name: 'styles_n', component: xstyles_n
        },

        // -- VICTORIA SPEAKS
        {
            path: '/speaks', name: 'speaks', component: speaks_n
        },
        {
            path: '/read', name: 'read', component: read_n
        },

        // -- VICTORIA COURT
        {
            path: '/location', name: 'location', component: location_n
        },
        {
            path: '/balintawak', name: 'balintawak', component: balintawak_n
        },
        {
            path: '/cuneta', name: 'cuneta', component: cuneta_n
        },
        {
            path: '/gilpuyat', name: 'gilpuyat', component: gilpuyat_n
        },
        {
            path: '/hillcrest', name: 'hillcrest', component: hillcrest_n
        },
        {
            path: '/laspinas', name: 'laspinas', component: laspinas_n
        },
        {
            path: '/malabon', name: 'malabon', component: malabon_n
        },
        {
            path: '/malate', name: 'malate', component: malate_n
        },
        {
            path: '/northedsa', name: 'northedsa', component: northedsa_n
        },
        {
            path: '/panorama', name: 'panorama', component: panorama_n
        },
        {
            path: '/sanfernando', name: 'sanfernando', component: sanfernando_n
        },

        // -- VICTORIA BITES

        {
            path: '/bites', name: '_homebites', component: bites_n
        },
        {
            path: '/breakfast', name: 'breakfast', component: breakfast_n
        },
        {
            path: '/sandwich', name: 'sandwich', component: sandwich_n
        },

        // -- BOOKING
        {
            path: '/booking', name: 'booking_n', component: booking_n
        },
        {
            path: '/booking_settlement', name: 'booking_settlement', component: booking_settlement_n
        },
        // {
        //     path: '/bites/:bites', name: 'bites' , component: bites_n,
        //     children:[
        //         {
        //             path: '/', name: 'bites', component: breakfast_n
        //         },
        //         {
        //             path: '/breakfast', name: 'breakfast', component: breakfast_n
        //         },
        //         {
        //             path: '/sandwich', name: 'sandwich', component: sandwich_n
        //         }
        //     ]
        // },
        // {
        //     path: '/appointment_complete', name: 'appointment_complete', component: appointment_complete
        // }

        // -- ACCOUNT
        {
            path: '/signup', name: 'signup_n', component: signup_n
        },
        {
            path: '/account_created', name: 'created_n', component: created_n
        },
        {
            path: '/activateaccount', name: 'verified_n', component: verified_n
        },
        {
            path: '/login', name: 'login_n', component: login_n
        },
        {
            path: '/myaccount', name: 'myaccount_n', component: myaccount_n
        },

        // -- ABOUT US
        {
            path: '/aboutus', name: 'aboutus_n', component: aboutus_n
        },

        // -- POLICIES
        {
            path: '/legalterms', name: 'legalnotice_n', component: legalnotice_n
        },
        {
            path: '/privacypolicy', name: 'privacypolicy_n', component: privacypolicy_n
        }
    ]
});
