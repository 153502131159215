<template>
  <div>
      <section class="section_gap">
          <div class="paper">
              <div class="row">
                  <div class="col-md-12 ">
                      <div class="paperlogo">
                          <img src="../../assets/img/logo/VCLOGOonly.png" alt="">
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <h1 style="text-align: center !important">PRIVACY POLICY</h1>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <p>Victoria Court. (hereinafter “VICTORIA COURT”), with its registered office in Pasig City, Philippines, the operating holding company of the AK Group, recognizes the importance of maintaining the confidentiality, integrity and security of your personal data (hereinafter “Personal Data”), and inform you that any Personal Data which you provide to VICTORIA COURT through the website <a href="https://www.victoriacourt.com">www.victoriacourt.com</a> (the “Website”), as well as any Personal Data which you may provide to a VICTORIA COURT branch, including through its subsidiaries and/or affiliates (collectively the “AK Group”), will be processed in compliance with current applicable laws on privacy and with any specific local regulations, applicable from time to time, together with the principles and general rules of conduct contained in the Code of Ethics adopted by the AK Group.</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <ol class="terms">
                          <li>Type and source of data</li>


                            <div class="row">
                                <div class="col-md-12">
                                    <i><u>Browsing data</u></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>During their normal operation, the computer systems and software procedures used to operate our Website collect certain personal data (log files). The transmission of such data is inherent to the use of internet communication protocols. This information is not collected in order to be associated with specific data subjects. However, due to its nature, this information can allow users to be identified by means of its processing and integration with data held by third parties. Such information includes the IP addresses or domain names of the computers you use to visit our Website, URIs (Uniform Resource Identifiers) of the resources requested, the time of the request, the method used to submit the request to the server, the size of the file obtained in reply, the numerical status code of the server reply (successful, error, etc.) and other parameters concerning the user's operating system and computer environment. This data is used with the sole purpose of obtaining anonymous statistical information on the use of our Website and to guarantee its correct operation.</p>
                                </div>
                            </div>
                            
                            <div class="row" id="cookie">
                                <div class="col-md-12">
                                    <i><u>Cookies</u></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Our Website uses cookies to ensure its efficient functioning and to improve our services. Cookies are small text files that are sent to your computer (usually to your browser) by the websites you visit. Cookies are stored in your computer to be recognized by those websites on your subsequent visits. For more information on cookies, on the types of cookies used on our Website, on how to disable them, and on how you can revoke your consent, see our <a href="/privacypolicy#cookie">cookie policy</a>.</p>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <i><u>Personal Data voluntarily provided by the data subject</u></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p><i><b>Personal Data collected through the Website</b></i></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>VICTORIA COURT collects and processes the Personal Data that you directly and voluntarily provide through our Website to subscribe to our newsletter service.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>The items of Personal Data collected include email addresses and location.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Furthermore, when you voluntarily send an e-mail through our Website and/or to the addresses indicated on our Website, we collect your e-mail address so that we can reply to any request and any additional Personal Data contained in your message.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p><i><b>Personal Data collected at Victoria Court branches</b></i></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>VICTORIA COURT may also collect and process the Personal Data that you provide by filling in and signing our customer card (hereinafter the “Customer Card”) at a VICTORIA COURT branch.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>The items of Personal Data collected may include personally identifiable information (title, name and surname, location, date of birth), contact details (postal address, email, telephone), details of purchases, and/or other information regarding you that you may decide to provide during the interactions with our Duty Manager or with Guest Specialist.</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <i><u>Personal Data of minors</u></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>You must be at least 21 years old (or older depending on your state of residence) to provide us with your Personal Data.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>If you are a minor, you should obtain prior written consent from your parent or legal guardian before providing your personal data. VICTORIA COURT protects the Personal Data of minors by relevant national laws and regulations. Suppose VICTORIA COURT becomes aware that a minor has inadvertently provided Personal Data, without the prior consent of a parent or legal guardian. In that case, such data will be deleted or made anonymous.</p>
                                </div>
                            </div>


                          <li>Purposes and legal bases of the processing</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Your Personal Data may be processed and used for the following purposes:</p>
                                </div>
                            </div>
                            <ol class="disclaimer">
                                <li>) to respond to all your requests and to manage your relationship with the AK Group;</li>
                                <li>) to send by email the newsletters and other marketing communication regarding the AK Group’s products, services, initiatives, and events as a result of your subscription to the service.</li>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Furthermore, if you register to the AK Group customers database (as a result of your signing the Customer Card at a AK Group branch), your Personal Data will be managed by AK Group and shared with all VICTORIA COURT nationwide, and may be processed, together with the details of your purchases online and/or in stores, for the following purposes:</p>
                                    </div>
                                </div>
                                <li>) to confirm your identity as a registered AK Group customer and, consequently, to provide a customized customer care service and post-sales assistance and allow you to access exclusive services and benefits reserved for registered members (e.g.: preservation of your purchase order history, faster online checkout, simplified procedures for product repair and warranty, commercial discounts, pre-sale and other promotional events, etc.);</li>
                                <li>) profiling: to perform individual or group studies, survey, statistical analysis and market research with regards to your preferences for VICTORIA COURT and the other brands, products and services of the AK Group, so that a personalized service can be offered and cultural and recreational activities may be promoted based on customers’ interests;</li>
                                <li> marketing: to contact you and/or send you (by post, telephone, e-mail and any other form of electronic communication or digital means including social network platforms and other instant messaging applications) information and promotions, including commercial information, newsletters, direct marketing material, advertising, catalogues and invitations to events concerning VICTORIA COURT and other brands, products and services of the AK Group.</li>
                            </ol>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>The Personal Data processing referred to in subsections (a), (b) and (c) is necessary to provide the service requested by the data subject. Further, the data processing referred to in subsections (d) and (e) is based on the prior consent of the data subject.</p>
                                </div>
                            </div>


                          <li>Nature of the provision of personal data</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Providing your Personal Data to us is optional.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>However, if you wish to subscribe to our newsletter and/or register to the AK Group customer database and/or use any other services offered on the Website, you need to fill in all mandatory fields of the relevant forms, otherwise, VICTORIA COURT cannot proceed with the contractual services requested.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>The Personal Data processing for profiling and marketing, referred to under section 2, letters d) and e) above, is subject to your express prior consent.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>If you withhold your consent, we may not be able to proceed with the indicated purposes, including the ability to offer you personalized services, inform you of any initiatives that may interest you, and/or send you any other commercial information on products, initiatives and events of the AK Group.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>You may withdraw or modify your consent to the processing of your Personal Data by us at any time, by writing to the Data Controller at the contact addresses indicated, or by using the “unsubscribe” link included in all of our commercial electronic communications, and/or by using any other appropriate procedures which may be made available to you by VICTORIA COURT.</p>
                                </div>
                            </div>


                          <li>Processing Methods</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Your Personal Data will be processed by suitable electronic or automated means and computerized tools, or manually and on hard copy, exclusively for the purposes for which they have been collected and guaranteeing the security and confidentiality of any processed information through the adoption of appropriate measures to prevent the alteration, cancellation, destruction, unauthorized access or not allowed processing or not by the purpose of collection. Your Personal Data will be processed by the AK Group’s internal staff who are duly authorized to do so by their respective job duties.</p>
                                </div>
                            </div>


                          <li>Share, transfer, and disclosure of data</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Whenever necessary for, and/or instrumental to, the abovementioned purposes, your Personal Data may be processed on behalf of VICTORIA COURT by other entities (third parties) engaged by VICTORIA COURT  in the correct and regular pursuit of the described purposes, including</p>
                                </div>
                            </div>
                            <ul>
                                <li>AK Group companies and franchisees that operate VICTORIA COURT branches;</li>
                                <li>Third-party service providers, consultants, or firms providing advisory and/or consulting activities, or performing related data processing services on behalf of Victoria Court (e.g. service providers, carriers, IT technicians or any other suppliers appointed by Victoria Court to carry out and/or manage any promotional campaigns for Victoria Court's products and services, etc.).</li>
                            </ul> 
                            <div class="row">
                                <div class="col-md-12">
                                    <p>These third parties will act on behalf of VICTORIA COURT as data processors and are under a contractual obligation of confidentiality of the personal information.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Your Personal Data will not be used for third-party advertising purposes or the promotion of products, services, or initiatives by entities other than the AK Group, nor shall they be disclosed to unknown persons under any circumstances.</p>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-12">
                                    <i><u>Communication of data to the other PRADA Group companies</u></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <u>Please also note that if you register on the PRADA Group customers database for the purposes referred to in Sections 2, letters (c)-(e), your Personal Data will be automatically visible to, and shared with, all PRADA Group stores globally to provide you with the same level of service around the world. PRADA will take all appropriate and suitable security and confidentiality measures as required by applicable legislation to ensure an adequate standard of data protection, as well as in compliance with article 49, paragraph 1 letter b) of the GDPR, as the data transfer is necessary for the implementation of pre-contractual and contractual measures adopted at the data subject’s request.</u>
                                </div>
                            </div> -->


                          <li>Retention period</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Your Personal Data will be processed and stored for: (a) as long as required to carry out the purposes for which the Personal Data were collected; (b) in accordance with the storage periods required by applicable laws; or (c) until you revoke your consent to the processing/storage of your Personal Data, if applicable. After the conclusion of such period(s), and where there is no legal or business purpose for retaining your Personal Data, it will be automatically and permanently erased, or made anonymous.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>In particular, regarding the data processing of registered members into AK Group customers database:</p>
                                </div>
                            </div>
                            <ul>
                                <li>Personal Data collected for the purposes of customer management to offer personalized services and advantages reserved for registered members, are kept for a period of 7 years from the date of your last interaction with the PRADA Group; and</li>
                                <li>Purchase details are kept for a period of 7 years from the date of purchase.</li>
                            </ul>


                          <li>Data controller and contacts</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <b>Victoria Court Head Office</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b>Group Data Protection Officer</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Email address: <a href="mailto:brandbuilder@victoriacourt.com" target="_blank">brandbuilder@victoriacourt.com</a></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>If you have any requests regarding your Personal Data, have any inquiries regarding this Privacy Policy, or you would like to exercise your rights, please contact (free of charge) the Data Controller or the Group Data Protection Officer (“DPO”) by writing to the address/email address listed above.</p>
                                </div>
                            </div>


                          <li>Your Rights</li>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Please note that you may exercise the rights under the applicable privacy laws, such us the right to request information as to whether your Personal Data is being processed and as to the characteristics of the processing, the right to rectification and erasure of your Personal Data, to limitation of the data processing, and/or the right to object to the processing and/or request the transmission of your Personal Data transmitted to another controller, and/or the right to lodge a complaint with the competent supervisory authority if you believe there is noncompliance with the provisions of the applicable laws.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>You also have the right to withdraw your consent for the processing of your Personal Data by VICTORIA COURT at any time, without charge. The withdrawal of your consent will not affect the lawfulness of processing based on your consent before its withdrawal.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>VICTORIA COURT  reserves the right to amend this Privacy Policy at any time.</p>
                                </div>
                            </div>
                            
                      </ol>
                  </div>
              </div>
              
          </div>
      </section>
  </div>
</template>

<script>
    // import services from '../../service/account_webservice'
    // import services from '../../service/webservice'
    export default {
        name: 'aboutus_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                company: process.env.VUE_APP_COMPANY_CODE,
                // -- WEB CONTENT
                // COVER: [],
                // webcontent: [], 
                // HISTORY: [],
                // MISSION: [],
                // VISION: [],
            }
        },
        created(){
            this.$store.state.header = "policy";
            // this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
            this.windowResizing()
            window.addEventListener("resize", this.windowResizing);
        },
        // mounted(){
            
        // }
        methods:{
            windowResizing(){
                // alert()
                var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                // alert(screenWidthCheck)
                if (screenWidthCheck > process.env.VUE_APP_MOBILE_SIZE) {
                this.currentView = 'web'
                } else {
                this.currentView = 'mobile'
                }
                // this.displayContent()
            },
            // contentvariableReset(){
            //     // -- FEATURES
            //     this.COVER = []
            //     this.HISTORY = []
            //     this.MISSION = []
            //     this.VISION = []
            // },
            // imageVideoSet(path){
            //     var ext = path.split('.')
            //     var img = ""
            //     var arr = [
            //                         "jpg","jpeg","jpe","jif","jfif","jfi",
            //                         "png",
            //                         "gif",
            //                         "webp",
            //                         "tiff","tif",
            //                         "psd",
            //                         "raw","arw","cr2","nrw","k25",
            //                         "bmp","dib",
            //                         "heif","heic",
            //                         "ind","indd","indt",
            //                         "jp2","j2k","jpf","jpx","jpm","mj2",
            //                         "svg","svgz",
            //                         "ai",
            //                         "eps",
            //                         "pdf"
            //                     ];
            //     if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
            //     img = `
            //         <img style="width: 100%" src="`+path+`"/>
            //     `;
            //     } else {
            //     img = `
            //         <video style="width:100%" id="background-video" autoplay loop muted>
            //             <source src="`+path+`" type="video/mp4">
            //         </video>
            //     `;
            //     }
            //     return img;
            // },
            // displayContent(){
            //     this.contentvariableReset()
            //     // RESET VARIABLE
            //     let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            //     // alert(width)
            //     if( width > 480 ){
            //         // -- WEB
            //         // -- COVER
            //         if( this.webcontent['web|home|about|cover'] ){
            //             this.COVER.path = this.webcontent['web|home|about|cover']['image']
            //             this.COVER.image = this.imageVideoSet(this.webcontent['web|home|about|cover']['image'])
            //         }
            //         // -- HISTORY
            //         if( this.webcontent['web|home|about|history'] ){
            //             this.HISTORY.description = this.webcontent['web|home|about|history']['description']
            //         }
            //         // -- MISSION
            //         if( this.webcontent['web|home|about|rules|mission'] ){
            //             this.MISSION.description = this.webcontent['web|home|about|rules|mission']['description']
            //             this.MISSION.image = this.imageVideoSet(this.webcontent['web|home|about|rules|mission']['image'])
            //             this.MISSION.path = this.webcontent['web|home|about|rules|mission']['image']
            //         }
            //         // -- VISION
            //         if( this.webcontent['web|home|about|rules|vision'] ){
            //             this.VISION.description = this.webcontent['web|home|about|rules|vision']['description']
            //             this.VISION.image = this.imageVideoSet(this.webcontent['web|home|about|rules|vision']['image'])
            //             this.VISION.path = this.webcontent['web|home|about|rules|vision']['image']
            //         }
            //     } else {
            //         // -- MOBILE
            //         // -- COVER
            //         if( this.webcontent['mobile|home|about|cover'] ){
            //             this.COVER.path = this.webcontent['mobile|home|about|cover']['image']
            //             this.COVER.image = this.imageVideoSet(this.webcontent['mobile|home|about|cover']['image'])
            //         }
            //         // -- HISTORY
            //         if( this.webcontent['mobile|home|about|history'] ){
            //             this.HISTORY.description = this.webcontent['mobile|home|about|history']['description']
            //         }
            //         // -- MISSION
            //         if( this.webcontent['mobile|home|about|rules|mission'] ){
            //             this.MISSION.description = this.webcontent['mobile|home|about|rules|mission']['description']
            //             this.MISSION.image = this.imageVideoSet(this.webcontent['mobile|home|about|rules|mission']['image'])
            //             this.MISSION.path = this.webcontent['mobile|home|about|rules|mission']['image']
            //         }
            //         // -- VISION
            //         if( this.webcontent['mobile|home|about|rules|vision'] ){
            //             this.VISION.description = this.webcontent['mobile|home|about|rules|vision']['description']
            //             this.VISION.image = this.imageVideoSet(this.webcontent['mobile|home|about|rules|vision']['image'])
            //             this.VISION.path = this.webcontent['mobile|home|about|rules|vision']['image']
            //         }
            //     }
            //     // alert(this.COVER)
            // },
            // getVCContent($url){
            //     this.xgetVCContent($url).then(response => {
            //     // console.log(response)
            //     this.webcontent = response.data
            //     // console.log(this.webcontent['web|home|vcFeatures|types|host'])
            //     this.displayContent()
            //     })
            // },
            // xgetVCContent($url){
            //     return services.fetchVCcontent($url)
            // },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    *{
        text-align: justify;
    }
    .paperlogo img{
        width: 100px;
        position: absolute;
        top: -50px;
        right: 10px;
    }
    .paper{
        /* min-width: 21.59cm;
        max-width: 21.59cm; */
        width: 21.59cm;
        box-shadow: 1px 1px 5px black;
        margin: 0 auto;
        padding: 2.54cm 3.18cm;
        background: white;
        overflow-x: scroll;
    }
    .companyinfo li{
        margin-left: 50px;
        list-style: none;
        margin-bottom: 10px;
    }
    .disclaimer{
        list-style-type: lower-alpha;
    }
    .disclaimer li{
        margin-left: 50px;
    }
    ul li{
        margin-left: 50px;
    }
    .terms {
        margin: 0 0 1.5em;
        padding: 0;
        counter-reset: item;
    }

    .terms > li {
        margin: 0;
        padding: 0 0 0 2em;
        text-indent: -2em;
        list-style-type: none;
        counter-increment: item;
    }

    .terms > li:before{
        display: inline-block;
        width: 1em;
        padding-right: 0.5em;
        font-weight: bold;
        text-align: right;
        content: counter(item) ".";
    }

    @media screen and (max-width: 480px) {
        .paperlogo{
            margin: 0 auto;
            width: 100px;
        }
        .paperlogo img{
            width: 100%;
            position: initial;
            margin: 0 auto;
            /* top: -50px; */
            /* right: 10px; */
        }
        .paper{
            width: auto !important;
            padding: 50px 20px;
            margin: 1 auto;
        }
    }

</style>
