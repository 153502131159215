import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    responseType: 'json',
    withCredentials: false,
    headers: {},
});

// const webcontent = axios.create({
//     baseURL: process.env.VUE_APP_VC_WEBSITE_CONTENT,
//     responseType: 'json',
//     headers: {},
// });

export default{
    getRooms(data){
        return apiClient.post('/api/rooms',data)
    },
    getbranch(data){
        return apiClient.post('/api/branch',data)
    },
    getRoomRate(data){
        return apiClient.post('/api/getRoomRates',data)
    },
    bookingPayment(data){
        return apiClient.post('/api/bookingPayment',data)
    },
    fetchOtherDetails(data){
        return apiClient.post('/api/fetchOtherDetails',data)
    },
    fetchAmenities(data){
        return apiClient.post('/api/fetchAmenities',data)
    },
    fetchAmenitiesByRoomID(data){
        return apiClient.post('/api/fetchAmenitiesByRoomID',data)
    },
    roomAvailabilityCheck(data){
        return apiClient.post('/api/roomAvailabilityCheck',data)
    },
    fetchAmenityCategoryPerAmenities(data){
        return apiClient.post('/api/fetchAmenityCategoryPerAmenities',data)
    },
    fetchProducts(data){
        return apiClient.post('/api/fetchlistproducts',data)
    },
    fetchProductsPaginate(data,num){
        return apiClient.post('/api/fetchlistproducts?page='+num,data)
    },
    orderPayment(data){
        return apiClient.post('/api/orderPayment',data)
    },
    fetchSpeaks(data){
        return apiClient.post('/api/speaks',data)
    },
    fetchSpeaksPagination(data,num){
        return apiClient.post('/api/speaks?page='+num,data)
    },
    fetchVCcontent(data){
        return apiClient.get('/api/getwebsitecontent?path='+data)
        // return webcontent.post('/api/com/bra4/test')
        
    },
    getPopularRoomInfo(data){
        return apiClient.post('/api/popularroom',data)
    },
    fetchVoucherViaCode(data){
        return apiClient.post('/api/fetchVoucherViaCode',data)
    }
}