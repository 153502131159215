<template>
  <div>
    <section class="shop spad"  style="padding-top: 150px !important">
        <div class="form-signup">
            <div class="row">
                <!-- <div class="col-md-4">
                    <div class="row"> -->
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">First Name</label> -->
                            <div class="input-group">
                                <span class="input-group-addon">First Name</span>
                                <input type="text" v-model="firstname" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.firstname != null">{{this.changeDescription('firstname',this.error.firstname[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Middle Name</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Middle Name</span>
                                <input type="text" v-model="middlename" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.middlename != null">{{this.changeDescription('middlename',this.error.middlename[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Last Name</label> -->
                            <div class="input-group">
                                <span class="input-group-addon">Last Name</span>
                                <input type="text" v-model="lastname" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.lastname != null">{{this.changeDescription('lastname',this.error.lastname[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Birthday</label> -->
                            <div class="input-group">
                                <span class="input-group-addon">Birthday</span>
                                <input type="text" v-model="birthday" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.birthday != null">{{this.changeDescription('birthday',this.error.birthday[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Email</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Email</span>
                                <input type="text" v-model="email" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.email != null">{{this.changeDescription('email',this.error.email[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Contact Number</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Contact Number</span>
                                <input type="text" v-model="contactnumber" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.contactnumber != null">{{this.changeDescription('contactnumber',this.error.contactnumber[0])}}</p>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="input-group">
                                <span class="input-group-addon">Username</span>
                                <input type="text" v-model="username" class="form-control mod-form">
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Contact Number</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Password</span>
                                <input type="password" v-model="password" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.password != null">{{this.changeDescription('password',this.error.password[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Contact Number</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Confirm Password</span>
                                <input type="password" v-model="confpassword" class="form-control mod-form">
                            </div>
                            <p class="input-required" v-if="error.confirm_password != null">{{this.changeDescription('confirm_password',this.error.confirm_password[0])}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" @click.stop.prevent="this.registerAccount()" style="color: white !important;height: 50px;line-height: 50px;vertical-align: middle;font-size: 20px" target="_blank" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now form-control">Sign Up</a>
                        </div>
                    </div>
                    
                <!-- </div>
            </div> -->
            
        </div>
    </section>
  </div>
</template>

<script>
    import services from '../../service/account_webservice'
    export default {
        name: 'signup_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                company: process.env.VUE_APP_COMPANY_CODE,
                firstname: '',
                middlename: '',
                lastname: '',
                birthday: '',
                email: '',
                contactnumber: '',
                password: '',
                confpassword: '',
                error: [],
                url_host: location.protocol+"//"+location.host,
            }
        },
        created(){
            this.$store.state.header = "account";
        },
        // mounted(){
            
        // }
        methods:{
            changeDescription(xcode,xstring){
                return xstring.replace('The '+xcode,'This')
            },
            registerAccount(){
                this.saveAccount()
            },
            sendConfirmationEmail(){
                var xdata = {
                    company: this.company,
                    email: this.email,
                    url_host: this.url_host
                }
                services.sendconfirmationemail(xdata)
                window.location.href="/account_created"
            },
            saveAccount(){
                this.saveAccountInfo().then(response => {
                    // alert(response)
                    this.sendConfirmationEmail()
                    console.log(response)
                }).catch(error=>{
                    // this.loader_stat = "hidden"
                    console.log(error.response.data.errors)
                    this.error = error.response.data.errors
                })
            },
            saveAccountInfo(){
                var xdata = {
                    company: this.company,
                    firstname: this.firstname,
                    middlename: this.middlename,
                    lastname: this.lastname,
                    birthday: this.birthday,
                    email: this.email,
                    contactnumber: this.contactnumber,
                    password: this.password,
                    confirm_password: this.confpassword,
                }
                return services.saveAccountInfo(xdata)
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .form-signup{
        max-width: 50%;
        /* border: 1px solid black; */
        margin: auto auto;
        padding: 20px;
    }
    .mod-form{
        height: 50px;
        /* border: transparent; */
        border: 1px solid black;
        border-radius: 0;
        outline: 0;
    }
    .input-title{
        font-size: 1.5em;
        font-weight: bold;
    }
    .mod-form:focus{
        box-shadow: none !important;
        outline: 0 !important;
        border: 1px solid black;
        /* border-bottom: 1px solid rgb(255, 229, 80); */
    }
    .form-signup>div{
        padding-bottom: 20px;
    }
    .input-group-addon{
        width: 180px;
        background: transparent;
        border: 1px solid black;
        border-right: 1px solid transparent;
        border-left: 5px solid gold;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    @media screen and (max-width: 480px) {
        .form-signup{
            max-width: 100% !important;
            padding: 15px;
        }
    }
   .input-required{
       color: red;
       font-size: 12px;
       margin: 0;
       text-indent: 30px;
   }
   .padBot{
       padding-top: 10px;
       /* padding-bottom: 10px; */
   }
</style>
