<template>
  <div>
    <section class="spad"  style="padding-top: 150px !important">
        <div class="row">
            <div class="col-md-12">
                <section class="tabs-section text-white">
                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-sm-5 col-lg-3">
                            <ul class="nav nav-tabs flex-column mb-3">
                                <li class="nav-item">
                                    <a class="nav-link active show" data-toggle="tab" href="#accountInformation">Account Information</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#reservation">Reservation</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#mystyles">Victoria Styles</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#vouchers">Vouchers</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="col-sm-7 col-lg-9">
                            <div class="tab-content">
                                <div class="tab-pane active show" id="accountInformation">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-3.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <!-- <h3 class="mt-3">What is Lorem Ipsum?</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p> -->
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">First Name</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Middle Name</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Last Name</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Birthday</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Contact Number</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Email</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Last Name</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">Birthday</span>
                                                                <input type="text" v-model="firstname" class="form-control mod-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="reservation">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-2.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <!-- <h3 class="mt-3">Why do we use it?</h3>
                                            <p class="font-italic">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p> -->
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Transaction Number</th>
                                                        <th>Check-in Date</th>
                                                        <th>Room</th>
                                                        <th>Price</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="mystyles">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-1.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <!-- <h3 class="mt-3">Why do we use it?</h3>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum</p> -->
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Transaction Number</th>
                                                        <th>Deliver Date</th>
                                                        <th>Room</th>
                                                        <th>Price</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="vouchers">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-3.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <!-- <h3 class="mt-3">Where does it come from?</h3>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p> -->
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Transaction Number</th>
                                                        <th>Check-in Date</th>
                                                        <th>Room</th>
                                                        <th>Price</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>0001</td>
                                                        <td>July 27, 2022</td>
                                                        <td>301</td>
                                                        <td>1,500</td>
                                                        <td>
                                                            <div class="btn-group btn-group-justified" role="group">
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-info"><i class="fa fa-eye"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-warning"><i class="fa fa-edit"></i></button>
                                                                </div>
                                                                <div class="btn-group" role="group">
                                                                    <button type="button" onclick="alert()" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-5">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-2.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <h3 class="mt-3">Where can I get some?</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-6">
                                    <div class="row">
                                        <!-- <div class="col-lg-4">
                                            <div class="feature-img">
                                                <img src="images/user-img-1.jpg" alt="" class="img-fluid">
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 details">
                                            <h3 class="mt-3">Why do we use it?</h3>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
    import services from '../../service/account_webservice'
    // import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
    export default {
        name: 'myaccount_n',
        // components: {VueSidebarMenuAkahon},
        // props: {
        //   msg: String
        // },
        data(){
            return {
                
            }
        },
        created(){
            this.$store.state.header = "account";
        },
        // mounted(){
            
        // }
        methods:{
            // 
            Login(){
                this.LoginAccount().then(response => {
                    // alert(response)
                    // this.sendConfirmationEmail()
                    console.log(response.data)
                    if( response.data != 0 ){
                        // alert('log');
                        window.location.href = "/myaccount"
                    } else {
                        alert('Wrong Credentials');
                    }
                })
            },
            LoginAccount(){
                var xdata = {
                    company: this.company,
                    email: this.email,
                    password: this.password,
                }
                // alert()
                return services.LoginAccount(xdata)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tabs-section {
    overflow: hidden;
    /* background-color: #333; */
    padding: 60px 0px;
}
.tabs-section .feature-img {
    max-height: 255px;
    overflow: hidden;
    border-radius: 10px;
    border: 3px solid rgb(0, 0, 0);
}
.tabs-section .nav-tabs {
    border: 0;
}
.tabs-section .nav-link {
    border: 0;
    padding: 11px 15px;
    transition: 0.3s;
    color: rgb(0, 0, 0);
    border-radius: 0;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    height: 100%;
    line-height: 60px;
    border-right: 2px solid #000000 !important;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
}
.tabs-section .nav-link:hover {
    color: #fff;
    background: #000;
    /* background: #cddc39; */
}
.tabs-section .nav-link.active {
    /* color: #000000; */
    color: #fff;
    background: #000;
    /* background: #cddc39; */
}
.tabs-section .nav-link:hover {
    border-right: 4px solid #000000;
}
.tabs-section .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}
.tabs-section .details h3 {
    font-size: 26px;
    color: gold;
}
.tabs-section .details p {
    color: #aaaaaa;
}
.feature-img{
    display: none;
}


.mod-form{
    height: 50px;
    /* border: transparent; */
    border: 1px solid black;
    border-radius: 0;
    outline: 0;
}
.input-title{
    font-size: 1.5em;
    font-weight: bold;
}
.mod-form:focus{
    box-shadow: none !important;
    outline: 0 !important;
    border: 1px solid black;
    /* border-bottom: 1px solid rgb(255, 229, 80); */
}
.form-signup>div{
    padding-bottom: 20px;
}
.input-group-addon{
    width: 180px;
    background: transparent;
    border: 1px solid black;
    border-right: 1px solid transparent;
    border-left: 5px solid gold;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: black;
}
.row{
    margin-bottom: 10px;
}
</style>
