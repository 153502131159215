<template>
  <div>

    <!-- Breadcrumb Section Begin -->
      <section v-if="COVER.image != undefined" class="breadcrumb_area">
          <div class="overlay bg-parallax" v-bind:style="{ 'background-image': 'url(' + COVER.image + ') !important' }" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Host</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
      <section v-else class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Host</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
    <!-- Breadcrumb Section End -->
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Hillcrest</h2>
                <p v-if="HC.description != undefined" v-html="HC.description"></p>
                <p v-else >A romantic date for two? Check. Party Room with family and friends? Check. Short-time or overnight staycation? Check. All these and more at Victoria Court Hillcrest. Located at Bagong Ilog, Pasig City, this 65-room property is the best place for your stay! You can enjoy and relax after shopping near malls like Estancia Capitol Commons, Edsa Shangri-la, SM Megamall, The Podium and Robinsons Galleria from one side and SM Aura and BGC on the other side. You will surely have a great time with our topnotch service and sumptuous food at the comfort of your uniquely-designed room at Victoria Court Hillcrest.</p>
                <a href="/hillcrest" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="HC.image != undefined" class="img-fluid" :src="HC.image" alt="img"> -->
              <div v-if="HC.image != undefined" class="vc_option" v-html="HC.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center xorder-2">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Panorama</h2>
                <p v-if="PA.description != undefined" v-html="PA.description"></p>
                <p v-else>Can't get enough of Victoria Court Hillcrest? Here comes Victoria Court Panorama. Boasting 96 rooms, this property is the best place for dates and parties! You can experience being a US President with our Oval Room or channel your inner Parisian with our Moulin Rouge thematic rooms while singing your heart out with videoke in our thematic rooms and fill your belly with the best lutong-bahay from Victoria Bites that you can order straight from your room. </p>
                <a href="panorama" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="PA.image != undefined" class="img-fluid" :src="PA.image" alt="img"> -->
              <div v-if="PA.image != undefined" class="vc_option" v-html="PA.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->

      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Las Piñas</h2>
                <p v-if="LP.description != undefined" v-html="LP.description"></p>
                <p v-else >Victoria Court Las Piñas is a hidden gem when it comes to unparalleled drive-in motel service. Located at Admiral Road, Talon III, Las Piñas, this property houses 74 rooms of different interiors that will spark curiosity and delight your senses. Whether you bring your SUV for a night-long stay or just go with your motorcycle for a quick getaway, you will surely enjoy your stay here!</p>
                <a href="/laspinas" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="LP.image != undefined" class="img-fluid" :src="LP.image" alt="img"> -->
              <div v-if="LP.image != undefined" class="vc_option" v-html="LP.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center xorder-2">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court North Edsa</h2>
                <p v-if="NE.description != undefined" v-html="NE.description"></p>
                <p v-else >As busy as the location it may seem - you can relax, have fun and find total bliss at Victoria Court North Edsa. With just a strut away from EDSA-Caloocan, this 136-room property situated along Don Vicente Ang Street, Bagong Barrio, Caloocan City offers a variety of one-of-a-kind rooms from standard room with unique designs up to bigger thematic rooms with in-room family entertainment that you and your friends can enjoy! </p>
                <a href="/northedsa" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="NE.image != undefined" class="img-fluid" :src="NE.image" alt="img"> -->
              <div v-if="NE.image != undefined" class="vc_option" v-html="NE.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Balintawak</h2>
                <p v-if="BA.description != undefined" v-html="BA.description"></p>
                <p v-else >Adjacent to Victoria Court North Edsa, this 93-room property along Don Vicente Ang Street, Bagong Barrio, Caloocan City offers the same great experience and food as with other branches. You'll never run out of room options for your enjoyment and pleasure.</p>
                <a href="/balintawak" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="BA.image != undefined" class="img-fluid" :src="BA.image" alt="img"> -->
              <div v-if="BA.image != undefined" class="vc_option" v-html="BA.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <!-- <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Cuneta</h2>
                <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                <a href="/cuneta" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section> -->
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <!-- <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Gil Puyat</h2>
                <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                <a href="/gilpuyat" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section> -->
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center xorder-2">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Malabon</h2>
                <p v-if="MA.description != undefined" v-html="MA.description"></p>
                <p v-else>Experience premium stay in one-of-a-kind rooms at Victoria Court Malabon! This property of 54 rooms has uniquely-designed rooms that are quite a joy to experience with someone special to you in creating memorable moments. Enjoy sumptuous food as well 24/7 with Victoria Bites with just a click of the phone inside your room.</p>
                <a href="/malabon" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="MA.image" class="img-fluid" :src="MA.image" alt="img"> -->
              <div v-if="MA.image != undefined" class="vc_option" v-html="MA.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <!-- <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content ">
                <h2 class="title title_color">Victoria Court Malate</h2>
                <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                <a href="/malate" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section> -->
      <!--================ About History Area  =================-->
      
      <!--================ About History Area  =================-->
      <section class="about_history_area section_gap">
        <div class="container">
          <div class="row locale_container">
            <div class="horizontal-line"></div>
            <div class="col-md-6 d_flex align-items-center">
              <div class="about_content">
                <h2 class="title title_color">Victoria Court San Fernando</h2>
                <p v-if="SF.description != undefined" v-html="SF.description"></p>
                <p v-else>Nestled at the heart of Pampanga's capital city, Victoria Court San Fernando houses a total number of 42 rooms of fully air conditioned rooms, all with parking for 4-wheeled vehicles and motorcycles alike wherein customers can experience an outstanding customer service, great food and most of all, your privacy is guaranteed. </p>
                <a href="/sanfernando" class="button_hover theme_btn_two">Check Location</a>
              </div>
            </div>
            <div class="col-md-6">
              <!-- <img v-if="SF.image" class="img-fluid" :src="SF.image" alt="img"> -->
              <div v-if="SF.image != undefined" class="vc_option" v-html="SF.image"></div>
              <img v-else class="img-fluid" src="../../assets/img/host/home/facade.jpg" alt="img">
            </div>
          </div>
        </div>
      </section>
      <!--================ About History Area  =================-->
	
  </div>
</template>

<script>
import services from '../../service/webservice'
export default {
  name: 'home_n',
  // props: {
  //   msg: String
  // },
  data(){
    return {
      company: process.env.VUE_APP_COMPANY_CODE,
      // -- WEB CONTENT
      webcontent: [], 
      COVER: [],
      HC: [],
      PA: [],
      LP: [],
      NE: [],
      BA: [],
      MA: [],
      SF: [],
    }
  },
  created(){
    this.$store.state.header = "locale";
    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    this.windowResizing()
    window.addEventListener("resize", this.windowResizing);
  },
  // mounted(){
    
  // }
  methods:{
    windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
        if (screenWidthCheck > 480) {
        this.currentView = 'web'
        } else {
        this.currentView = 'mobile'
        }
        this.displayContent()
    },
    contentvariableReset(){
        // -- FEATURES
        this.COVER = []
        this.HC = []
        this.PA = []
        this.LP = []
        this.NE = []
        this.BA = []
        this.MA = []
        this.SF = []
    },
    imageVideoSet(path){
      var ext = path.split('.')
      var img = ""
      var arr = [
            "jpg","jpeg","jpe","jif","jfif","jfi",
            "png",
            "gif",
            "webp",
            "tiff","tif",
            "psd",
            "raw","arw","cr2","nrw","k25",
            "bmp","dib",
            "heif","heic",
            "ind","indd","indt",
            "jp2","j2k","jpf","jpx","jpm","mj2",
            "svg","svgz",
            "ai",
            "eps",
            "pdf"
          ];
      if( arr.indexOf(ext.slice(-1)[0]) != -1 ){
        img = `
          <img style="width: 100%" src="`+path+`"/>
        `;
      } else {
        img = `
          <video style="width:100%" id="background-video" autoplay loop muted>
              <source src="`+path+`" type="video/mp4">
            </video>
        `;
      }
      return img;
    },
    displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > process.env.VUE_APP_MOBILE_SIZE ){
          // -- WEB
          // -- HISTORY
          // -- COVER
          if( this.webcontent['web|host|cover'] ){
              this.COVER.image = this.webcontent['web|host|cover']['image']
          }
          // -- HILLCREST
          if( this.webcontent['web|host|locale|hc'] ){
              this.HC.description = this.webcontent['web|host|locale|hc']['description']
              this.HC.image = this.imageVideoSet(this.webcontent['web|host|locale|hc']['image'])
              this.HC.path = this.webcontent['web|host|locale|hc']['image']
          }
          // -- PANORAMA
          if( this.webcontent['web|host|locale|pa'] ){
              this.PA.description = this.webcontent['web|host|locale|pa']['description']
              this.PA.image = this.imageVideoSet(this.webcontent['web|host|locale|pa']['image'])
              this.PA.path = this.webcontent['web|host|locale|pa']['image']
          }
          // -- LASPINAS
          if( this.webcontent['web|host|locale|lp'] ){
              this.LP.description = this.webcontent['web|host|locale|lp']['description']
              this.LP.image = this.imageVideoSet(this.webcontent['web|host|locale|lp']['image'])
              this.LP.path = this.webcontent['web|host|locale|lp']['image']
          }
          // -- NORTH EDSA
          if( this.webcontent['web|host|locale|ne'] ){
              this.NE.description = this.webcontent['web|host|locale|ne']['description']
              this.NE.image = this.imageVideoSet(this.webcontent['web|host|locale|ne']['image'])
              this.NE.path = this.webcontent['web|host|locale|ne']['image']
          }
          // -- BALINTAWAK
          if( this.webcontent['web|host|locale|ba'] ){
              this.BA.description = this.webcontent['web|host|locale|ba']['description']
              this.BA.image = this.imageVideoSet(this.webcontent['web|host|locale|ba']['image'])
              this.BA.path = this.webcontent['web|host|locale|ba']['image']
          }
          // -- MALABON
          if( this.webcontent['web|host|locale|ma'] ){
              this.MA.description = this.webcontent['web|host|locale|ma']['description']
              this.MA.image = this.imageVideoSet(this.webcontent['web|host|locale|ma']['image'])
              this.MA.path = this.webcontent['web|host|locale|ma']['image']
          }
          // -- SAN FERNANDO
          if( this.webcontent['web|host|locale|sf'] ){
              this.SF.description = this.webcontent['web|host|locale|sf']['description']
              this.SF.image = this.imageVideoSet(this.webcontent['web|host|locale|sf']['image'])
              this.SF.path = this.webcontent['web|host|locale|sf']['image']
          }
        } else {
          // -- MOBILE
          // -- COVER
          if( this.webcontent['mobile|host|cover'] ){
              this.COVER.image = this.webcontent['mobile|host|cover']['image']
          }
          // -- HILLCREST
          if( this.webcontent['mobile|host|locale|hc'] ){
              this.HC.description = this.webcontent['mobile|host|locale|hc']['description']
              this.HC.image = this.imageVideoSet(this.webcontent['mobile|host|locale|hc']['image'])
              this.HC.path = this.webcontent['mobile|host|locale|hc']['image']
          }
          // -- PANORAMA
          if( this.webcontent['mobile|host|locale|pa'] ){
              this.PA.description = this.webcontent['mobile|host|locale|pa']['description']
              this.PA.image = this.imageVideoSet(this.webcontent['mobile|host|locale|pa']['image'])
              this.PA.path = this.webcontent['mobile|host|locale|pa']['image']
          }
          // -- LASPINAS
          if( this.webcontent['mobile|host|locale|lp'] ){
              this.LP.description = this.webcontent['mobile|host|locale|lp']['description']
              this.LP.image = this.imageVideoSet(this.webcontent['mobile|host|locale|lp']['image'])
              this.LP.path = this.webcontent['mobile|host|locale|lp']['image']
          }
          // -- NORTH EDSA
          if( this.webcontent['mobile|host|locale|ne'] ){
              this.NE.description = this.webcontent['mobile|host|locale|ne']['description']
              this.NE.image = this.imageVideoSet(this.webcontent['mobile|host|locale|ne']['image'])
              this.NE.path = this.webcontent['mobile|host|locale|ne']['image']
          }
          // -- BALINTAWAK
          if( this.webcontent['mobile|host|locale|ba'] ){
              this.BA.description = this.webcontent['mobile|host|locale|ba']['description']
              this.BA.image = this.imageVideoSet(this.webcontent['mobile|host|locale|ba']['image'])
              this.BA.path = this.webcontent['mobile|host|locale|ba']['image']
          }
          // -- MALABON
          if( this.webcontent['mobile|host|locale|ma'] ){
              this.MA.description = this.webcontent['mobile|host|locale|ma']['description']
              this.MA.image = this.imageVideoSet(this.webcontent['mobile|host|locale|ma']['image'])
              this.MA.path = this.webcontent['mobile|host|locale|ma']['image']
          }
          // -- SAN FERNANDO
          if( this.webcontent['mobile|host|locale|sf'] ){
              this.SF.description = this.webcontent['mobile|host|locale|sf']['description']
              this.SF.image = this.imageVideoSet(this.webcontent['mobile|host|locale|sf']['image'])
              this.SF.path = this.webcontent['mobile|host|locale|sf']['image']
          }
        }
    },
    getVCContent($url){
        this.xgetVCContent($url).then(response => {
        // console.log(response)
        this.webcontent = response.data
        // console.log(this.webcontent['web|home|vcFeatures|types|host'])
        this.displayContent()
        })
    },
    xgetVCContent($url){
        return services.fetchVCcontent($url)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .locale_container{
    position: relative;
  }
  .horizontal-line{
    display: none;
    position: absolute;
    background: red;
    width: 70%;
    height: 10%;
    bottom: 50px;
    right: 0px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .breadcrumb_area .bg-parallax {
    background-image: url('../../assets/img/host/home/vc-host-header.jpg')!important;
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;
    background-position: center !important;
    background-size: cover !important;
  }
  @media screen and (min-width: 800px) {
    .xorder-2{
      order: 2
    }
  }
</style>
