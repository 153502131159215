<template>
  <div>

    <!-- Breadcrumb Section Begin -->
      <section v-if="COVER.image != undefined" class="breadcrumb_area">
          <div class="overlay bg-parallax" v-bind:style="{ 'background-image': 'url(' + COVER.image + ') !important' }" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
      <section v-else class="breadcrumb_area">
          <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
          <div class="container">
              <div class="page-cover text-center">
                  <h2 class="page-cover-tittle">Victoria Styles</h2>
                  <!-- <ol class="breadcrumb">
                      <li><a href="index.html">Home</a></li>
                      <li class="active">Accomodation</li>
                  </ol> -->
              </div>
          </div>
      </section>
    <!-- Breadcrumb Section End -->
    <!-- Shop Section Begin -->
    <section class="shop spad" id="product_list">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <h1 class="div-title">Order Details</h1>
                    <div class="cart-items" v-for="OI in this.$store.state.cart" :key="OI.product_id">
                        <div class="row">
                            
                            <div class="col-md-2 mobile-reset-padding" style="padding-right: 0">
                                <!-- this.$store.state.stylesImageURL -->
                                <img :src="`${this.$store.state.stylesImageURL}`+`/`+`${OI.product.image_file}`"  style="width: 100%" alt="">
                            </div>

                            <div class="col-md-4 cart-border-left" style=";display: inline-grid;text-align: center;">
                                <div class="col-md-12 cart-align-middle" style="height: 100%; margin: 0 !important">
                                    <h5 style="margin: 0 auto !important;">{{OI.product.product}}</h5>
                                </div>
                                <div class="col-md-12 cart-align-middle" style="margin: auto 0; border-top: 1px solid  rgba(197, 194, 194, 0.925);text-align: center">
                                    <span style="margin: 0 auto !important;">&#8369; {{this.formatter(OI.product.fetch_company_product_price_byid.amount)}}</span>
                                </div>
                            </div>
                            
                            <div class=" cart-border-left col-md-2" style=";">
                                <div class="cart-align-middle">
                                    <!-- <div class="input-group"> -->
                                        <!-- <span class="input-group-addon" style="font-size: 10px; padding: 7px;border-radius: initial;"><i class="fa fa-plus"></i></span> -->
                                        <input readonly type="number" min="0" class="form-control form-control-sm" @change="updateLocalStorageOrder()" v-model="OI.quantity" style="text-align: center;">
                                        <!-- <span class="input-group-addon" style="font-size: 10px; padding: 7px;border-radius: initial;"><i class="fa fa-minus"></i></span> -->
                                    <!-- </div> -->
                                </div>
                            </div>

                            <div class=" cart-border-left col-md-2 ">
                                <div class="cart-align-middle">
                                    <h5 style="margin: 0 auto !important;">&#8369; {{this.formatter(OI.product.fetch_company_product_price_byid.amount * OI.quantity)}}</h5>
                                </div>
                            </div>

                            <div class=" cart-border-left mobile-reset-padding col-md-2" style=";padding-left: 0">
                            <div class="cart-align-middle">
                                <span @click="removeItem(OI.product_id)" style="margin: 0 auto !important;"><u>Remove</u></span>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    <br><br>
                    <div class="row">
                        <div class="offset-md-6 col-md-6">
                            <table class="table table-bordered">
                                <tr>
                                    <th>Sub-Total</th>
                                    <td>&#8369; {{this.formatter(this.$store.state.cartSubTotal)}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <h1 class="div-title">Personal Information</h1>
                    <div class="settlement-container">
                        <div class="settlement_form">
                            <div class="row">
                                <div class="col-md-12 padBot">
                                    <label>First Name</label>
                                    <input type="text" v-model="firstname" class="form-control">
                                    <p class="input-required" v-if="error.firstname != null">{{this.changeDescription('firstname',this.error.firstname[0])}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 padBot">
                                    <label>Last Name</label>
                                    <input type="text" v-model="lastname" class="form-control">
                                    <p class="input-required" v-if="error.lastname != null">{{this.changeDescription('lastname',this.error.lastname[0])}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 padBot">
                                    <label>Contact Number</label>
                                    <input class="form-control" v-maska="'###########'" v-model="contactnumber">
                                    <p class="input-required" v-if="error.contactnumber != null">{{this.changeDescription('contactnumber',this.error.contactnumber[0])}}</p>
                                    <!-- <input type="text" v-model="contactnumber" class="form-control"> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 padBot">
                                    <label>Email</label>
                                    <input type="text" v-model="email" class="form-control">
                                    <p class="input-required" v-if="error.email != null">{{this.changeDescription('email',this.error.email[0])}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
            <div class="row">
                <div class="col-md-7">

                    <h1 class="div-title">Shipping Information</h1>
                    <div class="settlement-container">
                        <div class="settlement_form">
                            <div class="row">
                                <div class="col-md-6 padBot">
                                    <label>Blk Nos./Street Name</label>
                                    <input type="text" v-model="streetname" @keyup="concatAddress()" class="form-control">
                                    <p class="input-required" v-if="error.streetname != null">{{this.changeDescription('streetname',this.error.streetname[0])}}</p>
                                </div>
                                <div class="col-md-6 padBot">
                                    <label>Compound/Subdivision</label>
                                    <input type="text" v-model="compound" @keyup="concatAddress()"  class="form-control">
                                    <p class="input-required" v-if="error.compound != null">{{this.changeDescription('compound',this.error.compound[0])}}</p>
                                </div>
                            </div>
                            <div class="row">
                            </div>
                            <div class="row">
                                <div class="col-md-6 padBot">
                                    <label>Baranggay</label>
                                    <input type="text" v-model="baranggay" @keyup="concatAddress()"  class="form-control">
                                    <p class="input-required" v-if="error.baranggay != null">{{this.changeDescription('baranggay',this.error.baranggay[0])}}</p>
                                </div>
                                <div class="col-md-6 padBot">
                                    <label>Municipality</label>
                                    <input type="text" v-model="municipality" @keyup="concatAddress()"  class="form-control">
                                    <p class="input-required" v-if="error.municipality != null">{{this.changeDescription('municipality',this.error.municipality[0])}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 padBot">
                                    <label>Province</label>
                                    <input type="text" v-model="province" @keyup="concatAddress()"  class="form-control">
                                    <p class="input-required" v-if="error.province != null">{{this.changeDescription('province',this.error.province[0])}}</p>
                                </div>
                                <div class="col-md-6 padBot">
                                    <label>Region</label>
                                    <input type="text" v-model="region" @keyup="concatAddress()"  class="form-control">
                                    <p class="input-required" v-if="error.region != null">{{this.changeDescription('region',this.error.region[0])}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 padBot">
                                    <label>Complete Address</label>
                                    <input type="text" v-model="completeaddress" class="form-control">
                                    <p class="input-required" v-if="error.completeaddress != null">{{this.changeDescription('completeaddress',this.error.completeaddress[0])}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <h1 class="div-title">Payment</h1>
                    <div class="settlement-container">
                        <div class="settlement_form">
                            <div class="row">
                            <div class="col-md-12">
                                <!-- <label>Mode of Payment</label> -->
                                <div class="accordion" id="accordionExample">
                                    <div class="card">
                                        <div class="card-header" id="wallet-head" data-toggle="collapse" data-target="#wallet-payment" aria-expanded="true" aria-controls="wallet-payment">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link" type="button">
                                            Wallet
                                            </button>
                                        </h2>
                                        </div>
                                        <div id="wallet-payment" class="collapse show" aria-labelledby="wallet-head" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" checked id="pm-gc" @change="selPMethod('pm-gc')" value="wallet|gc"> GCash
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-wechat" @change="selPMethod('pm-wechat')" value="wallet|wechat"> WeChat
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header collapsed" id="debit-head" data-toggle="collapse" data-target="#debit-payment" aria-expanded="false" aria-controls="debit-payment">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link" type="button">
                                            Debit
                                            </button>
                                        </h2>
                                        </div>
                                        <div id="debit-payment" class="collapse" aria-labelledby="debit-head" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="row">
                                            <!-- <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-bn" @change="selPMethod('pm-bn')" value="onlinebanktransfer|bn"> Bancnet Philippines
                                                </label>
                                            </div> -->
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-bpi_ph" @change="selPMethod('pm-bpi_ph')" value="onlinebanktransfer|bpi_ph"> Bank of the Philippine Island
                                                </label>
                                            </div>
                                            <!-- <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-ubponline_ph" @change="selPMethod('pm-ubponline_ph')" value="onlinebanktransfer|ubponline_ph"> Union Bank of the Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-pnbonline_ph" @change="selPMethod('pm-pnbonline_ph')" value="onlinebanktransfer|pnbonline_ph"> Philippine National Bank
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-instapay_qr_ph" @change="selPMethod('pm-instapay_qr_ph')" value="onlinebanktransfer|instapay_qr_ph"> All Philippine banks that is supported by Instapay QR or QR PH
                                                </label>
                                            </div> -->
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-br_bdo_ph" @change="selPMethod('pm-br_bdo_ph')" value="onlinebanktransfer|br_bdo_ph"> BDO Online Bank Transfer (via Brankas)
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-br_rcbc_ph" @change="selPMethod('pm-br_rcbc_ph')" value="onlinebanktransfer|br_rcbc_ph"> RCBC Online Bank Transfer (via Brankas)
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-br_pnb_ph" @change="selPMethod('pm-br_pnb_ph')" value="onlinebanktransfer|br_pnb_ph"> PNB Online Bank Transfer (via Brankas)
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header collapsed" id="otc-head" data-toggle="collapse" data-target="#otc-payment" aria-expanded="false" aria-controls="otc-payment">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link" type="button">
                                            OTC Payment
                                            </button>
                                        </h2>
                                        </div>
                                        <div id="otc-payment" class="collapse" aria-labelledby="otc-head" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-bayadcenter_ph" @change="selPMethod('pm-bayadcenter_ph')" value="nonbank_otc|bayadcenter_ph"> Bayad Center Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-711_ph" @change="selPMethod('pm-711_ph')" value="nonbank_otc|711_ph"> 711 Network Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-cliqq_ph" @change="selPMethod('pm-cliqq_ph')" value="nonbank_otc|cliqq_ph"> 711 Cliqq Network Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-mlhuillier_ph" @change="selPMethod('pm-mlhuillier_ph')" value="nonbank_otc|mlhuillier_ph"> Mlhuillier Pawnshop Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-cebuana_ph" @change="selPMethod('pm-cebuana_ph')" value="nonbank_otc|cebuana_ph"> Cebuana Pawnshop Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-ecpay_ph" @change="selPMethod('pm-ecpay_ph')" value="nonbank_otc|ecpay_ph"> Ecpay Network Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-da5_ph" @change="selPMethod('pm-da5_ph')" value="nonbank_otc|da5_ph"> Direct Agents 5 Network Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-expresspay_ph" @change="selPMethod('pm-expresspay_ph')" value="nonbank_otc|expresspay_ph"> Expresspay Network Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-dp_ph" @change="selPMethod('pm-dp_ph')" value="nonbank_otc|dp_ph"> DragonPay Philippines
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-smbills_ph" @change="selPMethod('pm-smbills_ph')" value="nonbank_otc|smbills_ph"> SM Bills Payment Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-truemoney_ph" @change="selPMethod('pm-truemoney_ph')" value="nonbank_otc|truemoney_ph"> True Money Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-posible_ph" @change="selPMethod('pm-posible_ph')" value="nonbank_otc|posible_ph"> Posible.net Network
                                                </label>
                                            </div>
                                            <div class="col-md-12 pmethod">
                                                <label>
                                                <input type="checkbox" class="chk-paymentMethod" id="pm-etap_ph" @change="selPMethod('pm-etap_ph')" value="nonbank_otc|etap_ph"> Etap Network
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- <div class="row">
                            <div class="col-md-12">
                                <label>Mode of Payment</label>
                                <select v-model="modepayment" name="" class="form-control" id="">
                                <option value="onlinebanktransfer|bpi_ph">Bank of the Philippine Island</option>
                                <option value="onlinebanktransfer|br_bdo_ph">BDO Online Bank Transfer (via Brankas)</option>
                                <option value="onlinebanktransfer|br_rcbc_ph">RCBC Online Bank Transfer (via Brankas)</option>
                                <option value="onlinebanktransfer|br_pnb_ph">PNB Online Bank Transfer (via Brankas)</option>
                                <option value="wallet|gc">GCash</option>
                                <option value="wallet|wechat">WeChat</option>
                                <option value="nonbank_otc|bayadcenter_ph">Bayad Center Network</option>
                                <option value="nonbank_otc|711_ph">711 Network Philippines</option>
                                <option value="nonbank_otc|cliqq_ph">711 Cliqq Network Philippines</option>
                                <option value="nonbank_otc|mlhuillier_ph">Mlhuillier Pawnshop Network</option>
                                <option value="nonbank_otc|cebuana_ph">Cebuana Pawnshop Network</option>
                                <option value="nonbank_otc|ecpay_ph">Ecpay Network Philippines</option>
                                <option value="nonbank_otc|da5_ph">Direct Agents 5 Network Philippines</option>
                                <option value="nonbank_otc|expresspay_ph">Expresspay Network Philippines</option>
                                <option value="nonbank_otc|dp_ph">DragonPay Philippines</option>
                                <option value="nonbank_otc|smbills_ph">SM Bills Payment Network</option>
                                <option value="nonbank_otc|truemoney_ph">True Money Network</option>
                                <option value="nonbank_otc|posible_ph">Posible.net Network</option>
                                <option value="nonbank_otc|etap_ph">Etap Network</option>
                                </select>
                            </div>
                            </div> -->
                            <br>
                            <div class="row">
                            <div class="col-md-12">
                                <a href="#" @click.stop.prevent="continueToPay()" class="button_hover theme_btn_two form-control">Continue to pay</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Shop Section End -->

    <div :class="loader_stat" class="loader-container">
      <img src="../../assets/loading.gif" alt="">
    </div>
  </div>
  
</template>

<script>
// import vueStickySidebar from "vue-sticky-sidebar";
import services from '../../service/webservice'
// import Paginate from 'vuejs-paginate-next';
// var InputMask = require('inputmask-core')
import { maska } from 'maska'
export default {
  name: 'styles_n',
  // props: {
  //   msg: String
  // },,
  components: {
  //   "vue-sticky-sidebar": vueStickySidebar
    //   paginate: Paginate,
        // maska
  },
  directives: { maska },
  data(){
    return {
        loader_stat: 'hidden',
        company: process.env.VUE_APP_COMPANY_CODE,
        foodloading: 0,
        foodArr: [],
        foodImgSrc: "",
        prodisplay: {0: "hide", 1: "show"},

        currentpage: 1,
        pagecnt: 0,
        currentView: 'web',

        firstname: "",
        lastname: "",
        contactnumber: "",
        email: "",

        streetname: "",
        compound: "",
        baranggay: "",
        municipality: "",
        province: "",
        region: "",
        completeaddress: "",

        modepayment: "",


        error: [],

        // -- WEB CONTENT
        COVER: [],
        webcontent: [], 
    }
  },
  created(){
    this.$store.state.header = "styles";
    // this.$store.getters.fetchCart

    this.getProducts()
    this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')
    this.windowResizing()
    window.addEventListener("resize", this.windowResizing);
    // alert();
  },
  mounted(){
      this.getModepayment('pm-gc')
  },
  methods:{

    windowResizing(){
        // alert()
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(screenWidthCheck)
        if (screenWidthCheck > 480) {
        this.currentView = 'web'
        } else {
        this.currentView = 'mobile'
        }
        this.displayContent()
    },
    contentvariableReset(){
        // -- FEATURES
        this.COVER = []
    },
    displayContent(){
        this.contentvariableReset()
        // RESET VARIABLE
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // alert(width)
        if( width > 480 ){
            // -- WEB
            // -- COVER
            if( this.webcontent['web|styles|store|cover'] ){
                this.COVER.image = this.webcontent['web|styles|store|cover']['image']
            }
        } else {
            // -- MOBILE
            // -- COVER
            if( this.webcontent['mobile|styles|store|cover'] ){
                this.COVER.image = this.webcontent['mobile|styles|store|cover']['image']
            }
        }
    },
    getVCContent($url){
        this.xgetVCContent($url).then(response => {
        // console.log(response)
        this.webcontent = response.data
        // console.log(this.webcontent['web|home|vcFeatures|types|host'])
        this.displayContent()
        })
    },
    xgetVCContent($url){
        return services.fetchVCcontent($url)
    },
    changeDescription(xcode,xstring){
        return xstring.replace('The '+xcode,'This')
    },
    concatAddress(){
        this.completeaddress = this.streetname+" "+this.compound+" "+this.baranggay+" "+this.municipality+" "+this.province+" "+this.region
    },
    formatter(price){
        return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    getModepayment(checkID){
        this.modepayment = document.querySelector('#'+checkID).value;
    },
    updateLocalStorageOrder(){
      this.$store.state.cart.forEach(function(value){
        if( value['quantity'] == '' ){
          value['quantity'] = 0
        }
      });
      localStorage.setItem('myCart',JSON.stringify(this.$store.state.cart))
      this.$store.getters.fetchCart
    },
    showCart(){
      // alert('sokbu')
    },
    removeItem(prodID){
      this.$swal(
        '&nbsp;',
        'Do you want to cancel this order?'
      ).then((result)=>{
        if (result.isConfirmed) {

          var cart = []
          this.$store.state.cart.forEach(function (value){
            // console.log(key)
            if( value['product_id'] != prodID ){
              cart.push(value)
            }
          });
          localStorage.setItem('myCart',JSON.stringify(cart))
          this.$store.getters.fetchCart
          // this.$swal('', '', 'success')
          // console.log(result)
        } 
        // else if (result.isDenied) {
        //   // this.$swal('Changes are not saved', '', 'info')
        // }
      });

    },
    addToCart(objt){
        var cart = this.$store.getters.fetchCart;
        // (this.$store.getters.fetchCart)
        var isAdd = 1;
        cart.forEach(function(value,key){
            console.log(key)
            if( objt['id'] == value['product_id'] ){
                cart[key]['quantity'] = parseInt(cart[key]['quantity'])+1
                isAdd = 0;
            }
        });

        var xxarr = []
        if( isAdd == 1 ){
            xxarr= {
                "product_id": objt['id'],
                "product": objt,
                "quantity": 1
            }
            cart.push(xxarr)
        }

        // var xarr = xxarr
        this.$store.state.cart = cart
        localStorage.setItem('myCart', JSON.stringify(cart))
        this.$store.getters.fetchCart
        // alert(JSON.stringify(cart))
        // alert(JSON.stringify(objt['id']))
    },
    getCart(){
        
    },
      clickCallback (pageNum){
        // alert(pageNum)
        // this.currentpage = pageNum
        this.getProducts(pageNum)
        const myEl = document.getElementById('product_list')

        this.$smoothScroll({
            scrollTo: myEl, // scrollTo is also allowed to be number
        // hash: '#sampleHash' // required if updateHistory is true
        })
      },
      getProducts(num){
        this.foodloading = 0
        if( num != '' ){
            this.fetchProductsPaginate(num).then(response => {
                this.foodloading = 1
                this.foodArr = response.data.list
                this.foodImgSrc = response.data.motelIMG    
                this.$store.state.stylesImageURL = response.data.motelIMG   
                // alert(response.data.list.last_page)
                this.pagecnt = response.data.list.last_page
            })
        } else {
            this.fetchProducts().then(response => {
                this.foodloading = 1
                this.foodArr = response.data.list
                this.foodImgSrc = response.data.motelIMG    
                this.$store.state.stylesImageURL = response.data.motelIMG   
                // alert(response.data.list.last_page)
                this.pagecnt = response.data.list.last_page
            })
        }
      },
      fetchProducts(){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: "",
          pagination: 12
        }
        return services.fetchProducts(xdata)
      },
      fetchProductsPaginate(num){
        var xdata = {
          company: this.company,
        //   branch: this.branch,
          filter: "",
          pagination: 12
        }
        return services.fetchProductsPaginate(xdata,num)
      },
      async continueToPay(){
        this.loader_stat = "showed"
        // await this.roomAvailabilityCheck(this.roomid).then(response => {
        //   this.isAvailableRoom = response.data
        // })
        // if( this.isAvailableRoom == 1 ){
          this.orderSettlement().then(response => {
              console.log(response)
            var res = JSON.parse(JSON.stringify(response));
            // console.log(res)
            if( res['data']['status'] == 1 ){
              localStorage.removeItem('myCart')
              var xMethod = this.modepayment.split('|');
              if( xMethod[0] == 'wallet' || xMethod[0] == 'onlinebanktransfer' || xMethod[0] == 'onlinebillspayment' ){
                window.location.href=res['data']['link'];
              } else {
                var instruction = res['data']['direct_otc_info'][0];
                this.$swal(
                  '&nbsp;',
                  (instruction['pay_instructions']).replaceAll('\n', '<br><br>')+'<br><br>Reference Number: '+instruction['pay_reference']
                ).then(()=>{
                  window.location.href="/styles";
                });
                // alert(instruction['pay_instructions']+'\n\nReference Number: '+instruction['pay_reference']);
                // window.location.href="/booking";
              }
            } else {
              alert(res['data']['json'])
              console.log(res['data']['json'])
            }
          }).catch(error=>{
            this.loader_stat = "hidden"
            console.log(error.response.data.errors)
            this.error = error.response.data.errors
            // alert(error.response.data)
          })
        //   } else {
        //     this.$swal(
        //       '&nbsp;',
        //       'This room is not available right now. Please select new room'
        //     ).then(()=>{

        //       window.location.href="/booking";
        //       // if (result.isConfirmed) {
        //       //   this.$swal('Saved!', '', 'success')
        //       // } else if (result.isDenied) {
        //       //   this.$swal('Changes are not saved', '', 'info')
        //       // }
        //     });
            // window.location.href="/booking";
          // alert('Sorry, But this room is not already available right now, Please select new room');
          // Swal.fire(
          //   'The Internet?',
          //   'That thing is still around?',
          //   'question'
          // )

        // }
      },
      validateFields(arrinput){
        var stat = 1;
        arrinput.forEach((value)=>{
          if( value == "" ){
            stat = 0
          }
        })
        return stat
      },
      selPMethod(checkID){
        const checkboxes = document.querySelectorAll('.chk-paymentMethod');
          for (const c of checkboxes) {
            c.checked = false
          }
          document.querySelector('#'+checkID).checked = true;
          this.getModepayment(checkID)
      },
      orderSettlement(){
        // var requiredVal = [
        //     this.firstname,
        //     this.lastname,
        //     this.contactnumber,
        //     this.email,
        //     this.streetname,
        //     this.compound,
        //     this.baranggay,
        //     this.municipality,
        //     this.province,
        //     this.region,
        //     this.completeaddress,
        //     this.modepayment
        // ];
        // if( this.validateFields(requiredVal) == 1 ){
          var xData = {

            firstname: this.firstname,
            lastname: this.lastname,
            contactnumber: this.contactnumber,
            email: this.email,
            streetname: this.streetname,
            compound: this.compound,
            baranggay: this.baranggay,
            municipality: this.municipality,
            province: this.province,
            region: this.region,
            completeaddress: this.completeaddress,
            modepayment: this.modepayment,
            company: this.company,
            ordersJSON: JSON.stringify(this.$store.state.cart),
            price: this.$store.state.cartSubTotal,
            return_url: location.host,

          }
          return services.orderPayment(xData)
        // } else {
        //   this.$swal(
        //       '&nbsp;',
        //       'Please Complete All Required Fields'
        //     )
        //   this.loader_stat = "hidden"
        // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .cart{
        font-size: 20px;
        /* text-align: right; */
    }
    .cart-items{
        border: 1px solid rgba(197, 194, 194, 0.925);
        /* margin-bottom: 20px; */
    }
    .cart-border-left{
        border-left: 1px solid rgba(197, 194, 194, 0.925);
    }
    .cart-align-middle{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* margin: 30% 0 !important */
    }
    @media screen and (max-width: 480px) {
        .mobile-reset-padding{
        padding-left: 15px !important;
        padding-right: 15px !important;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .page-cover{
        position: relative !important;
        max-width: 1900px;
        /* align-content: center; */
        margin: auto;
    }
    .page-cover img{
        width: 100%;
        position: relative;
    }
    .page-cover .cover-message{
        position: absolute;
        top: 45%;
        color: white;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .cover-message h1{
        font-size: 45px;
    }
    /* .btn-shop-now{
        border: 1px solid #f8b100;
        width: 200px;
    } */
    .div-title{
        text-align: center !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    .product__item__pic{
        background-repeat: no-repeat;
        background-size: cover;
    }
    .loader-container{
    position: fixed;
    background: rgba(0,0,0,0.9);
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;

    /* margin: auto; */
  }
  .loader-container img{
    /* background: rgba(255, 255, 255, 0.5); */
    width: 100px;
    margin: auto;
    top: 45vh;
    position: relative;
    display: block;

  }
   .hidden{
     display: none;
   }
   .showed{
     display: block;
   }
   .input-required{
       color: red;
       font-size: 12px;
       margin: 0;
   }
   .padBot{
       padding-top: 10px;
       /* padding-bottom: 10px; */
   }
    .breadcrumb_area .bg-parallax {
        background-image: url('../../assets/img/host/home/vc-host-header.jpg')!important;
        background-repeat: no-repeat !important;
        background-attachment: scroll !important;
        background-position: center !important;
        background-size: cover !important;
    }
</style>
