<template>
    <div class="modal fade" id="styles-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">&nbsp;</h3>
            <button style="font-size: 20px;" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i></button>
          </div>
          <div class="modal-body">

            <div class="container">
                <div class="section_title text-center">
                    <h2 class="title_color">Visit Our Online Stores</h2>
                </div>
                <div class="row">
                    <div class="col-md-4 option_shop" style=";display:none">
                        <center>
                            <div style="height: 200px">
                                <img src="../../assets/img/logo/VCLOGOonly.png" style="width: 200px;height: 200px;" alt="">
                            </div>
                        </center>
                        <div class="styles-desc">
                            <!-- There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. -->
                        </div>
                        <a href="#" target="_blank" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                    </div>
                    <!-- LAZADA -->
                    <div class="col-6 option_shop" style="">
                        <center>
                            <div style="height: 200px">
                                <img src="../../assets/img/header/lazada.png" style="width: 100%" alt="">
                            </div>
                        </center>
                        <div class="styles-desc">
                            <!-- There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. -->
                        </div>
                        <a href="https://www.lazada.com.ph/shop/victoria-court/" target="_blank" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                    </div>
                    <!-- SHOPEE -->
                    <div class="col-6 option_shop" style="">
                        <center>
                            <div style="height: 200px">
                                <img src="../../assets/img/header/shopee.png" style="width: 50%" alt="">
                            </div>
                        </center>
                        <div class="styles-desc">
                            <!-- There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. -->
                        </div>
                        <a href="https://shopee.ph/victoriacourtstyle" target="_blank" class="button_hover theme_btn_two btn-visit xbtn-features">Visit Site</a>
                    </div>
                </div>   
            </div>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
    name: 'modals_n',
    // props: {
    //   msg: String
    // },,
    // components: {
    //   "vue-sticky-sidebar": vueStickySidebar
    // },
    data(){
        return {
            
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .btn-visit{
    /* padding: 5px 50px; */
    border: 1px solid #FFD700;
    /* background: transparent; */

    /* color: black; */
    /* position: absolute !important; */
    bottom: 10px;
    width: 80%;
    left: 10% !important;
    font-size: 14px;
    margin-top: 20px;
    /* padding: auto 30px !important; */
  }
  #styles-modal{
      margin-top: 10%;
  }
</style>
