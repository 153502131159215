<template>
  <div>

    <!-- Shop Section Begin -->
    <section>
         <!-- <img :src="`${article.img}`" alt=""> -->
         <!-- <img src="../../../public/template/home/image/vc_style.jpg" alt=""> -->
    </section>
    <section class="shop spad"  style="padding-top: 150px !important">
        <div class="container">
            <div class="row">
                <div class="offset-md-4 col-md-4">
                   <img style="width: 100%" :src="`${article.img}`" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12" style="text-align: center">
                    <h1>{{this.article.title}}</h1>
                    <p>{{this.article.created_at}}</p>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-1 col-md-10" style="margin-top: 50px" id="article">
                    <!-- {{this.article.article}} -->
                </div>
            </div>
        </div>
    </section>
    <!-- Shop Section End -->

  </div>
</template>

<script>
// import vueStickySidebar from "vue-sticky-sidebar";
// import services from '../../service/webservice'
// import Paginate from 'vuejs-paginate-next';
export default {
  name: 'speak_n',
  // props: {
  //   msg: String
  // },,
  components: {
  //   "vue-sticky-sidebar": vueStickySidebar
    //   paginate: Paginate,
  },
  data(){
    return {
        company: process.env.VUE_APP_COMPANY_CODE,
        // imgPath: process.env.VUE_APP_API_URL,
        article: JSON.parse(localStorage.getItem('article')),
        pagecnt: 20,
        speaks: []
    }
  },
  created(){
    this.$store.state.header = "speaks";
  },
  mounted(){
    //   alert(this.$route.params.id)
    // this.getSpeaks()
    let xapp = document.getElementById('article');
    xapp.innerHTML = this.article.article;
  },
  methods:{
    //   getSpeaks(){
    //     this.fetchSpeaks().then(response => {
    //         // this.foodloading = 1
    //         this.speaks = response.data
    //         console.log(this.speaks)
    //         // this.foodArr = response.data.list
    //         // this.foodImgSrc = response.data.motelIMG    
    //         // this.$store.state.stylesImageURL = response.data.motelIMG   
    //         // // alert(response.data.list.last_page)
    //         // this.pagecnt = response.data.list.last_page
    //     })
    //   },
    //   fetchSpeaks(){
    //     var xdata = {
    //       company: this.company,
    //     //   branch: this.branch,
    //       filter: "",
    //       pagination: 12
    //     }
    //     return services.fetchSpeaks(xdata)
    //   },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .speaks_container{
        margin-bottom: 70px;
    }
    .speaks_image img{
        width: 100% !important;
    }
    .speaks_from_local{
        font-weight: bold;
    }
    .speaks_from{
        font-size: 16px;
        text-align: right;
    }
    .speaks_intro{
        text-indent: 25px;
        text-align: justify;
    }
    .showmore{
        margin-top: 50px;
    }
</style>
