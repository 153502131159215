<template>
  <!-- <div> -->
    <section class="booking bg-abstract" id="roomList" style="padding-bottom: 100px">
      <div class="container">
        <div class="row" style="padding: 60px 0px">
          <div v-if="currentView=='mobile'">
            <button class="btn-mobile-filter btn btn-default btn-modal-filter" data-toggle="modal" data-target="#filter-modal"><i class="fa fa-search"></i></button>
            <div class="modal fade" id="filter-modal" data-backdrop="static">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                      <div>
                        <div class="title-header">
                          <h2>Check Availability</h2>
                        </div>
                          <div class="filters">
                            <div class="row">
                              <div class="col-md-12">
                                <h4>Branch</h4>
                                <!-- changeBranch() -->
                                <select @change="changeBranch()" v-model="branch" name="" id="" class="form-control">
                                  <option v-for="xbranch in branches.data" :key="xbranch.id" :value="xbranch.branch_code">{{xbranch.branch}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <h4>Date</h4>
                                <input @change="filter()" v-model="bookingDate" :min="xbookingDate" onkeydown="return false" required  id="datepicker" type="date" class="form-control">
                                <!-- <datepicker
                                  weekStartsOn="0"
                                  :lowerLimit="xbookingDate"
                                  class="form-control"
                                  v-model="bookingDate"
                                ></datepicker> -->
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <h4>Time</h4>
                                <!-- <input v-model="bookingDate" id="datepicker" readonly type="text" class="form-control"> -->
                                <input @change="filter()" type="time" name="" v-model="bookingTime" onkeydown="return false" required  class="form-control" id="">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <h4>Stay</h4>
                                <select @change="filter()" v-model="roomrate" class="form-control">
                                  <option v-for="xroomrate in roomRateList.data" :key="xroomrate.id" :value="xroomrate.roomrate.id">{{xroomrate.roomrate.room_rate}}</option>
                                </select>
                                <br>
                                <!-- <div class="col-12"> -->
                                <div class="NoteContainer">
                                  <p><i>Note: For party rates, please contact us through our email(<a href="mailto:serve@victoriacourt.com" target="_blank">serve@victoriacourt.com</a>) or phone number(<a href="tel:0917-VCSERVE">0917-VCSERVE</a>)</i></p>
                                </div>
                                <!-- </div> -->
                              </div>
                            </div>
                            <!-- <br> -->
                            <!-- <div class="row">
                              <div class="offset-sm-2 col-sm-8"> -->
                                <!-- <center>
                                  <button data-dismiss="modal" @click="filter()" class="button_hover theme_btn_two" style="width: 200px">Search</button>
                                </center> -->
                              <!-- </div>
                            </div> -->
                        </div>
                      </div>
                      <div>
                        <div class="title-header">
                          <h2>Amenities</h2>
                        </div>
                        <div class="filters">
                          <div v-for="ame in amenities.data" :key="ame.id">
                            <div v-for="xame in ame.amenities" :key="xame.id" class="row" style="padding: 0px !important; margin-left: 10px">
                              <div class="row">
                                <div v-if="xame.a_desc == 'yes' || xame.a_desc == 'YES' || xame.a_desc == 'Yes'" class="col-md-12"> 
                                  <label style="padding: 0px !important; margin-bottom: 0px !important">
                                    <input :id="'ame'+xame.id" class="ameCHCK" @change="getCheckAmenities('ame'+ame.id,'ame'+xame.id)" :name="'ame'+ame.id" :value="xame.id" type="checkbox"> {{ame.category}}
                                  </label>
                                </div>
                                <div v-else class="col-md-12"> 
                                  <label style="padding: 0px !important; margin-bottom: 0px !important">
                                    <input :id="'ame'+xame.id" class="ameCHCK" @change="getCheckAmenities('ame'+ame.id,'ame'+xame.id)" :name="'ame'+ame.id" :value="xame.id" type="checkbox"> {{xame.a_desc}}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="modal-footer" style="display: block !important">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="roomFilter" class="col-md-4 filter-cont">
            <vue-sticky-sidebar v-if="currentView=='web'"
               containerSelector=".container"
               innerWrapperSelector='.sidebar__inner'
               class="sidebar">

              <div class="filters-container">
                <div class="title-header">
                  <h2>Check Availability</h2>
                </div>
                <div class="filters">
                  <div class="row">
                    <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <h4>Branch</h4>
                            <!-- changeBranch() -->
                            <select @change="changeBranch()" v-model="branch" name="" id="" class="form-control">
                              <option v-for="xbranch in branches.data" :key="xbranch.id" :value="xbranch.branch_code">{{xbranch.branch}}</option>
                            </select>
                          </div>
                          <div class="col-6">
                            <h4>Stay</h4>
                            <select @change="filter()"  v-model="roomrate" class="form-control">
                              <option v-for="xroomrate in roomRateList.data" :key="xroomrate.id" :value="xroomrate.roomrate.id">{{xroomrate.roomrate.room_rate}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <h4>Date</h4>
                            <input @change="filter()" v-model="bookingDate" :min="xbookingDate"  onkeydown="return false" required  type="date" class="form-control">
                          </div>
                          <div class="col-6">
                            <h4>Time</h4>
                            <!-- <input v-model="bookingDate" id="datepicker" readonly type="text" class="form-control"> -->
                            <input @change="filter()"  type="time" name="" v-model="bookingTime" onkeydown="return false" required  class="form-control" id="">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <center>
                              <h2>Amenities</h2>
                            </center>
                          </div>
                        </div>
                        <div class="row">
                          <div v-for="ame in amenities.data" :key="ame.id" :class="getLength((ame.amenities).length)">
                            <div class="row">
                              <div v-for="xame in ame.amenities" :key="xame.id" :class="subGetLength((ame.amenities).length)">
                                <!-- <div class="row"> -->
                                  <div v-if="xame.a_desc == 'yes' || xame.a_desc == 'YES' || xame.a_desc == 'Yes'" class="col-12" > 
                                    <label style="padding: 0px !important; margin-bottom: 0px !important">
                                      <input :id="'ame'+xame.id" class="ameCHCK" @change="getCheckAmenities('ame'+ame.id,'ame'+xame.id)" :name="'ame'+ame.id" :value="xame.id" type="checkbox"> {{ame.category}}
                                    </label>
                                  </div>
                                  <div v-else  class="col-12"> 
                                    <label style="padding: 0px !important; margin-bottom: 0px !important">
                                      <input :id="'ame'+xame.id" class="ameCHCK" @change="getCheckAmenities('ame'+ame.id,'ame'+xame.id)" :name="'ame'+ame.id" :value="xame.id" type="checkbox"> {{xame.a_desc}}
                                    </label>
                                  </div>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <!-- <div class="col-12"> -->
                              <div class="NoteContainer">
                                <p><i>Note: For party rates, please contact us through our email(<a href="mailto:serve@victoriacourt.com" target="_blank">serve@victoriacourt.com</a>) or phone number(<a href="tel:0917-VCSERVE">0917-VCSERVE</a>)</i></p>
                              </div>
                            <!-- </div> -->
                          </div>
                        </div>
                    </div>
                  </div>
                  <!-- <br>
                  <div class="row">
                    <div class="offset-md-1 col-md-10">
                      <a href="#" @click.stop.prevent="filter()" class="button_hover theme_btn_two form-control">Search</a>
                    </div>
                  </div> -->
                </div>
              </div>
              <br>

            </vue-sticky-sidebar>
          </div>
          <div class="col-md-8">
            <div class="room-container">
              <div class="room-header">
                <h2>Available Rooms</h2>
              </div>
              <div class="room-content">
                <!-- room loader -->
                <div v-if="roomloading == 0">
                  <div class="rooms">
                    <div class="row">
                      <ContentLoader></ContentLoader>
                    </div>
                  </div>
                  <div class="rooms">
                    <div class="row">
                      <ContentLoader></ContentLoader>
                    </div>
                  </div>
                  <div class="rooms">
                    <div class="row">
                      <ContentLoader></ContentLoader>
                    </div>
                  </div>
                </div>
                <!-- room list -->
                <div v-else>
                  <div v-for="room in rooms" :key="room.id" class="rooms container-fluid">
                    <div class="row">
                      <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <div class="row">
                          <div class="col-md-12">
                            <center>
                                <h4>{{room.room_name}}</h4>
                            </center>
                            <div class="img" style="display: flex;justify-content: center;">
                                <img v-if="room.room_img == '' || room.room_img == null" src="../../assets/img/room/no-image.png" style="width: 100%;" alt="">
                                <!-- <img v-else :src="getImgUrl(img_path,company,(branch).toLowerCase(),room.room_img)" alt=""> -->
                                <img v-else :src="`${img_path}${company}/${(branch).toLowerCase()}/${room.room_img}`" alt="" style="width: 100%;;">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-8 col-md-5 col-lg-5">
                        <div class="row">
                          <div class="col-md-12">
                            <h6><b>Inclusions:</b></h6>
                            <div class="inc_imgs">
                              <div class="row">
                                <div v-for="ameCat in amenities.data" :key="ameCat.id" class="col-3 col-sm-3 col-md-3 col-lg-3" >
                                  <center>
                                    <img style="width: 25px;opacity: 0.4" :id="'amen_'+room.room_no+'_'+ameCat.id" :src="xHost+'/'+ameCat.img" alt="My Happy SVG"/>
                                    <br/><label style="font-size: 8px; margin-bottom: 10px;opacity: 0.1;" :id="'dspl_amen_'+room.room_no+'_'+ameCat.id">{{ameCat.category}}</label>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div v-if="room.amenities.desc != ''" class="col-md-12">
                            <h6><b>Description:</b></h6>
                            {{ room.amenities.desc }}
                            <!-- <p class="room_desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> -->
                          </div>
                        </div>
                      </div>
                      <div v-if="roomsRemainingTime[room.id] != null"  class="col-12 offset-sm-3 col-sm-6 col-md-4 col-lg-4" style="margin: auto">
                        <div class="row">
                          <div class="col-md-12">
                            <div v-for="xroomratetotype in room.roomratetoroomtype" :key="xroomratetotype.id">
                              <div v-if="xroomratetotype.roomrateprice.room_rate_id == xfilter_roomrate && xroomratetotype.roomrateprice.isResa == 1">
                                <h3 class="room_price">₱ {{this.formatter(xroomratetotype.roomrateprice.amount)}}</h3>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label>Available Time</label>
                            <div class="row mb-2" v-for="time in roomsRemainingTime[room.id]" :key="time.from">
                              <div class="col-md-12">
                                <a href="#" class="button_hover theme_btn_two form-control" @click.prevent="createFilterLink(time.from,room.id,room.room_type_id)">{{this.datetimeformatter(time.from)}}<br/>{{this.datetimeformatter(time.to)}}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="col-12 col-sm-12 col-md-4 col-lg-4" style="margin: auto">
                        <div class="row" :class="priceborder">
                          <div class="col-12 col-sm-6 col-md-12 col-lg-12">
                            <div v-for="xroomratetotype in room.roomratetoroomtype" :key="xroomratetotype.id">
                              <div v-if="xroomratetotype.roomrateprice.room_rate_id == xfilter_roomrate && xroomratetotype.roomrateprice.isResa == 1">
                                <h3 class="room_price">₱ {{this.formatter(xroomratetotype.roomrateprice.amount)}}</h3>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-12 col-lg-12">
                            <a href="#" @click.stop.prevent="bookRoom(room.id,room.room_type_id)" class="button_hover theme_btn_two form-control">Book Now</a>
                          </div>
                        </div>
                        <!-- <div class="row">
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-md-12">
                        
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <!-- </div> -->
</template>

<script>
  import services from '../../service/webservice'
  import vueStickySidebar from "../../../node_modules/vue-sticky-sidebar/src/vue-sticky-sidebar.vue";
  // import { ref } from 'vue';
  // import datepicker from 'vue3-datepicker'
  import { ContentLoader } from 'vue-content-loader'
  // import Popper from "vue3-popper"

  export default {
    name: 'booking_n',
    components: {
                  "vue-sticky-sidebar": vueStickySidebar,
                  // datepicker,
                  ContentLoader,
                  // Popper
                },
    data(){
      return {

        img_path: process.env.VUE_APP_ROOM_WEBSITE_URL,
        xHost: process.env.VUE_APP_HOST,
        company: process.env.VUE_APP_COMPANY_CODE,
        rooms: [],
        roomsRemainingTime: [],
        branches: [],
        branch: "",
        roomRateList: [],
        roomrate: "",
        bookingDate: "",
        xbookingDate: "",
        bookingTime: "",
        xbookingTime: "",
        listAmenities: [],
        isAvailableRoom: 0,
        roomPATH: "",

        xfilter_branch: "",
        xfilter_date: "",
        xfilter_time: "",
        xfilter_roomrate: "",

        roomloading: 0,

        amenities: [],
        checkedAmenities: [],
        branchinfo: this.$store.getters.getBranchInformations,
        webcontent: [],

        currentView: 'web',
        priceborder: ""

      }
    },
    created(){
      this.$store.state.header = "booking";
      // console.log(this.addHours,new Date())
      // var today = new Date(Date.now()+2*24*60*60*1000);
      var today = new Date(Date.now()+12*60*60*1000);
      var date = today.getDate()
      var month = today.getMonth()
      var year = today.getFullYear()
      // this.bookingDate = ref(new Date(year,month,date));
      // this.xbookingDate = ref(new Date(year,month,date));
      this.bookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
      this.xbookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
      var bHrs = today.getHours() < 10 ? '0'+today.getHours() : today.getHours();
      var bMins = today.getMinutes() < 10 ? '0'+today.getMinutes() : today.getMinutes();
      this.xbookingTime = bHrs+":"+bMins;
      this.bookingTime = bHrs+":"+bMins;


      
    this.windowResizing()
    var resizedFinished;
    window.addEventListener("resize", ()=>{
      clearTimeout(resizedFinished);
      resizedFinished = setTimeout(()=>{
       this.windowResizing()
      },1000);
    });

    },
    async mounted(){
      await this.getbranch().then(response => {
        // console.log(response)
        this.branches = response
        this.branch = response.data[0]['branch_code']
        this.xfilter_branch = this.branch
        this.$store.state.mybranch = this.branchinfo[this.xfilter_branch] //- CHANGE NAVIGATION INFORMATION
        this.getRates()


        this.getAmenityCategory() // -- INCLUDED TRIGGER FOR FILTERING 
        this.getAmenities()
        
        
        // do{
        //   alert(this.xfilter_roomrate)
          // this.filter()
        // } while(this.xfilter_roomrate == "");
        // alert(localStorage.getItem('chkAvailability'))
        if( localStorage.getItem('chkAvailability') != null ){
          var chkAvail = JSON.parse(localStorage.getItem('chkAvailability'));
          // alert(chkAvail['roomrate'])
          if( chkAvail['branch'] ){
            this.branch = chkAvail['branch']
            this.xfilter_branch = this.branch
            this.$store.state.mybranch = this.branchinfo[this.xfilter_branch] //- CHANGE NAVIGATION INFORMATION
          }
          this.getRates()
          // this.xfilter_roomrate = this.roomrate

          if( chkAvail['bookingDate'] ){
            var selDate = new Date(chkAvail['bookingDate'])
            // var today = new Date(selDate+2*24*60*60*1000);
            var today = new Date(selDate+12*60*60*1000);
            var date = today.getDate()
            var month = today.getMonth()
            var year = today.getFullYear()
            // this.bookingDate = ref(new Date(year,month,date));
            this.bookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
          }
          if( chkAvail['bookingDate'] ){
            this.bookingTime = chkAvail['bookingTime']
          }
          this.getAmenityCategory() // -- INCLUDED TRIGGER FOR FILTERING 
          this.getAmenities()
          setTimeout(function(){
            localStorage.removeItem('chkAvailability')
          },1000);
        }
      })

      // -- inactivity checker
      this.inactivityTime()

      this.getVCContent(process.env.VUE_APP_VC_WEBSITE_CONTENT+'/webcontent.json')

    },
    updated: function () {
        this.$nextTick(function () {
          this.enableAmenitiesDspl()
        })
    },
    
    methods:{
      // acceptBookingRules(){
      //   this.bookingRules = "";
      // },
      subGetLength(xcount){
        return xcount > 1 ? 'col-6 ' : 'col-12 '
      },
      getLength(xcount){
        return xcount > 1 ? 'col-12 ' : 'col-6 '
      },
      backToOriginalDate(){
        var today = new Date(Date.now()+12*60*60*1000);
        var date = today.getDate()
        var month = today.getMonth()
        var year = today.getFullYear()
        this.bookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
        this.xbookingDate = year+"-"+this.strPad((month+1))+"-"+this.strPad((date));
      },
      backToOriginalTime(){
        var selDate = new Date(this.bookingDate)
        var today = new Date(selDate+12*60*60*1000);
        var bHrs = today.getHours() < 10 ? '0'+today.getHours() : today.getHours();
        var bMins = today.getMinutes() < 10 ? '0'+today.getMinutes() : today.getMinutes();
        this.xbookingTime = bHrs+":"+bMins;
        this.bookingTime = bHrs+":"+bMins;
      },
      checkFilterDateTime(){
        var stat = 1;
        if( this.bookingDate == this.xbookingDate ){
          if( this.xbookingTime > this.bookingTime ){
            stat = 0;
          }
        }
        return stat;
      },
      addHours(numOfHours, date) {
        date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        return date;
      },
      enableAmenitiesDspl(){
        setTimeout(()=>{
            this.listAmenities.forEach((v)=>{
              // if( v['amenities'] ){
                v['amenities'].forEach((v1)=>{
                  if( v1 ){
                    if(  v1['amenities_category'] ){
                      var el = document.getElementById('amen_'+v['room_number']+'_'+v1['amenities_category']['id'])
                      var xel = document.getElementById('dspl_amen_'+v['room_number']+'_'+v1['amenities_category']['id'])
                      
                      if( el ){
                        el.style.opacity = 1
                      }

                      if( xel ){
                        xel.style.opacity = 1
                        if( v1['a_desc'] == 'Yes' || v1['a_desc'] == 'YES' ){
                          xel.innerText = v1['amenities_category']['category']
                        } else {
                          xel.innerText = v1['a_desc']
                        }
                      }
                    }
                  }
                })
              // }
            });
        },1000);
      },
      getPopular(){
        var branchcode = {
          HI: "hc",
          SM: "pa",
          PA: "pa",
          MA: "ma",
          LP: "lp",
          NE: "ne",
          BA: "ba",
          SF: "sf",
        }
        var popularRooms = []
        // alert(typeof this.webcontent['web|host|locale|'+(this.xfilter_branch).toLowerCase()+'|popularrooms'])
        // alert(this.webcontent['web|host|locale|hi|popularrooms'])
        if( typeof this.webcontent['web|host|locale|'+branchcode[this.xfilter_branch]+'|popularrooms'] != undefined ){
          popularRooms = this.webcontent['web|host|locale|'+branchcode[this.xfilter_branch]+'|popularrooms']
          // popularRooms = this.webcontent['web|host|locale|hi|popularrooms']
        }
        var roomID = [];
        if( popularRooms ){
          popularRooms.forEach((v)=>{
            roomID.push(parseInt(v['relID']))
          })
        }
        // popularRooms.forEach((v)=>{
        //   roomID.push(v['relID'])
        // })
        // console.log(popularRooms)
        return roomID;
        // return this.webcontent['web|host|locale|'+branchcode[this.xfilter_branch]+'|popularrooms']
      },
      getVCContent($url){
        this.xgetVCContent($url).then(response => {
          // console.log(response)
          this.webcontent = response.data
          // console.log(this.webcontent['web|home|vcFeatures|types|host'])
          // this.displayContent()
        })
      },
      xgetVCContent($url){
        return services.fetchVCcontent($url)
      },
      datetimeformatter(myDate){
        const months = {
          0: 'Jan',
          1: 'Feb',
          2: 'Mar',
          3: 'Apr',
          4: 'May',
          5: 'Jun',
          6: 'Jul',
          7: 'Aug',
          8: 'Sep',
          9: 'Oct',
          10: 'Nov',
          11: 'Dec'
        }
        var xxDate = myDate.split(' ')[0]
        var xxTime = myDate.split(' ')[1]
        var xDate = new Date(
          xxDate.split('-')[0],
          (xxDate.split('-')[1] - 1),
          xxDate.split('-')[2],
          xxTime.split(':')[0],
          xxTime.split(':')[1],
          xxTime.split(':')[2]
        )

        var hours = xDate.getHours();
        var minutes = xDate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        var retDate = months[xDate.getMonth()] + " " + xDate.getDate() + ", " + xDate.getFullYear();
        var retTime = hours + ':' + minutes + ' ' + ampm;
        return retDate + " " + retTime
        // return xDate.getFullYear()+"-"+this.strPad(((xDate.getMonth())+1))+"-"+this.strPad((xDate.getDate()));
      },
      createFilterLink(from,roomid,roomtype){
        var xData = from.split(' ')

        this.bookingDate = xData[0]
        this.bookingTime = xData[1]
        setTimeout(()=>{
          this.bookRoom(roomid,roomtype,from)
        },1000);
      },
      windowResizing(){
        var screenWidthCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (screenWidthCheck > process.env.VUE_APP_TABLET_SIZE) {
          this.priceborder = ""
          this.currentView = 'web'
        } else {
          this.priceborder = ""
          if( screenWidthCheck > process.env.VUE_APP_MOBILE_SIZE) {
            this.priceborder = "price-border"
          }
          this.currentView = 'mobile'
        }
      },
      changeDate(){
        alert(this.bookingDate)
        // this.format(this.date)
        // setTimeout(function(){
        //   alert(this.format);
        // },1000);
        
      },
      getCheckAmenities(checkName,checkID){

        const checkboxes = document.querySelectorAll('input[name='+checkName+']');
        if( (document.querySelectorAll('input[name='+checkName+']:checked')).length > 1 ){
          for (const c of checkboxes) {
            c.checked = false
          }
          document.querySelector('#'+checkID).checked = true;
        }

        this.checkedAmenities = []
        var chkAmenities = document.querySelectorAll('.ameCHCK:checked');
        for (var i = 0; i < chkAmenities.length; i++) {
          this.checkedAmenities.push(chkAmenities[i].value);
        }
        this.filter()
      },
      formatDate(xd){
        var today = new Date(xd);
        var date = today.getDate()
        var month = today.getMonth()
        var year = today.getFullYear()
        return year+"-"+this.strPad((month+1))+"-"+this.strPad((date));

      },
      getImgUrl(path,com,bra,img) {
        // let xpath = ""
        // if( require(path+com+"/"+bra+"/"+img) ) {
        //   xpath = path+com+"/"+bra+"/"+img
        // } else {
        //   xpath = "../../assets/img/room/VCBA.jpg"
        // }
        // return xpath
        // return require(path+com+"/"+bra+"/"+img)
        // try{
          // this.roomPATH = require(path+com+"/"+bra+"/"+img)
          // return this.roomPATH
        // } catch (e) {
        //   return require("../../assets/img/room/VCBA.jpg")
        // }
        return path+com+"/"+bra+"/"+img
      },
      // -- inactivity checker
      inactivityTime(){
        var time;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;

        function logout() {
          // alert('15mins Idle, please check room availability again')
          // window.location.href="/booking";
            this.$swal(
              '&nbsp;',
              '15mins Idle, please check room availability again'
            ).then(()=>{
              window.location.href="/booking";
            });
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 300000) // 15mins
        }
      },
      formatter(price){
        return (price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      },
      strPad(str){
        return (str).toString().padStart(2,'0')
      },
      getAmenities(){
        // load ROOM FIRST BEFORE LIST AMENITIES
        this.loadRoomsAfterAmenities()
        
          this.fetchAmenities().then(response=>{
            this.listAmenities = response.data
            
              this.enableAmenitiesDspl()
            // amen_room.room_no_ameCat.id
          })
      },
      filter(){
        if( this.bookingDate != "" ){
          if( this.bookingTime != "" ){
            if( this.checkFilterDateTime() == 1 ){
              // alert(JSON.stringify(this.$store.getters.getMyBranchInfo))
              const myEl = document.getElementById('roomList')

              this.$smoothScroll({
                  scrollTo: myEl, // scrollTo is also allowed to be number
              // hash: '#sampleHash' // required if updateHistory is true
              })

              this.getAmenities()
            } else {
              this.$swal(
                  '&nbsp;',
                  'Reservation Time Should Be Greater Than '+this.datetimeformatter(this.xbookingDate+" "+this.xbookingTime+":00")
                );
                this.backToOriginalTime()
            }
          } else {
            this.backToOriginalTime()
            this.filter()
          }
        } else{
          this.backToOriginalDate()
        }
      },
      loadRoomsAfterAmenities(){
        // -- Load Room
        this.roomloading = 0
        this.getRooms()
        // if( xgetRoom ){
          
        //   }).catch(error => {
        //     console.log(error)
        //   })
        // }
      },
      sortFunction(a, b) {
          if (a['roomratetoroomtype'][0]['roomrateprice']['amount'] === b['roomratetoroomtype'][0]['roomrateprice']['amount']) {
              return 0;
          }
          else {
              return (a['roomratetoroomtype'][0]['roomrateprice']['amount'] < b['roomratetoroomtype'][0]['roomrateprice']['amount']) ? 1 : -1;
          }
      },
      async getRates(){
        await this.getRoomRate().then(response => {
          // console.log(response)
          this.roomRateList = response
          this.roomrate = response.data[0]['roomrate']['id']
          this.xfilter_roomrate = this.roomrate
          // alert(" Get Rates "+this.xfilter_roomrate)

          if( localStorage.getItem('chkAvailability') != null ){
            var chkAvail = JSON.parse(localStorage.getItem('chkAvailability'));
            if( chkAvail['roomrate'] ){
              this.roomrate = chkAvail['roomrate']
              this.xfilter_roomrate = this.roomrate
            }
          }

        }).catch(error => {
          console.log(error)
        })
      },
      getRoomRate(){
        var xdata = {
          company: this.company,
          branch: this.branch
        }
        return services.getRoomRate(xdata)
      },
      getAmenityCategory(){
        this.getAmeCatPerAmenities().then(response => {
          this.amenities = response
          this.filter()
          // console.log(response.data)
        })
      },
      getAmeCatPerAmenities(){
        var xdata = {
          company: this.company,
          branch: this.branch
        }
        return services.fetchAmenityCategoryPerAmenities(xdata)
      },
      async getRooms(){
        this.xfilter_branch = this.branch
        this.xfilter_date = this.formatDate(this.bookingDate)
        this.xfilter_time = this.bookingTime
        this.xfilter_roomrate = this.roomrate


        this.$store.state.mybranch = this.branchinfo[this.xfilter_branch] //- CHANGE NAVIGATION INFORMATION

        // if( this.xfilter_roomrate != "" ){
          // alert(" Get Rooms "+this.xfilter_roomrate)
          var xdata = {
            company: this.company,
            branch: this.xfilter_branch,
            date: this.xfilter_date,
            time: this.xfilter_time,
            rate: this.xfilter_roomrate,
            amenities: this.checkedAmenities
          }
          if( this.xfilter_roomrate != "" ){
            // return await services.getRooms(xdata)
            await services.getRooms(xdata).then(response => {
              this.roomloading = 1
              // console.log(response)
              var main = []
              var arr1 = []
              var arr2 = []
              var popular = this.getPopular()
              main = response.data.room
              main.forEach((v)=>{
                if( popular.indexOf(v['id']) != -1 ){
                  arr1.push(v)
                } else {
                  arr2.push(v)
                }
              });
              arr1.sort(this.sortFunction)
              arr2.sort(this.sortFunction)
              this.rooms = arr1.concat(arr2)
              this.roomsRemainingTime = response.data.AvailableTime
            })
          } else {
            setTimeout(()=>{
              this.getRooms()
              // alert()
            },1000);
          }
        // }
      },
      getbranch(){
        var xdata = {
          company: this.company,
        }
        return services.getbranch(xdata)
      },
      fetchAmenities(){
        var xdata = {
          company: this.company,
          branch: this.xfilter_branch,
        }
        return services.fetchAmenities(xdata)
      },
      changeBranch(){
        // this.filter()
        // this.$store.state.mybranch = this.branchinfo[this.xfilter_branch] //- CHANGE NAVIGATION INFORMATION
        this.getRates()
        this.getAmenityCategory()
      },
      roomAvailabilityCheck(roomid,roomtype,xdate){
        var forDate = this.xfilter_date;
        if( xdate != null ){
          forDate = xdate;
        }
        var xdata = {
          company: this.company,
          branch: this.xfilter_branch,
          date: forDate,
          time: this.xfilter_time,
          rate: roomtype,
          roomid: roomid
        }
        return services.roomAvailabilityCheck(xdata)
      },
      async bookRoom(room,roomtype,xDate){
        await this.roomAvailabilityCheck(room,roomtype,xDate).then(response => {
          this.isAvailableRoom = response.data
        })
        if( this.isAvailableRoom == 1 ){
          var myBookingDate = this.xfilter_date
          var myBookingTime = this.xfilter_time
          if( xDate != null ){
            var newDate = xDate.split(' ')
            myBookingDate = newDate[0]
            myBookingTime = newDate[1]
          }
          localStorage.setItem('branch',this.xfilter_branch);
          localStorage.setItem('date',myBookingDate);
          localStorage.setItem('time',myBookingTime);
          localStorage.setItem('roomrate',this.xfilter_roomrate);
          localStorage.setItem('roomid',room);
          localStorage.setItem('roomtypeid',roomtype);
          window.location.href="/booking_settlement";
        } else {
          // alert('Sorry, But this room is not already available right now, Please select new room');
            this.$swal(
              '&nbsp;',
              'This room is not already available right now, Please select new room'
            ).then(()=>{
              window.location.href="/booking";
            });
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .price-border{
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 5px 1px;
  }
  .price-border a{
    height: 100%;
  }
  .price-border .room_price{
    margin: 0;
  }

  .bg-abstract{
    background: url("../../../public/template/home/image/bg-skin.png");
    background-repeat: repeat;
    /* background-size: 200px; */
  }

  #background-video{
    width: 100%;
  }
  /* .booking{ */
    /* background: rgb(20, 20, 20); */
  /* } */
  .booking *:not(i){
    font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
  } 
  /* .gray-bg{
    background: #e2e2e2;
  } */
  .vc_option{
    width: 100%;
    height: 500px;
  }
  .card-title{
    color: black !important;
  }
  .card-body{
    text-indent: 25px;
    text-align: justify;
  }
  .card-content{
    padding: 10px;
    background: white;
  }
  .btn-visit{
    font-size: 18px;
    padding: 5px 50px;
    border: 1px solid #FFD700;
    background: transparent;
    color: black;
  }
  .facilities_item p{
    color: white !important;
  }


  .inc_imgs img{
    width: 20px;
  }
  .xicon{
    display: flex;
  }
  .room-container{
    /* box-shadow: 1px 1px 5px #000; */

    /* border: 2px solid black; */
    /* border-top: transparent; */

    box-shadow: 1px 1px 10px rgb(158, 155, 155);
    border: 1px solid rgb(158, 155, 155);
  }
  .room-header{
    /* color: white; */
    background: #FFF;
    /* background: black; */
    text-align: center;
    padding-top: 10px;
  }
  .rooms{
    /* box-shadow: 1px 1px 5px #000; */
    padding: 20px;
    margin: 10px auto;
    /* padding-bottom: 50px; */
    /* margin: 10px; */
    /* border-radius: 5px; */
    background: white;
    border-top: 1px solid black;
  }
  .room_price{
    text-align: center;
  }
  .filters-container{
    box-shadow: 1px 1px 10px rgb(158, 155, 155);
    border: 1px solid rgb(158, 155, 155);
    /* border-top: transparent; */
  }
  .title-header{
    /* color: white; */
    /* background: #f8b600; */
    /* background: black; */
    background: #FFF;
    text-align: center;
    padding-top: 10px;
  }
  .title-header h2,
  .room-header h2{
    margin: 0;
    /* font-size: 20px; */
  }
  .filters{
    padding: 20px;
    margin: 0px;
    background: white;
    border-top: 1px solid black;
    /* border-radius: 2px; */
  }
  .filters .row{
    padding-bottom: 10px;
  }
  .room_desc{
    text-align: justify;
    text-indent: 20px;
  }

  .dp__theme_dark {
    --dp-background-color: #fff;
    --dp-text-color: #000;
    --dp-hover-color: #020202;
    --dp-hover-text-color: #ffffff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-text-color: #ffffff;
    --dp-secondary-color: #a9a9a9;
    --dp-border-color: #2d2d2d;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #737373;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;
  }
  .dp__button{
    display: none !important;
  }
  .show{
    display: block;
  }
  .hide{
    display: none;
  }

  .btn-mobile-filter{
    position: fixed;
    z-index: 999;
    right: 15px;
    /* padding: 10px; */
    height: 50px;
    width: 50px;
    top: 120px;
  }
  #filter-modal .modal-body{
    max-height: 80vh;
    overflow-y: scroll;
  }


  /* input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  } */



  input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }

  .theme_btn_two{
    height: auto;
    line-height: inherit;
  }
  .btn-default{
    background: white;
  }
  .NoteContainer{
    /* background: rgb(163, 36, 36);
    padding: 10px; */
    text-align: center;
  }
  .NoteContainer *{
    color: red;
    font-size: 13px;
  }
  .NoteContainer a{
    text-decoration: underline;
  }
</style>