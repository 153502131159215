<template>
  <div>
    <section class="shop spad"  style="padding-top: 150px !important">
        <div class="form-signup">
            <div class="row">
                <!-- <div class="col-md-4">
                    <div class="row"> -->
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">First Name</label> -->
                            <div class="input-group">
                                <span class="input-group-addon">Email</span>
                                <input type="text" v-model="email" class="form-control mod-form">
                            </div>
                            <!-- <p class="input-required" v-if="error.email != null">{{this.changeDescription('email',this.error.email[0])}}</p> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <label class="corbel input-title">Middle Name</label> -->

                            <div class="input-group">
                                <span class="input-group-addon">Password</span>
                                <input type="password" v-model="password" class="form-control mod-form">
                            </div>
                            <!-- <p class="input-required" v-if="error.password != null">{{this.changeDescription('password',this.error.password[0])}}</p> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" @click.stop.prevent="this.Login()" style="color: white !important;height: 50px;line-height: 50px;vertical-align: middle;font-size: 20px" target="_blank" class=" corbel button_hover btn-default theme_btn_two btn-visit btn-home-book-now form-control">Login</a>
                        </div>
                    </div>
                    
                <!-- </div>
            </div> -->
            
        </div>
    </section>
  </div>
</template>

<script>
    import services from '../../service/account_webservice'
    export default {
        name: 'signup_n',
        // props: {
        //   msg: String
        // },
        data(){
            return {
                company: process.env.VUE_APP_COMPANY_CODE,
                email: '',
                password: '',
                error: [],
            }
        },
        created(){
            this.$store.state.header = "account";
            this.xtoken()
        },
        // mounted(){
            
        // }
        methods:{
            changeDescription(xcode,xstring){
                return xstring.replace('The '+xcode,'This')
            },
            // Login(){
            //     this.LoginAccount()
            // },
            xtoken(){
                this.checkToken().then(response => {
                    if( response.data == 1 ){
                        // alert('log');
                        window.location.href = "/myaccount"
                    }
                })
            },
            checkToken(){
                var xdata = {
                    company: this.company,
                    _token: localStorage.getItem('_token')
                }

                // var formdata = new FormData();
                // formdata.append("company", this.company);
                // formdata.append("_token", localStorage.getItem('_token'));
                // alert()
                return services.checkToken(xdata)
            },
            Login(){
                this.LoginAccount().then(response => {
                    alert(response.data.token)
                    localStorage.setItem('_token',response.data.token);
                    // this.sendConfirmationEmail()
                    console.log(response.data)
                    if( response.data != 0 ){
                        // alert('log');
                        window.location.href = "/myaccount"
                    } else {
                        alert('Wrong Credentials');
                    }
                })
            },
            LoginAccount(){
                // var payload = { 
                //     data: JSON.stringify({
                //         company: this.company,
                //         email: this.email,
                //         password: this.password,
                //     })
                // }
                var formdata = new FormData();
                formdata.append("company", this.company);
                formdata.append("email", this.email);
                formdata.append("password", this.password);
                // alert()
                return services.LoginAccount(formdata)
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .form-signup{
        max-width: 50%;
        /* border: 1px solid black; */
        margin: auto auto;
        padding: 20px;
    }
    .mod-form{
        height: 50px;
        /* border: transparent; */
        border: 1px solid black;
        border-radius: 0;
        outline: 0;
    }
    .input-title{
        font-size: 1.5em;
        font-weight: bold;
    }
    .mod-form:focus{
        box-shadow: none !important;
        outline: 0 !important;
        border: 1px solid black;
        /* border-bottom: 1px solid rgb(255, 229, 80); */
    }
    .form-signup>div{
        padding-bottom: 20px;
    }
    .input-group-addon{
        width: 180px;
        background: transparent;
        border: 1px solid black;
        border-right: 1px solid transparent;
        border-left: 5px solid gold;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    @media screen and (max-width: 480px) {
        .form-signup{
            max-width: 100% !important;
            padding: 15px;
        }
    }
   .input-required{
       color: red;
       font-size: 12px;
       margin: 0;
       text-indent: 30px;
   }
   .padBot{
       padding-top: 10px;
       /* padding-bottom: 10px; */
   }
</style>
